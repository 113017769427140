import React from 'react'
import { Modal, Typography } from 'antd'
import { useIntl } from 'react-intl'

import { Upgrade } from 'components/UI'
import useNavigation from 'utils/hooks/useNavigation'

const ModalWhatsappAddon = ({ description, open, onClose }) => {
  const { formatMessage } = useIntl()

  const { onNavigate } = useNavigation()

  return (
    <Modal
      title={formatMessage({ id: 'billing.whatsapp_addon' })}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <div className="text-center text-wrapper">
        <Typography.Title level={5} className="text-center mb-3">
          {description}
        </Typography.Title>
        <Upgrade
          title={formatMessage({ id: 'dueReminder.top_up_whatsapp_balance' })}
          onClick={() => onNavigate('/settings/billing/addon?filter=whatsapp')}
          className="ml-3"
        />
      </div>
    </Modal>
  )
}

export default ModalWhatsappAddon
