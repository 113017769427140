import axios from 'axios'

export const getRoles = () => {
  return axios.get(`/roles`)
}

export const getDeletableRoles = () => {
  return axios.get(`/roles/deletable`)
}

export const getRole = (id) => {
  return axios.get(`/roles/${id}`)
}

export const addRole = (data) => {
  return axios.post(`/roles`, data)
}

export const updateRole = (data, id) => {
  return axios.put(`/roles/${id}`, data)
}

export const deleteRole = (id) => {
  return axios.delete(`/roles/${id}`)
}

export const moveRole = (srcId, destId) => {
  return axios.patch(`/roles/${srcId}/move`, { dest_id: destId })
}
