import React from 'react'
import _ from 'lodash'
import store from 'store'
import { message } from 'antd'

export const storedSettings = (payload) => {
  const settings = {}
  Object.keys(payload).forEach((key) => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : payload[key]
  })
  return settings
}

export const setSettingsToLocalStorage = (payload) => {
  Object.keys(payload).forEach((setting) => {
    store.set(`app.settings.${setting}`, payload[setting])
  })
}

// Untuk pengecekan apakah keynya valid ada websiteid nya, untuk menghapus yg lama
const isValidKey = (key) => {
  const spliteKey = key.split(':')
  if (Array.isArray(spliteKey)) {
    // Index 0 website id, ke 1 uuid
    if (!_.isEmpty(spliteKey[0]) && !_.isEmpty(spliteKey[1])) {
      return true
    }
  }
  return false
}

let showErrorMessage = false

export const setDraftTransaction = (name, key, data, intl) => {
  if (!_.isEmpty(name) && isValidKey(key)) {
    const storeDate = store.get(name)
    let storedData = storeDate ? JSON.parse(storeDate) : undefined
    if (storedData) {
      if (storedData[key]) {
        storedData[key] = { ...storedData[key], ...data }
      } else {
        storedData[key] = data
      }
    } else {
      storedData = {}
      storedData[key] = data
    }

    try {
      store.set(name, JSON.stringify(storedData)) // 3 hari
    } catch (error) {
      if (
        error instanceof DOMException &&
        // everything except Firefox
        (error.code === 22 ||
          // Firefox
          error.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          error.name === 'QuotaExceededError' ||
          // Firefox
          error.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      ) {
        if (showErrorMessage) {
          return
        }

        if (!intl) {
          showErrorMessage = true

          message.error({
            content: (
              <>
                To use draft transaction feature, consider deleting your draft transaction and try
                again.
                <br />
                If the problem persists, consider clearing your browser cache and cookies.
                <br />
                Or ignore this message if you don&apos;t want to use the draft transaction feature.
              </>
            ),
            onClose: () => {
              showErrorMessage = false
            },
          })
        } else {
          showErrorMessage = true
          message.error({
            content: (
              <>
                {intl.formatMessage({ id: 'errors.1_transaction_draft' })}
                <br />
                {intl.formatMessage({ id: 'errors.2_transaction_draft' })}
                <br />
                {intl.formatMessage({ id: 'errors.3_transaction_draft' })}
              </>
            ),
            onClose: () => {
              showErrorMessage = false
            },
          })
        }
      } else {
        console.error('Error saving draft transaction:', error)
      }
    }
  }
}
export const getDraftTransaction = (name, key) => {
  const storeDate = store.get(name)
  const data = storeDate ? JSON.parse(storeDate) : undefined

  if (!data) {
    return undefined
  }
  if (!key) {
    return data
  }
  if (data[key]) {
    return data[key]
  }
  return undefined
}

export const removeTempTransaction = (name, key) => {
  if (!_.isEmpty(name) && !_.isEmpty(key)) {
    const storeDate = store.get(name)
    const data = storeDate ? JSON.parse(storeDate) : undefined
    if (data) {
      if (Object.keys(data).length === 1) {
        store.remove(name)
      } else if (data[key]) {
        delete data[key]
        store.set(name, JSON.stringify(data), { expires: 3 }) // 3 hari
      }
    }
  }
}

export const cleanInvalidDraftTransaction = (name) => {
  if (!_.isEmpty(name)) {
    const storeDate = store.get(name)
    const data = storeDate ? JSON.parse(storeDate) : undefined
    if (data) {
      if (typeof data === 'object') {
        Object.keys(data).forEach((key) => {
          if (!isValidKey(key)) {
            removeTempTransaction(name, key)
          }
        })
      }
    }
  }
}
