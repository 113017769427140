import { Avatar, Image, Modal } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { CloseOutlined } from '@ant-design/icons'

const Banner = ({ banner }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    // Simpan waktu modal ditutup ke cookies hanya dengan ID banner
    Cookies.set(`banner_closed_${banner?.id}`, dayjs().toISOString(), { expires: 1 / 24 }) // Berlaku 1 jam (1/24 hari)
  }, [banner])

  const handleBannerClick = useCallback(() => {
    if (banner?.url) {
      window.open(banner.url, '_blank')
    }
  }, [banner])

  useEffect(() => {
    if (banner?.id) {
      // Konversi waktu dari WIB ke UTC
      const startTimeUTC = dayjs.tz(banner.start_time, 'Asia/Jakarta').utc() // WIB ke UTC
      const endTimeUTC = dayjs.tz(banner.end_time, 'Asia/Jakarta').utc() // WIB ke UTC

      // Waktu lokal pengguna
      const nowLocal = dayjs().tz(dayjs.tz.guess())

      // Konversi waktu UTC ke waktu lokal
      const startTimeLocal = startTimeUTC.local()
      const endTimeLocal = endTimeUTC.local()

      // Periksa jika modal sudah ditutup (berdasarkan cookie dengan ID banner saja)
      const modalClosedTime = Cookies.get(`banner_closed_${banner.id}`)

      if (!modalClosedTime) {
        if (nowLocal.isAfter(startTimeLocal) && nowLocal.isBefore(endTimeLocal)) {
          setIsModalVisible(true)
        }
      } else {
        const lastClosedTime = dayjs(modalClosedTime)
        const oneHourLater = lastClosedTime.add(1, 'hour')

        if (nowLocal.isAfter(oneHourLater)) {
          setIsModalVisible(true)
        }
      }
    }
  }, [banner])

  const closeIcon = useMemo(
    () => (
      <Avatar
        icon={<CloseOutlined />}
        style={{
          backgroundColor: 'rgba(240, 240, 240, 0.9)',
          color: 'rgba(89, 89, 89, 0.9)',
          position: 'absolute',
          top: '-22px', // Keluar sedikit ke atas
          right: '-22px', // Keluar sedikit ke kanan
          cursor: 'pointer',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Efek bayangan agar lebih terlihat
          border: '1px solid rgba(0, 0, 0, 0.1)', // Border tipis agar kontras
          transition: 'all 0.3s ease', // Animasi untuk hover
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.backgroundColor = 'rgba(200, 200, 200, 1)' // Warna lebih gelap saat hover
          e.currentTarget.style.color = 'rgba(50, 50, 50, 1)' // Kontras lebih tajam
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.backgroundColor = 'rgba(240, 240, 240, 0.9)' // Kembali ke warna awal
          e.currentTarget.style.color = 'rgba(89, 89, 89, 0.9)' // Kembali ke warna awal
        }}
        onFocus={() => {}}
        onBlur={() => {}}
      />
    ),
    [],
  )

  return (
    <Modal
      styles={{ content: { padding: 0 } }}
      open={isModalVisible}
      onCancel={handleClose}
      footer={null}
      zIndex={1100}
      closeIcon={closeIcon}
    >
      <Image
        src={banner?.banner_url}
        alt={banner?.title}
        preview={false}
        style={{
          width: '100%',
          height: '100%',
          cursor: 'pointer', // Gambar bisa diklik untuk diarahkan
          objectFit: 'cover', // Pastikan gambar memenuhi area
          borderRadius: 8,
        }}
        onClick={handleBannerClick}
      />
    </Modal>
  )
}

export default Banner
