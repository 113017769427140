import React, { memo, useCallback, useState } from 'react'
import { Avatar, Dropdown, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import QueueStats from 'components/apps/Notification/QueueStats'
import Timeline from 'containers/Notification/Timeline'
import { useResponsive } from 'utils/hooks'
import { darkModeSelector } from 'redux/selectors'
import { compareWithJSON } from 'utils/helper'
import { useIntl } from 'react-intl'
import styles from './style.module.scss'

const Timelines = () => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)
  const darkMode = useSelector(darkModeSelector)

  const { isMobileView } = useResponsive()

  const visibleChangeHandler = (flag) => setVisible(flag)
  const hideHandler = () => setVisible(false)

  const stylePane = { height: 400, overflow: 'auto' }
  if (!isMobileView) {
    stylePane.width = 400
  }

  const renderDropdownMenu = useCallback(() => {
    return (
      <div
        className={`card cui__utils__shadow width-350 overflow-hidden border-0 ${
          darkMode ? 'bg-dark-3 text-light' : 'bg-light'
        }`}
      >
        <div style={stylePane}>
          <QueueStats enabled={visible} />
          <Timeline isMobileView={isMobileView} onHideParent={hideHandler} />
        </div>
      </div>
    )
  }, [darkMode, isMobileView, stylePane, visible])
  return (
    <Tooltip
      open={visible ? false : undefined}
      title={formatMessage({ id: 'topBar.dataChangesTimeline' })}
    >
      <div>
        <Dropdown
          dropdownRender={renderDropdownMenu}
          trigger={['click']}
          placement="bottomRight"
          open={visible}
          arrow
          onOpenChange={visibleChangeHandler}
          destroyPopupOnHide
        >
          <div className={`${styles.dropdown} ${visible ? styles.open : ''}`}>
            <Avatar shape="square" size="small" icon={<FontAwesomeIcon icon="clock" />} />
          </div>
        </Dropdown>
      </div>
    </Tooltip>
  )
}

export default memo(Timelines, compareWithJSON)
