import axios from 'axios'
import NProgress from 'nprogress'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { setMaintenance } from 'redux/maintenance/actions'
import { setLoadedInit } from 'redux/inits/actions'
import { showLoginForm } from 'redux/loginSlice'
import { getToken, setToken } from './cookies'
import { generateBaseUri } from './helper'

const customAxios = axios.create({
  baseURL: generateBaseUri(),
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 300000,
  withCredentials: true,
})

const isAuthorization = (config = {}) => {
  // const hasAuthorizationProperty = Object.prototype.hasOwnProperty.call(config, "authorization");
  return !!config.headers.Authorization
}

export const requestHandler = (request) => {
  if (isAuthorization(request)) {
    request.headers.Authorization = `Bearer ${getToken()}`
  } else {
    delete request.headers.Authorization
  }
  return request
}

export const errorHandler = (error, store, history) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error)
  }

  const status = error.response?.status

  let errorMessage

  try {
    if (_.isArrayBuffer(error?.response?.data)) {
      errorMessage = JSON.parse(new TextDecoder().decode(error?.response?.data)).message
    } else errorMessage = error?.response?.data?.message || 'Network error'
  } catch {
    errorMessage = error?.response?.data?.message || 'Network error'
  }

  switch (status) {
    case 401:
      // const currentLocation = history.location.pathname;
      if (history.location.pathname !== '/user/login') {
        NProgress.done()
        store.dispatch(showLoginForm({ message: error?.response?.data?.message }))
        // localStore.set('app.previousPathname', currentLocation);
        // store.dispatch(push('/user/login'));
      }
      break

    case 521:
      NProgress.done()
      store.dispatch(setMaintenance(errorMessage))
      store.dispatch(setLoadedInit())
      store.dispatch(push('/maintenance'))
      break

    case 400: // Bad Request
      break
    case 404: // Not Found
      break
    case status >= 500: // Server error
      break

    case 422:
      Sentry.captureException(errorMessage)
      break
    default:
      break
  }
  notification.warning({
    // message: title,
    description: errorMessage,
    key: errorMessage,
  })

  return Promise.reject(error.response)
}

export const successHandler = (response) => {
  // Jika ada response access_token di set kan ke cookies
  const newToken = response.data?.access_token
  if (newToken && newToken !== getToken()) {
    setToken(response.data?.access_token)
  }
  return response
}

// Create custom axios GET with cancellation
export const makeGetRequestCreator = () => {
  let call
  return (url) => {
    if (call) {
      call.cancel('Only one request allowed at a time.')
    }
    call = axios.CancelToken.source()
    return customAxios.get(url, {
      cancelToken: call.token,
    })
  }
}

export const getOnce = makeGetRequestCreator()

// customAxios.interceptors.request.use((request) => requestHandler(request));
// customAxios.interceptors.response.use(
//     (response) => successHandler(response),
//     (error) => errorHandler(error),
//   );

export default customAxios
