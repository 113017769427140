import _ from 'lodash'

export const getPermissions = (state) => state._inits?.user?.permissions || []
export const getMenus = (state) => state._inits?.user?.menus || {}
export const getOptions = (state) => state.options.options || {}

/**
 *
 * @param recursive {boolean}
 * @param permissions {Array<Object>}
 * @param currentPermissions {Array<Object>}
 * @param name {string}
 * @param actions {Object}
 * @return {{isCanAdd: boolean, isCanDelete: boolean, isCanEdit: boolean}}
 */
const getModulePermissionsRecursive = ({
  recursive,
  permissions,
  currentPermissions,
  name,
  actions = {},
}) => {
  const findData = permissions.find((row) => row.name === name)

  let _actions = {
    isCanAdd: false,
    isCanEdit: false,
    isCanDelete: false,
    ...actions,
  }

  if (currentPermissions || findData?.child) {
    Object.keys(currentPermissions || findData.child).forEach((index) => {
      const permission = (currentPermissions || findData?.child)[index]
      if (permission.name === `${name}_add`) {
        _actions.isCanAdd = true
      } else if (permission.name === `${name}_edit`) {
        _actions.isCanEdit = true
      } else if (permission.name === `${name}_delete`) {
        _actions.isCanDelete = true
      } else if (permission.name === `${name}_reconcile`) {
        _actions.isCanReconciliation = true
      } else if (permission.name === 'conversion_balance') {
        _actions.isCanConversionBalance = true
      }
      _actions[permission.name] = true
      if (permission.child && Object.keys(permission.child).length > 0 && recursive) {
        _actions = getModulePermissionsRecursive({
          recursive,
          permissions,
          currentPermissions: permission.child,
          name,
          actions: _actions,
        })
      }
    })
  }

  return _actions
}
export const getModulePermissions = (state, name, recursive) => {
  const permissions = getPermissions(state)
  return getModulePermissionsRecursive({
    recursive,
    permissions,
    name,
    actions: {},
  })
}
const checkPermissionMain = (permissions, name) => {
  let findPermission = false
  // eslint-disable-next-line consistent-return
  permissions.forEach((perms) => {
    if (perms.name === name) findPermission = true
    if (findPermission) return findPermission
    if (perms.child && Object.keys(perms.child).length > 0) {
      const childPerms = Object.keys(perms.child).map((key) => perms.child[key])
      findPermission = checkPermissionMain(childPerms, name)
    }
  })
  return findPermission
}
export const checkPermission = (state, name) => {
  const permissions = getPermissions(state)
  return checkPermissionMain(permissions, name)
}
export const getFirstUrl = (data, url) => {
  for (let i = 0; i < data.length; i += 1) {
    if (data[i].sub) {
      url = getFirstUrl(data[i].sub, url)
      break
    } else if (!data[i].divider && data[i].url && data[i].url !== '/') {
      // eslint-disable-next-line prefer-destructuring
      url = data[i].url
      break
    }
  }
  return url
}
export const isGrantToDashboard = (state) => {
  const menus = getMenus(state)
  const dashboard = menus.dashboard || []
  const findDashboard = dashboard.find((row) => row.name === 'home')

  return !!findDashboard
}
export const getRedirectUrl = (menus) => {
  if (!menus) {
    return '/'
  }
  const _menus = _.cloneDeep(menus)
  const dashboard = _menus.dashboard || []
  // eslint-disable-next-line no-return-assign
  const settings = _menus.settings ? _menus.settings.map((e) => (e.url = `/settings${e.url}`)) : []
  let url = getFirstUrl(dashboard, '')
  if (url === '') {
    url = getFirstUrl(settings, '')
  }

  return url === '' ? '/' : url
}
