import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import {
  deleteOnAddFinanceBankTransAttachment,
  executeImportFinanceBankTransfer,
  getFinanceBankTranses,
  getRecurringBankTranses,
  getTotalFinanceBankTrans,
  invoicePaymentFinanceBankTrans,
  joinedInvoicePaymentFinanceBankTrans,
  joinedPurchasePaymentFinanceBankTrans,
  purchasePaymentFinanceBankTrans,
  uploadImportFinanceBankTransfer,
} from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const useBankTranses = ({ payload = {}, enabled = false }) => {
  const options = useSelector(optionsSelector)
  const dispatch = useDispatch()

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (payload.per_page && payload.per_page !== options.banks_per_page_default) {
    dispatch(
      editOption({
        banks_per_page_default: payload.per_page > 100 ? 100 : payload.per_page,
      }),
    )
  }

  return useQuery(
    ['bankTrans', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceBankTranses(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useTotalBankTrans = ({ payload = {}, options = {} }) => {
  return useQuery(
    ['total-bank-trans', payload],
    async () => {
      const {
        data: { data },
      } = await getTotalFinanceBankTrans(payload)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useRecurringBankTranses = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['bankTrans', 'recurring', payload],
    async () => {
      const {
        data: { data },
      } = await getRecurringBankTranses(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export const useInvoicePayment = () => {
  return useMutation((payload) => invoicePaymentFinanceBankTrans(payload))
}

export const usePurchaseInvoicePayment = () => {
  return useMutation((payload) => purchasePaymentFinanceBankTrans(payload))
}

export const useJoinInvoicePayment = () => {
  return useMutation((payload) => joinedInvoicePaymentFinanceBankTrans(payload))
}

export const useJoinPurchaseInvoicePayment = () => {
  return useMutation((payload) => joinedPurchasePaymentFinanceBankTrans(payload))
}

export const useBankTransDeleteAttachment = () => {
  return useMutation((payload) => deleteOnAddFinanceBankTransAttachment(payload))
}

export const useUploadImportFinanceBankTransfer = () => {
  return useMutation((payload) => uploadImportFinanceBankTransfer(payload))
}

export const useExecuteImportFinanceBankTransfer = () => {
  return useMutation((payload) => executeImportFinanceBankTransfer(payload))
}
