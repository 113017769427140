import { message } from 'antd'

import { getLayoutReportVariable } from 'utils/apis'

const DATA_LANG_HEADER_KEY_PAIR = {
  assets: 'lang_assets',
  liabilitiesAndEquity: 'lang_liabilities_and_equity',
}

const DATA_LANG_KEY_PAIR = {
  cashBank: 'lang_cash_bank',
  currentAssets: 'lang_current_assets',
  fixedAssets: 'lang_fixed_assets',
  depreciationAndAmortization: 'lang_depreciation_and_amortization',
  otherAsset: 'lang_other_asset',
  currentLiabilities: 'lang_current_liabilities',
  longtermLiabilities: 'lang_long_term_liabilities',
  equity: 'lang_equity',
}

const getTotal = (key) => `lang_total_${key.split('lang_')[1]}`
const getRecordFormatted = ({ key, dataLang, spreadData }) => {
  const { data, ..._spreadData } = spreadData
  return {
    ..._spreadData,
    name: dataLang[key],
    key,
    total_name: dataLang[getTotal(key)],
    total_key: getTotal(key),
    data,
  }
}

export const formatBalanceSheet = async ({ data, print_url }) => {
  const finalData = {
    data: [],
    printUrl: print_url,
  }

  try {
    const response = await getLayoutReportVariable('neraca')

    const isObject = (variable) =>
      typeof variable === 'object' && variable !== null && !Array.isArray(variable)
    const formattedData = {}
    const dataLang = response.data?.data ?? {}

    if (response.data.success) {
      Object.entries(data).forEach(([key, value]) => {
        const newData = {
          assets: value.assets,
          liabilities_equity: value.liabilities_equity,
        }
        newData.assets = getRecordFormatted({
          key: DATA_LANG_HEADER_KEY_PAIR.assets,
          dataLang,
          spreadData: newData.assets,
        })
        newData.assets.data.bank = getRecordFormatted({
          key: DATA_LANG_KEY_PAIR.cashBank,
          dataLang,
          spreadData: newData.assets.data.bank,
        })
        newData.assets.data.current_assets = getRecordFormatted({
          key: DATA_LANG_KEY_PAIR.currentAssets,
          dataLang,
          spreadData: newData.assets.data.current_assets,
        })
        newData.assets.data.fixed_assets = getRecordFormatted({
          key: DATA_LANG_KEY_PAIR.fixedAssets,
          dataLang,
          spreadData: newData.assets.data.fixed_assets,
        })
        newData.assets.data.depreciation = getRecordFormatted({
          key: DATA_LANG_KEY_PAIR.depreciationAndAmortization,
          dataLang,
          spreadData: newData.assets.data.depreciation,
        })
        newData.assets.data.other_assets = getRecordFormatted({
          key: DATA_LANG_KEY_PAIR.otherAsset,
          dataLang,
          spreadData: newData.assets.data.other_assets,
        })

        newData.liabilities_equity = getRecordFormatted({
          key: DATA_LANG_HEADER_KEY_PAIR.liabilitiesAndEquity,
          dataLang,
          spreadData: newData.liabilities_equity,
        })
        newData.liabilities_equity.data.current_liabilities = getRecordFormatted({
          key: DATA_LANG_KEY_PAIR.currentLiabilities,
          dataLang,
          spreadData: newData.liabilities_equity.data.current_liabilities,
        })
        newData.liabilities_equity.data.long_term_liabilities = getRecordFormatted({
          key: DATA_LANG_KEY_PAIR.longtermLiabilities,
          dataLang,
          spreadData: newData.liabilities_equity.data.long_term_liabilities,
        })
        newData.liabilities_equity.data.equity = getRecordFormatted({
          key: DATA_LANG_KEY_PAIR.equity,
          dataLang,
          spreadData: newData.liabilities_equity.data.equity,
        })

        if (!isObject(newData.liabilities_equity.data.equity.data)) {
          const equityEarningsUpToLastPeriodIndex =
            newData.liabilities_equity.data.equity.data.findIndex((eq) => {
              return eq.name === 'earnings_up_to_last_period'
            })

          if (equityEarningsUpToLastPeriodIndex > -1) {
            newData.liabilities_equity.data.equity.data[equityEarningsUpToLastPeriodIndex] = {
              ...newData.liabilities_equity.data.equity.data[equityEarningsUpToLastPeriodIndex],
              name: 'lang_earnings_up_to_last_period',
              account: {
                ...newData.liabilities_equity.data.equity.data[equityEarningsUpToLastPeriodIndex]
                  .account,
                name: dataLang.lang_earnings_up_to_last_period,
              },
              editable: true,
            }
          }

          const equityCurrentPeriodEarningsIndex =
            newData.liabilities_equity.data.equity.data.findIndex((eq) => {
              return eq.name === 'current_period_earnings'
            })
          if (equityCurrentPeriodEarningsIndex > -1) {
            newData.liabilities_equity.data.equity.data[equityCurrentPeriodEarningsIndex] = {
              ...newData.liabilities_equity.data.equity.data[equityCurrentPeriodEarningsIndex],
              name: 'lang_current_period_earnings',
              account: {
                ...newData.liabilities_equity.data.equity.data[equityCurrentPeriodEarningsIndex]
                  .account,
                name: dataLang.lang_current_period_earnings,
              },
              editable: true,
            }
          }
        }

        formattedData[key] = newData
      })

      finalData.data = formattedData || {}
      finalData.printUrl = print_url
      return finalData
    }
    message.error(response.data.message)
    return finalData
  } catch (error) {
    return finalData
  }
}
