import { lazy } from 'react'
import { appMessage } from 'utils/formatting'

const routes = [
  {
    path: '/companies',
    component: lazy(() => import('pages/companies')),
    exact: true,
  },
  {
    path: '/companies/add',
    component: lazy(() => import('pages/companies/Add')),
    exact: true,
  },
  {
    path: '/settings/notification',
    component: lazy(() => import('pages/notification')),
    exact: true,
  },
  {
    path: '/settings/posConnect',
    component: lazy(() => import('pages/posConnect')),
    exact: true,
  },
  {
    path: '/settings/posConnect/add',
    component: lazy(() => import('pages/posConnect/Add')),
    exact: true,
  },
  {
    path: '/settings/posConnect/add/:pos',
    component: lazy(() => import('pages/posConnect/AddForm')),
    exact: true,
  },
  {
    path: '/settings/posConnect/edit/:id',
    component: lazy(() => import('pages/posConnect/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/marketplaceConnect',
    component: lazy(() => import('pages/marketplaceConnect')),
    exact: true,
  },
  {
    path: '/settings/marketplaceConnect/add',
    component: lazy(() => import('pages/marketplaceConnect/Add')),
    exact: true,
  },
  {
    path: '/settings/marketplaceConnect/add/:marketplace',
    component: lazy(() => import('pages/marketplaceConnect/AddForm')),
    exact: true,
  },
  {
    path: '/settings/marketplaceConnect/edit/:id',
    component: lazy(() => import('pages/marketplaceConnect/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/apps',
    component: lazy(() => import('pages/appsSetting')),
    exact: true,
  },
  {
    path: '/settings/apps/add',
    component: lazy(() => import('pages/appsSetting/Add')),
    exact: true,
  },
  {
    path: '/settings/apps/detail/:id',
    component: lazy(() => import('pages/appsSetting/Detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/whatsappTemplate',
    component: lazy(() => import('pages/whatsappTemplate')),
    exact: true,
  },
  {
    path: '/settings/email-template',
    component: lazy(() => import('pages/emailTemplate')),
    exact: true,
  },
  {
    path: '/settings/account-mapping',
    component: lazy(() => import('pages/accountMapping')),
    exact: true,
  },
  {
    path: '/confirm/email/:token',
    component: lazy(() => import('pages/emailTemplate/confirm')),
    exact: true,
    breadcrumb: appMessage('email.email_confirmation'),
  },

  {
    path: '/referral',
    component: lazy(() => import('pages/referral')),
    exact: true,
  },

  {
    path: '/settings/custom-columns',
    component: lazy(() => import('pages/customColumn')),
    exact: true,
  },

  {
    path: '/settings/shipping-company',
    component: lazy(() => import('pages/shippingCompany')),
    exact: true,
  },

  {
    path: '/settings/consolidation',
    component: lazy(() => import('pages/consolidation')),
    exact: true,
  },

  {
    path: '/settings/unit',
    component: lazy(() => import('pages/financeUnits')),
    exact: true,
  },

  {
    path: '/settings/translation',
    component: lazy(() => import('pages/translation')),
    exact: true,
  },

  {
    path: '/settings/audit',
    component: lazy(() => import('pages/audit')),
    exact: true,
  },

  {
    path: '/settings/multi-currency',
    component: lazy(() => import('pages/multiCurrency')),
    exact: true,
  },

  {
    path: '/user/billing/success',
    component: lazy(() => import('pages/billing/Success')),
    exact: true,
  },
  {
    path: '/user/billing/failed',
    component: lazy(() => import('pages/billing/Failed')),
    exact: true,
  },
  {
    path: '/settings/billing',
    component: lazy(() => import('pages/billing')),
    exact: true,
  },
  {
    path: '/settings/billing/upgrade',
    component: lazy(() => import('pages/billing/UpgradeBilling')),
    exact: true,
  },
  {
    path: '/settings/billing/downgrade',
    component: lazy(() => import('pages/billing/DowngradeBilling')),
    exact: true,
  },
  {
    path: '/settings/billing/addon',
    component: lazy(() => import('pages/billing/AddAddon')),
    exact: true,
  },
  {
    path: '/settings/billing/pos',
    component: lazy(() => import('pages/billing/POS')),
    exact: true,
  },
  {
    path: '/settings/billing/stamp',
    component: lazy(() => import('pages/billing/StampBilling')),
    exact: true,
  },
  {
    path: '/settings/billing/whatsapp',
    component: lazy(() => import('pages/billing/WhatsappBilling')),
    exact: true,
  },
  {
    path: '/settings/users',
    component: lazy(() => import('pages/users')),
    exact: true,
  },
  {
    path: '/settings/roles',
    component: lazy(() => import('pages/roles')),
    exact: true,
  },
  {
    path: '/settings/roles/add',
    component: lazy(() => import('pages/roles/AddRole')),
    exact: true,
  },
  {
    path: '/settings/roles/edit/:id',
    component: lazy(() => import('pages/roles/EditRole')),
    exact: true,
    breadcrumb: null,
  },

  {
    path: '/settings/sales-commissions',
    component: lazy(() => import('pages/salesCommission')),
    exact: true,
  },
  {
    path: '/settings/sales-commissions/add',
    component: lazy(() => import('pages/salesCommission/AddSalesCommission')),
    exact: true,
  },
  {
    path: '/settings/sales-commissions/detail/:id',
    component: lazy(() => import('pages/salesCommission/DetailSalesCommission')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/sales-commissions/edit/:id',
    component: lazy(() => import('pages/salesCommission/EditSalesCommission')),
    exact: true,
    breadcrumb: null,
  },

  // System Pages
  {
    path: '/onboarding',
    component: lazy(() => import('pages/onBoarding')),
    exact: true,
  },
  {
    path: '/user/login',
    component: lazy(() => import('pages/user/login')),
    exact: true,
    breadcrumb: 'Login',
  },
  {
    path: '/user/login/sso',
    component: lazy(() => import('pages/user/login/sso')),
    exact: true,
    breadcrumb: 'Login',
  },
  {
    path: '/user/select-company',
    component: lazy(() => import('pages/user/select-company')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: lazy(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/register',
    component: lazy(() => import('pages/user/register')),
    exact: true,
  },
  {
    path: '/user/reset-password/:token',
    component: lazy(() => import('pages/user/reset-password')),
    exact: true,
  },
  {
    path: '/user/invite/:token',
    component: lazy(() => import('pages/user/invite')),
    exact: true,
  },
  {
    path: '/settings/profile',
    component: lazy(() => import('pages/user/profile')),
    exact: true,
  },

  {
    path: '/settings/company',
    component: lazy(() => import('pages/company')),
    exact: true,
  },

  // Dashboards
  {
    path: '/dashboard',
    component: lazy(() => import('pages/dashboard')),
    breadcrumb: null,
  },
  {
    path: '/purchases/overview',
    component: lazy(() => import('pages/purchases')),
  },
  {
    path: '/sales/overview',
    component: lazy(() => import('pages/sales')),
  },
  // Reports
  {
    path: '/reports/sales-per-product-category',
    component: lazy(() => import('pages/reports/salesPerProductCategory')),
    exact: true,
  },
  {
    path: '/reports/product-purchases-per-vendor',
    component: lazy(() => import('pages/reports/productPurchasesPerVendor')),
    exact: true,
  },
  {
    path: '/reports/product-sales-per-customer',
    component: lazy(() => import('pages/reports/productSalesPerCustomer')),
    exact: true,
  },
  {
    path: '/reports/sales-per-period',
    component: lazy(() => import('pages/reports/salesPerPeriod')),
    exact: true,
  },
  {
    path: '/reports/purchases-per-period',
    component: lazy(() => import('pages/reports/purchasesPerPeriod')),
    exact: true,
  },
  {
    path: '/reports/inventory-summary',
    component: lazy(() => import('pages/reports/inventorySummary')),
    exact: true,
  },
  {
    path: '/reports/product-serial-stock-movement',
    component: lazy(() => import('pages/reports/inventoryProductSerialStockMovement')),
    exact: true,
  },
  {
    path: '/reports/inventory-product-serial-expiry-age',
    component: lazy(() => import('pages/reports/inventoryProductSerialExpiryAge')),
    exact: true,
  },
  {
    path: '/reports/inventory-production-serial-number-history',
    component: lazy(() => import('pages/reports/inventoryProductionSerialNumberHistory')),
    exact: true,
  },
  {
    path: '/reports/inventory-stock-movement',
    component: lazy(() => import('pages/reports/inventoryStockMovement')),
    exact: true,
  },
  {
    path: '/reports/warehouse-stock-summary',
    component: lazy(() => import('pages/reports/warehouseStockSummary')),
    exact: true,
  },
  {
    path: '/reports/warehouse-stock-movement',
    component: lazy(() => import('pages/reports/warehouseStockMovement')),
    exact: true,
  },
  {
    path: '/reports/inventory-stock-adjustment',
    component: lazy(() => import('pages/reports/inventoryStockAdjustment')),
    exact: true,
  },
  {
    path: '/reports/warehouse-transfer',
    component: lazy(() => import('pages/reports/inventoryWarehouseTransfer')),
    exact: true,
  },
  {
    path: '/reports/inventory-product-serial-stock',
    component: lazy(() => import('pages/reports/inventoryProductSerialStock')),
    exact: true,
  },
  {
    path: '/reports/inventory-product-serial-expires-soon',
    component: lazy(() => import('pages/reports/inventoryProductSerialExpiresSoon')),
    exact: true,
  },
  {
    path: '/reports/production',
    component: lazy(() => import('pages/reports/production')),
    exact: true,
  },
  {
    path: '/reports/general-ledger',
    component: lazy(() => import('pages/reports/generalLedger')),
    exact: true,
  },
  {
    path: '/reports/general-ledger/category/:id',
    component: lazy(() => import('pages/reports/generalLedger/Category')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/reports/journal',
    component: lazy(() => import('pages/reports/journalReporting')),
    exact: true,
  },
  {
    path: '/reports/bank-summary',
    component: lazy(() => import('pages/reports/bankSummary')),
    exact: true,
  },
  {
    path: '/reports/sales-tax',
    component: lazy(() => import('pages/reports/salesTax')),
    exact: true,
  },
  {
    path: '/reports/customer-invoice',
    component: lazy(() => import('pages/reports/customerInvoice')),
    exact: true,
  },
  {
    path: '/reports/profitability-per-invoice',
    component: lazy(() => import('pages/reports/profitabilityPerInvoice')),
    exact: true,
  },
  {
    path: '/reports/supplier-invoice',
    component: lazy(() => import('pages/reports/supplierInvoice')),
    exact: true,
  },
  {
    path: '/reports/sales-detail',
    component: lazy(() => import('pages/reports/salesDetail')),
    exact: true,
  },
  {
    path: '/reports/purchase-detail',
    component: lazy(() => import('pages/reports/purchaseDetail')),
    exact: true,
  },
  {
    path: '/reports/aged-receivable',
    component: lazy(() => import('pages/reports/agedReceivable')),
    exact: true,
  },
  {
    path: '/reports/aged-receivable/detail/:id',
    component: lazy(() => import('pages/reports/agedReceivableDetail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/reports/aged-payable',
    component: lazy(() => import('pages/reports/agedPayable')),
    exact: true,
  },
  {
    path: '/reports/aged-payable/detail/:id',
    component: lazy(() => import('pages/reports/agedPayableDetail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/reports/expense-claim',
    component: lazy(() => import('pages/reports/expenseClaim')),
    exact: true,
  },
  {
    path: '/reports/expense-claim/detail/:id',
    component: lazy(() => import('pages/reports/expenseClaimDetail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/reports/income-per-customer',
    component: lazy(() => import('pages/reports/incomePerCustomer')),
    exact: true,
  },
  {
    path: '/reports/trial-balance',
    component: lazy(() => import('pages/reports/trialBalance')),
    exact: true,
  },
  {
    path: '/reports/profit-loss',
    component: lazy(() => import('pages/reports/profitLoss')),
    exact: true,
  },
  {
    path: '/reports/expense-per-contact',
    component: lazy(() => import('pages/reports/expensePerContact')),
    exact: true,
  },
  {
    path: '/reports/sales-per-product',
    component: lazy(() => import('pages/reports/salesPerProduct')),
    exact: true,
  },
  {
    path: '/reports/order-per-product',
    component: lazy(() => import('pages/reports/orderPerProduct')),
    exact: true,
  },
  {
    path: '/reports/account-transaction/:id',
    component: lazy(() => import('pages/reports/accountTransaction')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/reports/purchases-per-vendor',
    component: lazy(() => import('pages/reports/purchasesPerVendor')),
    exact: true,
  },
  {
    path: '/reports/purchases-per-product',
    component: lazy(() => import('pages/reports/purchasesPerProduct')),
    exact: true,
  },
  {
    path: '/reports',
    component: lazy(() => import('pages/reports/reportingHome')),
    exact: true,
    breadcrumb: appMessage('report.reports'),
  },
  {
    path: '/reports/cash-flow',
    component: lazy(() => import('pages/reports/cashFlow')),
    exact: true,
  },
  {
    path: '/reports/balance-sheet',
    component: lazy(() => import('pages/reports/balanceSheet')),
    exact: true,
  },
  {
    path: '/reports/equity-movement',
    component: lazy(() => import('pages/reports/equityMovement')),
    exact: true,
  },
  {
    path: '/reports/executive-summary',
    component: lazy(() => import('pages/reports/executiveSummary')),
    exact: true,
  },
  {
    path: '/reports/receivable-payable-per-contact',
    component: lazy(() => import('pages/reports/receivablePayable')),
    exact: true,
  },
  {
    path: '/reports/fixed-asset-detail',
    component: lazy(() => import('pages/reports/fixedAssetDetail')),
    exact: true,
  },
  {
    path: '/reports/fixed-asset-summary',
    component: lazy(() => import('pages/reports/fixedAssetSummary')),
    exact: true,
  },
  {
    path: '/reports/fixed-asset-disposal',
    component: lazy(() => import('pages/reports/fixedAssetDisposal')),
    exact: true,
  },
  {
    path: '/reports/consolidation-balance-sheet',
    component: lazy(() => import('pages/reports/consolidationBalanceSheet')),
    exact: true,
  },
  {
    path: '/reports/consolidation-cash-flow',
    component: lazy(() => import('pages/reports/consolidationCashFlow')),
    exact: true,
  },
  {
    path: '/reports/consolidation-profit-loss',
    component: lazy(() => import('pages/reports/consolidationProfitLoss')),
    exact: true,
  },
  {
    path: '/reports/consolidation-worksheet',
    component: lazy(() => import('pages/reports/consolidationWorksheet')),
    exact: true,
  },
  {
    path: '/reports/sales-delivery',
    component: lazy(() => import('pages/reports/salesDelivery')),
    exact: true,
  },
  {
    path: '/reports/purchase-delivery',
    component: lazy(() => import('pages/reports/purchaseDelivery')),
    exact: true,
  },
  {
    path: '/reports/budget-management',
    component: lazy(() => import('pages/reports/budgetManagement')),
    exact: true,
  },
  {
    path: '/reports/budget-management/add',
    component: lazy(() => import('pages/reports/budgetManagement/Add')),
    exact: true,
  },
  {
    path: '/reports/budget-management/edit/:id',
    component: lazy(() => import('pages/reports/budgetManagement/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/reports/budget-profit-loss',
    component: lazy(() => import('pages/reports/budgetProfitLoss')),
    exact: true,
  },
  {
    path: '/reports/shipping-cost-per-expedition',
    component: lazy(() => import('pages/reports/shippingCostPerExpedition')),
    exact: true,
  },
  {
    path: '/reports/sales-per-sales-person',
    component: lazy(() => import('pages/reports/salesPerSalesPerson')),
    exact: true,
  },
  {
    path: '/reports/product-profitability',
    component: lazy(() => import('pages/reports/productProfitability')),
    exact: true,
  },
  {
    path: '/reports/invoice-payment',
    component: lazy(() => import('pages/reports/invoicePayment')),
    exact: true,
  },
  {
    path: '/reports/purchase-invoice-payment',
    component: lazy(() => import('pages/reports/purchaseInvoicePayment')),
    exact: true,
  },
  {
    path: '/reports/pos-shift',
    component: lazy(() => import('pages/reports/posShift')),
    exact: true,
  },
  {
    path: '/reports/pos-shift/detail/:id',
    component: lazy(() => import('pages/reports/posShift/Detail')),
    exact: true,
    breadcrumb: appMessage('detail'),
  },
  {
    path: '/reports/attachment',
    component: lazy(() => import('pages/reports/attachment')),
    exact: true,
  },
  {
    path: '/reports/export',
    component: lazy(() => import('pages/reports/export')),
    exact: true,
  },
  {
    path: '/reports/import',
    component: lazy(() => import('pages/reports/import')),
    exact: true,
  },
  {
    path: '/reports/import/:id',
    component: lazy(() => import('pages/reports/import/Detail')),
    exact: true,
    breadcrumb: appMessage('detail'),
  },
  {
    path: '/reports/duty-stamp',
    component: lazy(() => import('pages/reports/dutyStamp')),
    exact: true,
  },
  {
    path: '/reports/team-activities',
    component: lazy(() => import('pages/reports/teamActivities')),
    exact: true,
  },
  {
    path: '/settings/setup',
    component: lazy(() => import('pages/setup')),
  },
  {
    path: '/accounts',
    component: lazy(() => import('pages/financeAccounts')),
    exact: true,
  },
  {
    path: '/accounts/import',
    component: lazy(() => import('pages/financeAccounts/Import')),
    exact: true,
  },
  {
    path: '/contacts/import',
    component: lazy(() => import('pages/financeContacts/Import')),
    exact: true,
  },
  {
    path: '/contacts/import-accounts-payable',
    component: lazy(() => import('pages/financeContacts/ImportAccountsPayable')),
    exact: true,
  },
  {
    path: '/contacts',
    component: lazy(() => import('pages/financeContacts')),
    exact: true,
  },
  {
    path: '/contacts/detail/:id',
    component: lazy(() => import('pages/financeContacts/detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/contacts/edit/:id',
    component: lazy(() => import('pages/financeContacts/edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/expenses/terms',
    component: lazy(() => import('pages/financeExpenseTerms')),
    exact: true,
  },
  {
    path: '/products/detail/:id',
    component: lazy(() => import('pages/financeProducts/detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/products',
    component: lazy(() => import('pages/financeProducts')),
    exact: true,
  },
  {
    path: '/products/access/:id',
    component: lazy(() => import('pages/financeProducts/ProductAccess')),
    exact: true,
  },
  {
    path: '/products/price-rule',
    component: lazy(() => import('pages/financeProducts/PriceRule')),
    exact: true,
  },
  {
    path: '/products/price-rule/add',
    component: lazy(() => import('pages/financeProducts/AddPriceRule')),
    exact: true,
  },
  {
    path: '/products/price-rule/detail/:id',
    component: lazy(() => import('pages/financeProducts/DetailPriceRule')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/products/price-rule/edit/:id',
    component: lazy(() => import('pages/financeProducts/EditPriceRule')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/products/price-rule/import',
    component: lazy(() => import('pages/financeProducts/ImportPriceRule')),
    exact: true,
  },
  {
    path: '/products/import',
    component: lazy(() => import('pages/financeProducts/Import')),
    exact: true,
  },
  {
    path: '/products/import-package',
    component: lazy(() => import('pages/financeProducts/ImportPackage')),
    exact: true,
  },
  {
    path: '/products/import-manufacture',
    component: lazy(() => import('pages/financeProducts/ImportManufacture')),
    exact: true,
  },
  {
    path: '/products/import-conversion',
    component: lazy(() => import('pages/financeProducts/ImportConversion')),
    exact: true,
  },
  {
    path: '/products/bundle-package/add/:id?',
    component: lazy(() => import('pages/financeProducts/AddBundlePackage')),
    exact: true,
  },
  {
    path: '/products/bundle-manufacture/add/:id?',
    component: lazy(() => import('pages/financeProducts/AddBundleManufacture')),
    exact: true,
  },
  {
    path: '/products/bundle-package/edit/:id',
    component: lazy(() => import('pages/financeProducts/EditBundlePackage')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/products/bundle-manufacture/edit/:id',
    component: lazy(() => import('pages/financeProducts/EditBundleManufacture')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/products/conversions/:id',
    component: lazy(() => import('pages/financeProducts/ConversionProduct')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/products/conversions/detail/:id',
    component: lazy(() => import('pages/financeProducts/ConversionProductDetail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/products/conversions/edit/:id',
    component: lazy(() => import('pages/financeProducts/EditConversionProduct')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/taxes',
    component: lazy(() => import('pages/financeTaxes')),
    exact: true,
  },
  {
    path: '/expenses',
    component: lazy(() => import('pages/financeExpenses')),
    exact: true,
  },
  {
    path: '/expenses/import',
    component: lazy(() => import('pages/financeExpenses/Import')),
    exact: true,
  },
  {
    path: '/expenses/add/:id?',
    component: lazy(() => import('pages/financeExpenses/AddFinanceExpense')),
    exact: true,
    breadcrumb: appMessage('add'),
  },
  {
    path: '/expenses/edit/:id',
    component: lazy(() => import('pages/financeExpenses/EditFinanceExpense')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/expenses/detail/:id',
    component: lazy(() => import('pages/financeExpenses/DetailFinanceExpense')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseorders',
    component: lazy(() => import('pages/financePurchaseOrders')),
    exact: true,
    breadcrumb: appMessage('purchase_order'),
  },
  {
    path: '/purchases/purchaseorders/add/:idQuote?',
    component: lazy(() => import('pages/financePurchaseOrders/AddFinancePurchaseOrder')),
    exact: true,
    breadcrumb: appMessage('add'),
  },
  {
    path: '/purchases/purchaseorders/edit/:id',
    component: lazy(() => import('pages/financePurchaseOrders/EditFinancePurchaseOrder')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseorders/detail/:id',
    component: lazy(() => import('pages/financePurchaseOrders/DetailFinancePurchaseOrder')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseorders/import',
    component: lazy(() => import('pages/financePurchaseOrders/Import')),
    exact: true,
  },
  {
    path: '/purchases/purchaseinvoices',
    component: lazy(() => import('pages/financePurchaseInvoices')),
    exact: true,
    breadcrumb: appMessage('purchase_invoice'),
  },
  {
    path: '/purchases/purchaseinvoices/add/:idRelation?',
    component: lazy(() => import('pages/financePurchaseInvoices/AddFinancePurchaseInvoice')),
    exact: true,
  },
  {
    path: '/purchases/purchaseinvoices/edit/:id',
    component: lazy(() => import('pages/financePurchaseInvoices/EditFinancePurchaseInvoice')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseinvoices/detail/:id',
    component: lazy(() => import('pages/financePurchaseInvoices/DetailFinancePurchaseInvoice')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseinvoices/import',
    component: lazy(() => import('pages/financePurchaseInvoices/Import')),
    exact: true,
  },
  {
    path: '/purchases/purchaseinvoices/import-recurring',
    component: lazy(() => import('pages/financePurchaseInvoices/ImportRecurring')),
    exact: true,
  },
  {
    path: '/purchases/purchaseinvoices/importStatus',
    component: lazy(() => import('pages/financePurchaseInvoices/ImportStatus')),
    exact: true,
  },
  {
    path: '/purchases/purchaseinvoices/join-invoice',
    component: lazy(() => import('pages/financePurchaseInvoices/AddJoinPurchaseInvoice')),
    exact: true,
  },
  {
    path: '/purchases/purchaseinvoices/join-invoice/edit/:id',
    component: lazy(() => import('pages/financePurchaseInvoices/EditJoinPurchaseInvoice')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseinvoices/detail/merged-invoices/:id',
    component: lazy(() => import('pages/financePurchaseInvoices/DetailJoinPurchaseInvoice')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/quotes',
    component: lazy(() => import('pages/financeQuotes')),
    exact: true,
    breadcrumb: appMessage('quote'),
  },
  {
    path: '/sales/quotes/add/:id?',
    component: lazy(() => import('pages/financeQuotes/AddFinanceQuote')),
    exact: true,
    breadcrumb: appMessage('add'),
  },
  {
    path: '/sales/quotes/edit/:id',
    component: lazy(() => import('pages/financeQuotes/EditFinanceQuote')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/quotes/detail/:id',
    component: lazy(() => import('pages/financeQuotes/DetailFinanceQuote')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/quotes/import',
    component: lazy(() => import('pages/financeQuotes/Import')),
    exact: true,
  },
  {
    path: '/sales/invoices',
    component: lazy(() => import('pages/financeInvoices')),
    exact: true,
    breadcrumb: appMessage('invoice'),
  },
  {
    path: '/sales/invoices/add/:idRelation?',
    component: lazy(() => import('pages/financeInvoices/AddFinanceInvoice')),
    exact: true,
  },
  {
    path: '/sales/invoices/join-invoice',
    component: lazy(() => import('pages/financeInvoices/AddJoinInvoice')),
    exact: true,
  },
  {
    path: '/sales/invoices/join-invoice/edit/:id',
    component: lazy(() => import('pages/financeInvoices/EditJoinInvoice')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/invoices/detail/merged-invoices/:id',
    component: lazy(() => import('pages/financeInvoices/DetailJoinInvoice')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/invoices/edit/:id',
    component: lazy(() => import('pages/financeInvoices/EditFinanceInvoice')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/invoices/detail/:id',
    component: lazy(() => import('pages/financeInvoices/DetailFinanceInvoice')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/invoices/recurring/add',
    component: lazy(() => import('pages/recurring/Add')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/invoices/recurring/edit/:id',
    component: lazy(() => import('pages/recurring/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/invoices/import',
    component: lazy(() => import('pages/financeInvoices/Import')),
    exact: true,
  },
  {
    path: '/sales/invoices/import-recurring',
    component: lazy(() => import('pages/financeInvoices/ImportRecurring')),
    exact: true,
  },
  {
    path: '/sales/invoices/import-return',
    component: lazy(() => import('pages/financeInvoices/ImportReturn')),
    exact: true,
  },
  {
    path: '/sales/invoices/importStatus',
    component: lazy(() => import('pages/financeInvoices/ImportStatus')),
    exact: true,
  },
  {
    path: '/banks',
    component: lazy(() => import('pages/financeBanks')),
    exact: true,
    breadcrumb: 'Bank',
  },
  {
    path: '/banks/detail/:id',
    component: lazy(() => import('pages/financeBanks/DetailFinanceBank')),
  },
  {
    path: '/banks/:id/access',
    component: lazy(() => import('pages/financeAccounts/AccountAccess')),
  },
  {
    path: '/banks/transactions/import/:id',
    component: lazy(() => import('pages/financeBanks/ImportBankTrans')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/transfer/import/:id',
    component: lazy(() => import('pages/financeBanks/ImportBankTransfer')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/:bankAccountId/spend/:id?',
    component: lazy(() => import('pages/financeBanks/AddSpendFinanceBankTrans')),
    exact: true,
  },
  {
    path: '/banks/:bankAccountId/receive/:id?',
    component: lazy(() => import('pages/financeBanks/AddReceiveFinanceBankTrans')),
    exact: true,
  },
  {
    path: '/banks/:bankAccountId/transaction/edit/:id',
    component: lazy(() => import('pages/financeBanks/EditFinanceBankTrans')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/:bankAccountId/transaction/detail/:id',
    component: lazy(() => import('pages/financeBanks/DetailFinanceBankTrans')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/bankstatement/import/:id',
    component: lazy(() => import('pages/financeBanks/ImportBankStatement')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/connect',
    component: lazy(() => import('pages/financeBanks/Connect')),
    exact: true,
  },
  {
    path: '/banks/connect/detail/:id',
    component: lazy(() => import('pages/financeBanks/DetailConnect')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/connect/add/:id',
    component: lazy(() => import('pages/financeBanks/AddConnect')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/connect/edit/:id',
    component: lazy(() => import('pages/financeBanks/EditConnect')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/automatic-sequencing',
    component: lazy(() => import('pages/financeNumbers')),
    exact: true,
    breadcrumb: 'Automatic Sequencing',
  },
  {
    path: '/accounts/conversionbalances',
    component: lazy(() => import('pages/financeConversion/ConversionBalances')),
    exact: true,
  },
  {
    path: '/accounts/conversionbalances/import',
    component: lazy(() => import('pages/financeConversion/Import')),
    exact: true,
  },
  {
    path: '/accounts/conversionbalances/conversiondate',
    component: lazy(() => import('pages/financeConversion/ConversionDate')),
    exact: true,
  },
  {
    path: '/accounts/conversionbalances/reversal_detail/:id',
    component: lazy(() => import('pages/financeConversion/ReversalDetail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/accounts/conversionbalances/opening_balance/:id',
    component: lazy(() => import('pages/financeConversion/OpeningBalanceDetail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/manual-journal',
    component: lazy(() => import('pages/financeManualJournal')),
    exact: true,
  },
  {
    path: '/manual-journal/import',
    component: lazy(() => import('pages/financeManualJournal/ImportManualJournal')),
    exact: true,
  },
  {
    path: '/manual-journal/add/:idRelation?',
    component: lazy(() => import('pages/financeManualJournal/AddFinanceManualJournal')),
    exact: true,
  },
  {
    path: '/manual-journal/edit/:id',
    component: lazy(() => import('pages/financeManualJournal/EditFinanceManualJournal')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/manual-journal/detail/:id',
    component: lazy(() => import('pages/financeManualJournal/DetailFinanceManualJournal')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/fixed-assets',
    component: lazy(() => import('pages/financeFixedAssets')),
    exact: true,
  },
  {
    path: '/fixed-assets/import',
    component: lazy(() => import('pages/financeFixedAssets/ImportFixedAsset')),
    exact: true,
  },
  {
    path: '/fixed-assets/add/:id?',
    component: lazy(() => import('pages/financeFixedAssets/AddFinanceFixedAsset')),
    exact: true,
  },
  {
    path: '/fixed-assets/detail/:id',
    component: lazy(() => import('pages/financeFixedAssets/DetailFinanceFixedAsset')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/fixed-assets/edit/:id',
    component: lazy(() => import('pages/financeFixedAssets/EditFinanceFixedAsset')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/fixed-assets/dispose/:id',
    component: lazy(() => import('pages/financeFixedAssets/DisposeFixedAsset')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/fixed-assets/register/:id',
    component: lazy(() => import('pages/financeFixedAssets/RegisterFixedAsset')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/fixed-assets/run-depreciation',
    component: lazy(() => import('pages/financeFixedAssets/RunDepreciation')),
    exact: true,
  },
  {
    path: '/fixed-assets/transaction/:tranId',
    component: lazy(() => import('pages/financeFixedAssets/DetailFinanceFixedAssetTransaction')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/lock-date',
    component: lazy(() => import('pages/financeLockDate')),
    exact: true,
  },
  {
    path: '/reports/witholding-tax',
    component: lazy(() => import('pages/reports/witholdingTax')),
    exact: true,
  },
  {
    path: '/warehouses',
    component: lazy(() => import('pages/financeWareHouses')),
    exact: true,
  },
  {
    path: '/warehouses/detail/:id',
    component: lazy(() => import('pages/financeWareHouses/DetailFinanceWareHouse')),
    breadcrumb: null,
    exact: true,
  },
  {
    path: '/warehouses/access/:id',
    component: lazy(() => import('pages/financeWareHouses/WarehouseAccess')),
    breadcrumb: null,
    exact: true,
  },
  {
    path: '/warehouses/access-per-contact/:id',
    component: lazy(() => import('pages/financeWareHouses/WarehouseAccessPerContact')),
    breadcrumb: null,
    exact: true,
  },
  {
    path: '/warehouses/:warehouseId/transfer-in/:id?',
    component: lazy(() => import('pages/financeWareHouses/AddTransferIn')),
    exact: true,
  },
  {
    path: '/warehouses/:warehouseId/transfer-out/:id?',
    component: lazy(() => import('pages/financeWareHouses/AddTransferOut')),
    exact: true,
  },
  {
    path: '/warehouses/:id/transfer/import',
    component: lazy(() => import('pages/financeWareHouses/ImportTransfer')),
    exact: true,
  },
  {
    path: '/warehouses/:warehouseId/transfer/detail/:id',
    component: lazy(() => import('pages/financeWareHouses/DetailFinanceWareHouseTransfer')),
    exact: true,
  },
  {
    path: '/warehouses/:warehouseId/transfer/edit/:id',
    component: lazy(() => import('pages/financeWareHouses/EditTransferInOut')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/warehouses/:warehouseId/stock-adjustment/detail/:id',
    component: lazy(() => import('pages/financeWareHouses/DetailStockAdjustment')),
    exact: true,
  },
  {
    path: '/warehouses/:id/stock-adjustment/add/:relationId?',
    component: lazy(() => import('pages/financeWareHouses/AddStockAdjustment')),
    exact: true,
  },
  {
    path: '/warehouses/:id/stock-adjustment/import',
    component: lazy(() => import('pages/financeWareHouses/ImportStockAdjustment')),
    exact: true,
  },
  {
    path: '/warehouses/:warehouseId/stock-adjustment/edit/:id',
    component: lazy(() => import('pages/financeWareHouses/EditStockAdjustment')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/finance-approval',
    component: lazy(() => import('pages/approvals')),
    exact: true,
  },
  {
    path: '/settings/layout-invoice',
    component: lazy(() => import('pages/layoutInvoice')),
    exact: true,
  },
  {
    path: '/settings/layout-report',
    component: lazy(() => import('pages/layoutReport')),
    exact: true,
  },
  {
    path: '/settings/layout-barcode',
    component: lazy(() => import('pages/layoutBarcode')),
    exact: true,
  },
  {
    path: '/settings/tags',
    component: lazy(() => import('pages/financeTag')),
    exact: true,
  },
  {
    path: '/settings/tags/detail/:id',
    component: lazy(() => import('pages/financeTag/detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/tags/access/:id',
    component: lazy(() => import('pages/financeTag/TagAccess')),
    breadcrumb: null,
    exact: true,
  },
  {
    path: '/settings',
    component: lazy(() => import('pages/settings')),
    exact: true,
  },
  {
    path: '/settings/approval-setting',
    component: lazy(() => import('pages/approvals/ApprovalSettings')),
    exact: true,
  },
  {
    path: '/sales/orders',
    component: lazy(() => import('pages/financeOrder')),
    exact: true,
  },
  {
    path: '/sales/orders/import',
    component: lazy(() => import('pages/financeOrder/Import')),
    exact: true,
  },
  {
    path: '/sales/orders/detail/:id',
    component: lazy(() => import('pages/financeOrder/DetailFinanceOrder')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/orders/add/:idQuote?',
    component: lazy(() => import('pages/financeOrder/AddFinanceOrder')),
    exact: true,
  },
  {
    path: '/sales/orders/edit/:id',
    component: lazy(() => import('pages/financeOrder/EditFinanceOrder')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/orders/recurring/add',
    component: lazy(() => import('pages/recurring/Add')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/orders/recurring/edit/:id',
    component: lazy(() => import('pages/recurring/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/deliveries',
    component: lazy(() => import('pages/financeDelivery')),
    exact: true,
  },
  {
    path: '/sales/deliveries/add/:idOrder?',
    component: lazy(() => import('pages/financeDelivery/AddFinanceDelivery')),
    exact: true,
  },
  {
    path: '/sales/deliveries/detail/:id',
    component: lazy(() => import('pages/financeDelivery/DetailFinanceDelivery')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/deliveries/edit/:id',
    component: lazy(() => import('pages/financeDelivery/EditFinanceDelivery')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/credit-memo/add/:idContact',
    component: lazy(() => import('pages/creditMemo/AddCreditMemo')),
    exact: true,
  },
  {
    path: '/credit-memo/edit/:id',
    component: lazy(() => import('pages/creditMemo/EditCreditMemo')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/credit-memo/detail/:id',
    component: lazy(() => import('pages/creditMemo/DetailCreditMemo')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/returns/add/:idInvoice?',
    component: lazy(() => import('pages/return/AddReturn')),
    exact: true,
  },
  {
    path: '/sales/returns/detail/:id',
    component: lazy(() => import('pages/return/DetailReturn')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/sales/returns/edit/:id',
    component: lazy(() => import('pages/return/EditReturn')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/quotes',
    component: lazy(() => import('pages/financePurchaseQuotes')),
    exact: true,
    breadcrumb: appMessage('quote'),
  },
  {
    path: '/purchases/quotes/add/:id?',
    component: lazy(() => import('pages/financePurchaseQuotes/AddFinancePurchaseQuote')),
    exact: true,
    breadcrumb: appMessage('add'),
  },
  {
    path: '/purchases/quotes/edit/:id',
    component: lazy(() => import('pages/financePurchaseQuotes/EditFinancePurchaseQuote')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/debit-memo/edit/:id',
    component: lazy(() => import('pages/debitMemo/EditDebitMemo')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/quotes/detail/:id',
    component: lazy(() => import('pages/financePurchaseQuotes/DetailFinancePurchaseQuote')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/quotes/import',
    component: lazy(() => import('pages/financePurchaseQuotes/Import')),
    exact: true,
  },
  {
    path: '/debit-memo/detail/:id',
    component: lazy(() => import('pages/debitMemo/DetailDebitMemo')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/debit-memo/add/:idContact',
    component: lazy(() => import('pages/debitMemo/AddDebitMemo')),
    exact: true,
  },
  {
    path: '/purchases/deliveries',
    component: lazy(() => import('pages/financePurchaseDelivery')),
    exact: true,
  },
  {
    path: '/purchases/deliveries/add/:idOrder?',
    component: lazy(() => import('pages/financePurchaseDelivery/AddFinancePurchaseDelivery')),
    exact: true,
  },
  {
    path: '/purchases/deliveries/detail/:id',
    component: lazy(() => import('pages/financePurchaseDelivery/DetailFinancePurchaseDelivery')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/deliveries/edit/:id',
    component: lazy(() => import('pages/financePurchaseDelivery/EditFinancePurchaseDelivery')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/returns/add/:idInvoice?',
    component: lazy(() => import('pages/purchaseReturn/AddReturn')),
    exact: true,
  },
  {
    path: '/purchases/returns/detail/:id',
    component: lazy(() => import('pages/purchaseReturn/DetailReturn')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/returns/edit/:id',
    component: lazy(() => import('pages/purchaseReturn/EditReturn')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/business-flow',
    component: lazy(() => import('pages/businessFlow')),
    exact: true,
    // breadcrumb: 'Automatic Sequencing'
  },
  {
    path: '/purchases/purchaseinvoices/recurring/add',
    component: lazy(() => import('pages/recurring/Add')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseinvoices/recurring/edit/:id',
    component: lazy(() => import('pages/recurring/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseorders/recurring/add',
    component: lazy(() => import('pages/recurring/Add')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchases/purchaseorders/recurring/edit/:id',
    component: lazy(() => import('pages/recurring/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/expenses/recurring/add',
    component: lazy(() => import('pages/recurring/Add')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/expenses/recurring/edit/:id',
    component: lazy(() => import('pages/recurring/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/manual-journal/recurring/add',
    component: lazy(() => import('pages/recurring/Add')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/manual-journal/recurring/edit/:id',
    component: lazy(() => import('pages/recurring/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/:bankAccountId/recurring/add',
    component: lazy(() => import('pages/recurring/Add')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/banks/:bankAccountId/recurring/edit/:id',
    component: lazy(() => import('pages/recurring/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/closing',
    component: lazy(() => import('pages/closing')),
    exact: true,
  },
  {
    path: '/closing/add',
    component: lazy(() => import('pages/closing/Add')),
    // exact: true,
  },
  {
    path: '/closing/detail/:id',
    component: lazy(() => import('pages/closing/Detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/invoice-payment-credit-memo/detail/:id',
    component: lazy(() => import('pages/invoicePaymentCreditMemo/Detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchaseinvoice-payment-debit-memo/detail/:id',
    component: lazy(() => import('pages/purchaseInvoicePaymentDebitMemo/Detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/invoice-payment-credit-memo/edit/:id',
    component: lazy(() => import('pages/invoicePaymentCreditMemo/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/purchaseinvoice-payment-debit-memo/edit/:id',
    component: lazy(() => import('pages/purchaseInvoicePaymentDebitMemo/Edit')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/unrealized-gain/detail/:id',
    component: lazy(() => import('pages/unrealizedGain/Detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/payroll',
    component: lazy(() => import('pages/payroll')),
    exact: true,
    // breadcrumb: null,
  },
  {
    path: '/payroll/success',
    component: lazy(() => import('pages/payroll/SuccessIntegration')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/faqs',
    component: lazy(() => import('pages/faqs')),
    exact: true,
  },
  {
    path: '/faqs/detail/:id',
    component: lazy(() => import('pages/faqs/Detail')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/pos',
    component: lazy(() => import('pages/kledoPos')),
    exact: true,
    breadcrumb: 'Kledo Pos',
  },
  {
    path: '/pos/settings',
    component: lazy(() => import('pages/kledoPos/Settings')),
    exact: true,
  },
  {
    path: '/pos/outlets',
    component: lazy(() => import('pages/outlets/Outlets')),
    exact: true,
  },
  {
    path: '/pos/product-favorites',
    component: lazy(() => import('pages/financeProducts/Favorites')),
    exact: true,
  },
  {
    path: '/pos/users',
    component: lazy(() => import('pages/pos/users')),
    exact: true,
  },
  {
    path: '/pos/roles',
    component: lazy(() => import('pages/pos/Roles')),
    exact: true,
  },
  {
    path: '/settings/due-reminder',
    component: lazy(() => import('pages/dueReminder')),
    exact: true,
  },
  {
    path: '/settings/backup-database',
    component: lazy(() => import('pages/backupDatabase')),
    exact: true,
  },
  {
    path: '/settings/customer-birthday',
    component: lazy(() => import('pages/customerBirthday')),
    exact: true,
  },
  {
    path: '/kledo-pos',
    component: lazy(() => import('pages/kledoPos')),
    exact: true,
    breadcrumb: 'Kledo POS',
  },
  {
    path: '/settings/fees',
    component: lazy(() => import('pages/transactionFees')),
    exact: true,
  },
  {
    path: '/settings/sso',
    component: lazy(() => import('pages/sso')),
    exact: true,
  },
  {
    path: '/settings/payment-connect',
    component: lazy(() => import('pages/paymentConnect')),
    exact: true,
  },
  {
    path: '/settings/payment-connect/:id',
    component: lazy(() => import('pages/paymentConnect/detail')),
    exact: true,
  },
  {
    path: '/products/add/:id?',
    component: lazy(() => import('pages/financeProducts/AddProduct')),
    exact: true,
  },
  {
    path: '/products/edit/:id',
    component: lazy(() => import('pages/financeProducts/EditProduct')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/settings/tags/import',
    component: lazy(() => import('pages/financeTag/Import')),
    exact: true,
  },
  {
    path: '/pos/settings/pos-settings',
    component: lazy(() => import('pages/kledoPos/PosSettings')),
    exact: true,
  },
  {
    path: '/pos/settings/driver-online',
    component: lazy(() => import('pages/kledoPos/DriverOnline')),
    exact: true,
  },
  {
    path: '/pos/outlets/edit/:id',
    component: lazy(() => import('pages/outlets/EditOutlets')),
    exact: true,
    breadcrumb: null,
  },
  {
    path: '/pos/outlets/add',
    component: lazy(() => import('pages/outlets/AddOutlets')),
    exact: true,
  },
  {
    path: '/purchases/purchaseinvoices/import-return',
    component: lazy(() => import('pages/financePurchaseInvoices/ImportReturn')),
    exact: true,
  },
  {
    path: '/reports/purchase-order-per-product',
    component: lazy(() => import('pages/reports/purchaseOrderPerProduct')),
    exact: true,
  },
  {
    path: '/settings/profile/two-factor-authentication',
    component: lazy(() => import('pages/user/profile/TwoFactorAuthentication')),
    exact: true,
  },
  {
    path: '/user/login/two-factor-verification',
    component: lazy(() => import('pages/user/login/TwoFactorVerification')),
    exact: true,
  },
  {
    path: '/user/login/otp',
    component: lazy(() => import('pages/user/login/LoginOTP')),
    exact: true,
  },
]

export default routes
