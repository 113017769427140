import { useMutation } from 'react-query'
import { verifyTfa, enabledTfa, disabledTfa, registerTfa } from 'utils/apis/tfa'

export const useVerifyTfa = () => {
  return useMutation(verifyTfa)
}

export const useEnabledTfa = () => {
  return useMutation(enabledTfa)
}

export const useDisabledTfa = () => {
  return useMutation(disabledTfa)
}

export const useRegisterTfa = () => {
  return useMutation(registerTfa)
}
