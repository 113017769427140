import React from 'react'
import styles from './styles.module.scss'

const SocialLoginButton = ({ icon, label, onClick }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.socialLoginButton}
      onClick={onClick}
      onKeyDown={(e) => e.key === 'Enter' && onClick?.()}
    >
      <img src={`./resources/images/icons/${icon}.svg`} alt={`${label} Icon`} width={32} />
      <div className={styles.socialLoginText}>{label}</div>
    </div>
  )
}

export default SocialLoginButton 