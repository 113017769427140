import React from 'react'
import { injectIntl } from 'react-intl'
import { Select } from 'antd'
import { EditorState, Modifier } from 'draft-js'
import { connect } from 'react-redux'

import { getTerm } from 'utils/hooks/useTerm'

class TagsOption extends React.Component {
  componentDidMount() {
    if (this.props.emailId) {
      this.props.getTagsHandler(this.props.emailId)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.emailId !== prevProps.emailId && this.props.emailId) {
      this.props.getTagsHandler(this.props.emailId)
    }
  }

  changeHandler = (value) => {
    const { editorState, onChange } = this.props
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `[${value}]`,
      editorState.getCurrentInlineStyle(),
    )
    const newEditorState = EditorState.push(editorState, contentState)

    onChange(EditorState.forceSelection(newEditorState, newEditorState.getSelection()))
  }

  render() {
    const { loading, intl, term, locale, tags } = this.props
    const _termPlural = getTerm({
      term,
      formatMessage: intl.formatMessage,
      plural: locale === 'en-US',
    })
    return (
      <div className="rdw-tags-wrapper">
        <Select
          loading={loading}
          value={undefined}
          showSearch
          placeholder={intl.formatMessage(
            { id: 'emails.insert_tagsment_with_title' },
            {
              tag: _termPlural.tag,
            },
          )}
          onChange={this.changeHandler}
          options={Object.keys(tags).map((tag) => ({
            label: tag,
            options: tags[tag].map((tagItem) => ({
              label: tagItem.title,
              value: tagItem.name,
            })),
          }))}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    term: state.translation.term,
    locale: state.settings.locale,
  }
}

export default connect(mapStateToProps)(injectIntl(TagsOption))
