import { InstagramOutlined, TikTokOutlined } from '@ant-design/icons'
import { Button, Space, Typography } from 'antd'
import React, { memo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { livestreamSelector } from 'redux/selectors'
import { compareWithJSON, openNewTab } from 'utils/helper'

const { Text } = Typography

const LivestreamAlert = () => {
  const { formatMessage } = useIntl()

  const livestream = useSelector(livestreamSelector)

  const handleTiktok = useCallback(() => {
    openNewTab(livestream.tiktok_url, false)
  }, [livestream.tiktok_url])

  const handleInstagram = useCallback(() => {
    openNewTab(livestream.instagram_url, false)
  }, [livestream.instagram_url])

  return (
    <div
      style={{
        position: 'relative',
        top: 0,
        width: '100%',
        height: 45,
        zIndex: 50,
        backgroundColor: '#FFBE3D',
        display: 'flex',
        placeItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Space size={30}>
        <Space>
          <span role="img" aria-label="">
            🔴
          </span>
          <Text style={{ color: '#000000' }}>
            {' '}
            {formatMessage({ id: 'direct_consultation_with_kledo_amazing_team' })}
          </Text>
        </Space>
        <Space size={0}>
          <Button
            onClick={handleTiktok}
            icon={<TikTokOutlined style={{ color: '#000000' }} />}
            type="text"
          >
            <Text style={{ textDecoration: 'underline', color: '#000000' }}>Live Tiktok</Text>
          </Button>
          <Button
            onClick={handleInstagram}
            icon={<InstagramOutlined style={{ color: '#000000' }} />}
            type="text"
          >
            <Text style={{ textDecoration: 'underline', color: '#000000' }}>Live Instagram</Text>
          </Button>
        </Space>
      </Space>
    </div>
  )
}

export default memo(LivestreamAlert, compareWithJSON)
