import React, { forwardRef } from 'react'
import { Button } from 'antd'
import { UnorderedListOutlined } from '@ant-design/icons'

function ShowColumn({
  icon = <UnorderedListOutlined />,
  id = 'btn-show-column',
  innerRef,
  ...props
}) {
  return <Button ref={innerRef} icon={icon} id={id} {...props} />
}

export default forwardRef((props, ref) => <ShowColumn {...props} innerRef={ref} />)
