import axios from 'axios'

export const verifyTfa = (data) => {
  return axios.post('/tfa/verify', data)
}

export const enabledTfa = ({ id }) => {
  return axios.post(`/tfa/enabled/${id}`)
}

export const disabledTfa = ({ id, ...payload }) => {
  return axios.post(`/tfa/disabled/${id}`, payload)
}

export const registerTfa = ({ id, ...payload }) => {
  return axios.post(`/tfa/register/${id}`, payload)
}
