import { createSlice } from '@reduxjs/toolkit'
import { updateObject } from './utility'

const initialState = {
  livestream: {
    instagram_url: 'https://www.instagram.com/kledocom',
    is_live: '0',
    thumbnail:
      'https://kledo-live-user.s3.ap-southeast-1.amazonaws.com/livestream/yVjF5XYtddN09OnhlEcLSzQd9hsWrC2zJmXMVNfQ.gif',
    tiktok_url: 'https://www.tiktok.com/@kledocom',
    is_show_thumbnail: true,
  },
  finance_trans_total: {},
  user: {
    id: 0,
    name: '',
    email: '',
    is_active: 0,
    phone_number: '',
    permissions: [],
    loading: false,
    logout: false,
    profile_image: null,
  },
  show_nps: 0,
  show_hr_addon: 0,
  tutorials: {},
  reviews: [],
  message_default: {},
  lang: [],
  reasons: [],
  salutations: [],
  banner: {},
}

const slice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setInits: (state, { payload }) => updateObject(state, payload),
    resetInits: (state, { payload }) =>
      updateObject(state, { ...initialState, user: { ...initialState.user, ...payload } }),
    setUser: (state, { payload }) => updateObject(state, { user: payload }),
    updateUser: (state, { payload }) =>
      updateObject(state, { user: { ...state.user, ...payload } }),
    closeLivestreamThumbnail: (state) =>
      updateObject(state, { livestream: { ...state.livestream, is_show_thumbnail: false } }),
    hideNPS: (state) => updateObject(state, { show_nps: 0 }),
    setReviews: (state, { payload }) => updateObject(state, { reviews: payload }),
    resetUser: (state, { payload }) => updateObject(state, { user: payload }),
    editDefaultMessage: (state, { payload }) =>
      updateObject(state, { message_default: { ...state.message_default, ...payload } }),
  },
})

export const {
  setInits,
  resetInits,
  setUser,
  updateUser,
  closeLivestreamThumbnail,
  hideNPS,
  setReviews,
  resetUser,
  editDefaultMessage,
} = slice.actions

export default slice
