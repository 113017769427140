import React, { useCallback } from 'react'
import { message } from 'antd'
import { useVerifyTfa } from 'utils/queries/tfa'
import { useDispatch } from 'react-redux'
import { afterLoginHandler } from 'redux/user/actions'
import TwoFactorVerificationForm from './TwoFactorVerificationForm'

const TwoFactorVerification = ({ email, onBack, isOnModal = false }) => {
  const dispatch = useDispatch()

  const verifyCodeMutation = useVerifyTfa()

  const handleSubmit = useCallback(
    (values) => {
      verifyCodeMutation.mutate(
        { email, code: values.code },
        {
          onSuccess: (response) => {
            if (response.data.success) {
              dispatch(afterLoginHandler(response, { email }))
              if (isOnModal) {
                window.location.reload(true)
              }
            } else {
              message.error(response.data?.message || 'Failed to verify code')
            }
          },
          onError: (error) => {
            message.error(error?.message || 'Failed to verify code')
          },
        },
      )
    },
    [verifyCodeMutation, email, isOnModal, dispatch],
  )

  return (
    <TwoFactorVerificationForm
      onSubmit={handleSubmit}
      loading={verifyCodeMutation.isLoading}
      onBack={onBack}
    />
  )
}

export default TwoFactorVerification
