import React, { useCallback, useMemo, useState } from 'react'
import { Button, Form, message, Modal } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'

import { openNewTab } from 'utils/helper'
import { Cancel, Send } from 'components/UI'
import HideFeature from 'containers/Billing/HideFeature'
import FormTab from 'components/UI/SendWaModal/FormTab'
import {
  sendFinanceInvoiceWhatsapp,
  sendFinanceOrderWhatsapp,
  sendFinancePurchaseInvoiceWhatsapp,
  sendFinancePurchaseOrderWhatsapp,
  sendFinancePurchaseQuoteWhatsapp,
  sendFinanceQuoteWhatsapp,
} from 'utils/apis'
import { transType } from 'utils/static'
import { billingSelector } from 'redux/selectors'
import ModalWhatsappAddon from 'components/apps/EmailTemplate/ModalWhatsappAddon'

const useSendWhatsappMutation = ({ transTypeId }) => {
  const fetchFunction = useMemo(() => {
    switch (transTypeId) {
      case transType.INVOICE:
        return sendFinanceInvoiceWhatsapp
      case transType.ORDER:
        return sendFinanceOrderWhatsapp
      case transType.QUOTE:
        return sendFinanceQuoteWhatsapp
      case transType.PURCHASE_INVOICE:
        return sendFinancePurchaseInvoiceWhatsapp
      case transType.PURCHASE_ORDER:
        return sendFinancePurchaseOrderWhatsapp
      case transType.PURCHASE_QUOTE:
        return sendFinancePurchaseQuoteWhatsapp
      default:
        return sendFinanceInvoiceWhatsapp
    }
  }, [transTypeId])

  return useMutation((payload) => fetchFunction(payload))
}

const SendWaModal = ({
  sendTitle,
  getWaTemplate,
  show = false,
  onCloseWaModal,
  goToWaTamplate,
  hideOfficial,
  id,
  transTypeId,
}) => {
  const [showBillingWhatsappModal, setShowBillingWhatsappModal] = useState(false)

  const formRef = React.createRef()

  const { formatMessage } = useIntl()

  const billing = useSelector(billingSelector)

  const sendWhatsappMutation = useSendWhatsappMutation({ transTypeId })

  const onSendWa = async () => {
    // Dapatkan form values dengan preserve = true. Field yang tidak di register harus ikut
    const values = formRef.current.getFieldsValue(true)

    if (values.whatsapp_type === '2') {
      if (billing.max_whatsapp === 0) {
        setShowBillingWhatsappModal(true)
        return
      }

      sendWhatsappMutation.mutate(
        { id, payload: values.official_whatsapp_value_replacement },
        {
          onSuccess: (response) => {
            message.success(response.data.message)
            onCloseWaModal()
          },
          onError: (response) => {
            message.error(response.data.message)
          },
        },
      )
      return
    }

    // use replace with a g flag on regex instead replaceAll to prevent a user cannot send whatsapp
    // on a Chrome version under 85
    // source: https://caniuse.com/?search=replaceAll
    const body = values.body
      .replace(/(?:\r\n|\r|\n)/g, '%0a')
      .replace(/#/g, encodeURIComponent('#'))
      .replace(/&/g, encodeURIComponent('&'))

    let phone = values.phone_number
    if (phone.charAt(0) === '+') {
      phone = phone.substring(1)
    }
    if (phone.charAt(0) === '0') {
      phone = phone.substring(1)
      phone = `62${phone}`
    }
    openNewTab(`https://api.whatsapp.com/send?phone=${phone}&text=${body}`, false)
    onCloseWaModal()
  }

  const onCloseBillingWhatsappModal = useCallback(() => setShowBillingWhatsappModal(false), [])

  return (
    <>
      <Modal
        title={
          sendTitle === 'button.send_wa' || !sendTitle
            ? formatMessage({ id: 'button.send_wa' })
            : sendTitle
        }
        open={show}
        onCancel={onCloseWaModal}
        destroyOnClose
        footer={[
          <React.Fragment key="template">
            {goToWaTamplate && (
              <Button style={{ float: 'left' }} icon={<EditOutlined />} onClick={goToWaTamplate}>
                {formatMessage({ id: 'wa.change_template' })}
              </Button>
            )}
          </React.Fragment>,
          <Cancel key={`_${0}`} onClick={onCloseWaModal} />,
          <Send
            form="sendWaForm"
            key="submit"
            htmlType="submit"
            loading={sendWhatsappMutation.isLoading}
          />,
        ]}
      >
        <HideFeature
          code="whatsapp"
          feature={
            sendTitle === 'button.send_wa' || !sendTitle
              ? formatMessage({ id: 'button.send_wa' })
              : sendTitle
          }
        >
          <Form
            ref={formRef}
            onFinish={onSendWa}
            layout="vertical"
            id="sendWaForm"
            initialValues={{
              phone_number: '',
              body: '',
              whatsapp_type: '1',
              official_whatsapp_value_replacement: [],
            }}
          >
            <FormTab
              getWaTemplate={getWaTemplate}
              hideOfficial={hideOfficial}
              id={id}
              show={show}
              transTypeId={transTypeId}
            />
          </Form>
        </HideFeature>
      </Modal>
      <ModalWhatsappAddon
        description={formatMessage({ id: 'whatsappModal.out_of_whatsapp_balance_desc' })}
        open={showBillingWhatsappModal}
        onClose={onCloseBillingWhatsappModal}
      />
    </>
  )
}

export default SendWaModal
