import React, { memo, useCallback, useState } from 'react'
import { Avatar, Badge, Dropdown, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import News from 'containers/Notification/News'
import { useResponsive } from 'utils/hooks'
import { useUpdateReadNews } from 'utils/queries'
import { useSelector } from 'react-redux'
import { darkModeSelector, userSelector } from 'redux/selectors'
import { compareWithJSON } from 'utils/helper'

import styles from './style.module.scss'

const Actions = () => {
  const { formatMessage } = useIntl()

  const [visible, setVisible] = useState(false)

  const darkMode = useSelector(darkModeSelector)
  const { is_show_news: isShowNews, id: userId } = useSelector(userSelector)
  const { mutate: updateReadNews } = useUpdateReadNews()

  const { isMobileView } = useResponsive()

  const visibleChangeHandler = useCallback(
    (flag) => {
      setVisible(flag)
      if (isShowNews === 1) {
        updateReadNews(userId)
      }
    },
    [updateReadNews, userId, isShowNews],
  )

  const stylePane = { height: 400, overflow: 'auto' }
  if (!isMobileView) {
    stylePane.width = 400
  }

  const dropdownRender = useCallback(() => {
    return (
      <div
        className={`card cui__utils__shadow width-350 overflow-hidden border-0 ${
          darkMode ? 'bg-dark-3 text-light' : 'bg-light'
        }`}
      >
        <div className="card-body" style={stylePane}>
          <News />
        </div>
      </div>
    )
  }, [darkMode, stylePane])

  return (
    <Tooltip
      open={visible ? false : undefined}
      title={formatMessage({ id: 'topBar.latestKledoUpdate' })}
    >
      <div>
        <Dropdown
          dropdownRender={dropdownRender}
          trigger={['click']}
          placement="bottomRight"
          open={visible}
          arrow
          onOpenChange={visibleChangeHandler}
        >
          <div className={`${styles.dropdown} ${visible ? styles.open : ''}`}>
            <Badge dot={isShowNews === 1} size="small" className={styles.notificationBadge}>
              <Avatar shape="square" size="small" icon={<FontAwesomeIcon icon="bell" />} />
            </Badge>
          </div>
        </Dropdown>
      </div>
    </Tooltip>
  )
}

export default memo(Actions, compareWithJSON)
