import React from 'react'
import localStore from 'store'
import axios from 'axios'
import dayjs from 'dayjs'
import _ from 'lodash'
import { store as reduxStore } from 'index'
import { parse } from 'query-string'

export const nth = (n) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100

  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const arraymove = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const priceValidator = (rule, value) => {
  if (value <= 0) {
    return Promise.reject(rule.message)
  }
  return Promise.resolve()
}

export const disabledTransDate = (current) => {
  const { options } = reduxStore.getState().options

  if (!options.lock_date) {
    return false
  }

  return current && current.isSameOrBefore(dayjs(options.lock_date), 'day')
}

export const transDateValidator = (rule, value) => {
  const { options } = reduxStore.getState().options
  // if (!options.lock_date) {
  //   callback();
  // }

  if (value && options.lock_date && value.isSameOrBefore(dayjs(options.lock_date), 'day')) {
    return Promise.reject(rule.message)
  }
  return Promise.resolve()
}

// Get import template link
export const getTemplateUrl = (fileName = '') => {
  return `${generateBaseUri()}/download/import/${fileName}`
}

export const generateBaseUri = () => {
  // Jika env base url di bypass

  const byPassEndpoint = localStore.get('app.bypassEndpoint')
  if (localStore.get('app.isOverwriteBaseUrl') && !!byPassEndpoint) {
    return byPassEndpoint
  }

  let apiUrl = byPassEndpoint || process.env.REACT_APP_API_URL
  let baseUrl = apiUrl ? apiUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '') : ''
  const { hostname } = window.location
  if (process.env.NODE_ENV === 'production') {
    const splitHostname = hostname.split('.')
    if (splitHostname[0] && localStore.get('app.endpoint')) {
      baseUrl = `${splitHostname[0]}.${baseUrl}`
    }
  }

  let protocol = _.includes(apiUrl, 'https://') ? 'https://' : 'http://'
  if (_.includes('local.jokolodang.com', hostname)) {
    protocol = 'http://'
  }
  if (localStore.get('app.endpoint')) {
    apiUrl = `${protocol}${localStore.get('app.endpoint')}/api/v1`
  } else {
    apiUrl = `${protocol}${baseUrl}`
    if (_.includes('local.jokolodang.com', hostname)) {
      apiUrl = `http://app.kledo.test/api/v1`
    } else if (_.includes('localssl.jokolodang.com', hostname)) {
      apiUrl = `https://app.kledo.test/api/v1`
    }
  }
  return apiUrl
}

export const getMax = (arr = []) => {
  let max
  arr.forEach((e, i) => {
    if (i === 0) max = e
    if (max < e) max = e
  })
  return max
}

export const cleanBlankValue = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key]
    }
  })

  return obj
}

// Create custom axios GET with cancellation
export const makeGetRequestCreator = () => {
  let call
  let _url
  return (url, { forceCancellation, forceCancellationWithURL, ...options } = {}) => {
    if (call) {
      if (
        (!forceCancellation && !forceCancellationWithURL) ||
        (forceCancellationWithURL && url === _url)
      ) {
        call.cancel('Only one request allowed at a time.')
      }
    }
    call = axios.CancelToken.source()
    _url = url
    return axios.get(url, {
      cancelToken: call.token,
      ...options,
    })
  }
}

export const getOnce = makeGetRequestCreator()

// To check layout is desktop
export const isDesktopLayout = () => {
  const { isMobileView, isTabView } = reduxStore.getState().settings
  // const isMobileView = localStore.get('app.settings.isMobileView');
  // const isTabView = localStore.get('app.settings.isTabView');

  return !isMobileView && !isTabView
}

export const discountType = (type) => {
  const { options } = reduxStore.getState().options
  let isDiscountNumeric
  if (type === 'purchase') {
    isDiscountNumeric = options.purchase_discount_numeric === 1
  } else {
    isDiscountNumeric = options.sale_discount_numeric === 1
  }

  return isDiscountNumeric ? 'discount_amount' : 'discount_percent'
}

export const openNewTabBlobFile = (data) => {
  const file = new Blob([data], { type: 'application/pdf' })
  // Build a URL from the file
  const fileURL = URL.createObjectURL(file)
  const tempLink = document.createElement('a')
  tempLink.href = fileURL
  tempLink.setAttribute('target', '_blank')
  tempLink.click()
}

export const openNewTab = (moduleUrl = '', withBaseUri = true) => {
  const tempLink = document.createElement('a')
  if (withBaseUri) tempLink.href = `${generateBaseUri()}${moduleUrl}`
  else tempLink.href = moduleUrl
  tempLink.setAttribute('target', '_blank')
  tempLink.click()
}

export const filterOption = (input, option) => {
  if (option.children) {
    return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
  if (option?.name) {
    return option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
  if (option?.label) {
    return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
  return true
}

export const copyclip = (text) => {
  const textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}

export const clearCache = (reload = false) => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      const delAll = new Promise((resolve) => {
        if (names.length === 0) resolve()
        names.forEach(async (name, i) => {
          await caches.delete(name)
          if (i === names.length - 1) resolve()
        })
      })

      delAll.then(() => {
        if (reload) {
          window.location.reload(true)
        }
      })
    })
  }
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'ios', 'android', 'windows_phone', or 'other'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows_phone'
  }

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return 'other'
}

export const useDebounce = (callback, delay) => {
  return React.useCallback(
    _.debounce((...args) => callback(...args), delay),
    [delay], // will recreate if delay changes
  )
}

export const parseUrlToFileName = (url) => {
  let name = ''
  if (url) {
    try {
      const { pathname } = new URL(url)
      if (pathname) {
        const splitPath = pathname.split('/')
        name = splitPath ? decodeURI(_.last(splitPath)) : ''
      }
    } catch (error) {
      // Jika URL tidak valid, coba ekstrak nama file dari string url langsung
      const splitPath = url.split('/')
      name = splitPath ? decodeURI(_.last(splitPath)) : ''
    }
  }
  return name
}

// Replace slash for uri
export const replaceSlash = (text = '', replaceable = ':slash:') => text.replace(/\//g, replaceable)

export const isPaidFeature = ({ billing, code }) => {
  const hiddenFeatures = [
    ...(billing.hidden_feature ?? []),
    ...(billing.hidden_feature_elite ?? []),
    ...(billing.hidden_feature_champion ?? []),
  ]

  return hiddenFeatures.filter((feature) => feature === code).length > 0
}
export const isFeaturePro = ({ billing, code }) =>
  billing?.hidden_feature && billing.hidden_feature.filter((e) => e === code).length > 0

export const isFeatureElite = ({ billing, code }) =>
  billing?.hidden_feature_elite && billing.hidden_feature_elite.filter((e) => e === code).length > 0

export const isFeatureChampion = ({ billing, code }) =>
  billing?.hidden_feature_champion &&
  billing.hidden_feature_champion.filter((e) => e === code).length > 0

export const makeYears = (min = 2000) => {
  const years = []
  for (let i = dayjs().year(); i >= min; i -= 1) {
    years.push(i.toString())
  }

  return years
}

export function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

// export const getBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })

export const isValidJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function compare(prevProps, nextProps) {
  return stringify(prevProps) === stringify(nextProps)
}

export const compareWithJSON = (prevProps, nextProps) => {
  try {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  } catch {
    return true
  }
}

export function compareWithIsEqual(prevProps, nextProps) {
  return _.isEqual(prevProps, nextProps)
}

export function stringify(obj) {
  let cache = []
  const str = JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return
      }
      // Store value in our collection
      cache.push(value)
    }
  })
  cache = null // reset the cache
  return str
}

export const stripTagsRDW = (blocks) => {
  const newBlocks = []

  if (_.isArray(blocks)) {
    blocks.forEach((block) => {
      newBlocks.push({
        ...block,
        // prevent link like below to be undefined and still link (can be click like link)
        // <a data-atyp="prec" jsaction="trigger.VM8bg" data-url="intent://www.google.com/maps/place//data=!4m2!3m1!1s0x2e69f700ca0f71ed:0xec2353a4f12e3357!11m1!4b1?entry=s&amp;sa=X&amp;ved=2ahUKEwjpmt3Vt6qCAxXCTGwGHSJOASkQ4kB6BAgBEAA#Intent;scheme=http;package=com.google.android.apps.maps;S.browser_fallback_url=https://www.google.com/maps/place//data%3D!4m2!3m1!1s0x2e69f700ca0f71ed:0xec2353a4f12e3357!11m1!4b1%3Fentry%3Ds&amp;sa%3DX&amp;ved%3D2ahUKEwjpmt3Vt6qCAxXCTGwGHSJOASkQ4kB6BAgBEAA;end" role="link" tabindex="0" data-ved="2ahUKEwjpmt3Vt6qCAxXCTGwGHSJOASkQ4kB6BAgWEAI" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0.1); background-color: rgb(255, 251, 255); max-height: 999999px; color: rgb(21, 88, 214); font-family: Roboto, &quot;Helvetica Neue&quot;, Arial, sans-serif; font-size: 14px; cursor: pointer;">
        entityRanges: [],
        text: block?.text?.replace(/(<([^>]+)>)/gi, '')?.replace('&nbsp;', ' ') ?? '',
      })
    })
  }

  return newBlocks
}

/**
 *
 * @param data {string[]}
 * @returns {string[]}
 */
export const sortDate = (data) => {
  return data.sort(
    (prevDate, nextDate) => new Date(prevDate).getTime() - new Date(nextDate).getTime(),
  )
}

// Reference https://css-tricks.com/converting-color-spaces-in-javascript/#aa-hex-to-rgb
/**
 *
 * @param h {string}
 * @returns {string}
 */
export const hexToRGB = (h) => {
  let r = 0
  let g = 0
  let b = 0

  // 3 digits
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`
    g = `0x${h[2]}${h[2]}`
    b = `0x${h[3]}${h[3]}`

    // 6 digits
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`
    g = `0x${h[3]}${h[4]}`
    b = `0x${h[5]}${h[6]}`
  }

  return `rgb(${+r},${+g},${+b})`
}

// Check contrast color for tag with background color
// Reference: https://stackoverflow.com/a/9733420

const RED = 0.2126
const GREEN = 0.7152
const BLUE = 0.0722

const GAMMA = 2.4

const luminance = (r, g, b) => {
  const a = [r, g, b].map((v) => {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** GAMMA
  })
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE
}

/**
 *
 * @param stringRgb {string}
 * @returns {number[]}
 */
const extractRgb = (stringRgb) => {
  return stringRgb
    .split(/[()]/i)[1]
    .split(',')
    .map((num) => Number(num))
}

export const checkContrast = (rgb1, rgb2) => {
  const lum1 = luminance(...extractRgb(rgb1))
  const lum2 = luminance(...extractRgb(rgb2))
  const brightest = Math.max(lum1, lum2)
  const darkest = Math.min(lum1, lum2)
  return (brightest + 0.05) / (darkest + 0.05)
}

// Untuk mengganti bahasa dayjs dan calendar
export const changeLocale = (locale) => {
  const localeShort = locale === 'id-ID' ? 'id' : 'en'
  dayjs.locale(localeShort)
}

// Konversi hari termin ke due date
export const transformDueDate = (termId, transDate, terms) => {
  let dueDate = null
  if (!terms) {
    return dueDate
  }
  const cloneTransDate = transDate ? _.clone(dayjs(transDate.format('YYYY-MM-DD'))) : dayjs()
  const findTerm = terms.find((row) => row.id === termId)
  if (findTerm) {
    dueDate = cloneTransDate.add(findTerm.days, 'days')
  } else {
    dueDate = cloneTransDate.add(30, 'days')
  }

  return dueDate
}

export const decodeUriWithAtob = (search) => {
  if (!_.isEmpty(search)) {
    const searches = parse(search)
    if (!_.isEmpty(searches?.filters)) {
      try {
        const decodedString = atob(searches.filters || '')
        return JSON.parse(decodeURIComponent(decodedString))
      } catch {
        return {}
      }
    }
  }
  return {}
}

export const encodeUriWithBtoa = (params) => {
  if (!_.isEmpty(params)) {
    const queryString = JSON.stringify(params)
    const encodedString = encodeURIComponent(queryString)
    return btoa(encodedString)
  }
  return ''
}

export const getFileNameFromHeaders = (headers) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(headers['content-disposition'])
  if (matches?.[1]) {
    return matches[1].replace(/['"]/g, '')
  }

  return null
}

export const createDownloadLink = ({ data, headers }) => {
  const downloadLink = document.createElement('a')
  const blob = new Blob([data], { type: headers['content-type'] })
  downloadLink.href = URL.createObjectURL(blob)

  const fileName = getFileNameFromHeaders(headers)
  if (!_.isEmpty(fileName)) {
    downloadLink.download = fileName
  }
  downloadLink.click()
}
