import { CloseOutlined, WechatOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import React, { memo, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { closeLivestreamThumbnail } from 'redux/initSlice'
import { livestreamSelector } from 'redux/selectors'
import { compareWithJSON, openNewTab } from 'utils/helper'

const LivestreamThumbnail = ({ isChatSupportShowed }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const [isHovered, setIsHovered] = useState(false)
  const livestream = useSelector(livestreamSelector)

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  const handleTiktok = useCallback(() => {
    openNewTab(livestream.tiktok_url, false)
  }, [livestream.tiktok_url])

  const handleInstagram = useCallback(() => {
    openNewTab(livestream.instagram_url, false)
  }, [livestream.instagram_url])

  const handleCloseThumbnail = useCallback(() => {
    dispatch(closeLivestreamThumbnail())
  }, [dispatch])

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'fixed',
        right: '2.5rem',
        bottom: isChatSupportShowed ? '4rem' : '1rem',
        width: 192,
        height: 336,
        borderRadius: 6,
        zIndex: 50,
        backgroundImage: `url('${livestream.thumbnail}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          opacity: isHovered ? 1 : 0, // Tampilkan overlay saat hover
          transition: 'opacity 0.3s ease',
        }}
      />
      {isHovered && (
        <>
          <CloseOutlined
            size={24}
            style={{
              right: '0.5rem',
              top: '0.5rem',
              position: 'absolute',
              color: '#FFFFFF',
              cursor: 'pointer',
            }}
            onClick={handleCloseThumbnail}
          />
          <Space style={{ position: 'absolute', bottom: 40, left: 8 }} direction="vertical">
            <Button
              icon={
                <img alt="Tiktok" src="resources/images/icons/tiktok.svg" width={16} height={16} />
              }
              onClick={handleTiktok}
            >
              Live Tiktok
            </Button>
            <Button
              icon={
                <img
                  alt="Instagram"
                  src="resources/images/icons/instagram.svg"
                  width={16}
                  height={16}
                />
              }
              onClick={handleInstagram}
            >
              Live Instagram
            </Button>
          </Space>
        </>
      )}

      <Button
        block
        icon={<WechatOutlined />}
        style={{
          background: '#FFBE3D',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          border: 'none',
          borderRadius: 0,
          cursor: 'default',
          color: "#000000"
        }}
        type="text"
      >
        {formatMessage({ id: 'consult_now' })}
      </Button>
    </div>
  )
}

export default memo(LivestreamThumbnail, compareWithJSON)
