/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Icon from '@ant-design/icons'
import { Button, DatePicker, Menu, Popover, Select, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'

import { formatDateText } from 'utils/formatting'
import styles from 'containers/Reports/Export/styles.module.scss'
import { getDefaultCustomDate, onNextDateChange, onPrevDateChange } from './Dropdown.service'

// const MONTHLY = ['monthly', 'this_month', 'last_month']
// const YEARLY = ['yearly', 'this_year', 'last_year']
const MONTHLY = ['monthly']
const YEARLY = ['yearly']
// const DAILY = ['today', 'yesterday']

const ThreeDotsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
    />
  </svg>
)

export const DropdownDate = ({
  intl,
  id,
  setDateRange,
  selectedKeys,
  data,
  dateType,
  // hideDateType,
  params,
  withCustomDaterange,
  withCustomDateType = true,
  legacy = true,
  forceCustomDaterange = false,
  placeholderDateFrom,
  formatPickerDateFrom,
  pickerTypeDateFrom,
  placeholderDateTo,
  formatPickerDateTo,
  pickerTypeDateTo,
  daterangeOnly,
  dateOnly,
  customDateTypeKey,
  customDateType,
  keepCustomDateType,
}) => {
  // const [customDateType, setCustomDateType] = useState('daily')
  const [popoverVisible, setPopoverVisible] = useState(false)
  // const [prevDate, setPrevDate] = useState(null)
  // const [toDate, setToDate] = useState(null)
  const dateTypeKey = legacy ? 'daterange' : 'movement_compare'
  const initialParams = useMemo(() => {
    const currentInitialParams = {
      [dateTypeKey]: dateType,
      date_from: params?.date_from || null,
      date_to: params?.date_to || null,
    }

    if (customDateTypeKey) {
      currentInitialParams[customDateTypeKey] = customDateType
    }

    return currentInitialParams
  }, [customDateType, customDateTypeKey, dateType, dateTypeKey, params])

  const [innerParams, setInnerParams] = useState(initialParams)

  // useEffect(() => {
  //   setCustomDateType(getCustomDateTypeFromProps({ dateType: dateType || 'daily' }))
  // }, [dateType])

  // useEffect(() => {
  //   if (hideDateType) {
  //     setPrevDate(
  //       getPrevDate({
  //         customDateType,
  //         dateType,
  //         hideDateType,
  //       }),
  //     )
  //     setToDate(
  //       getNextDate({
  //         customDateType,
  //         hideDateType,
  //       }),
  //     )
  //   }
  // }, [customDateType, dateType, hideDateType])

  const customDateranges = useMemo(() => {
    if (withCustomDaterange) {
      return [
        {
          title: intl.formatMessage({ id: 'date.day' }),
          name: 'daily',
        },
        {
          title: intl.formatMessage({ id: 'date.month' }),
          name: 'monthly',
        },
        {
          title: intl.formatMessage({ id: 'date.year' }),
          name: 'yearly',
        },
      ]
    }
    return data
  }, [data, intl, withCustomDaterange])

  const onSetDateRange = useCallback(
    (payload) => {
      return () => {
        const newPayload = {
          id,
          [dateTypeKey]: payload[dateTypeKey],
          custom_daterange: payload.custom_daterange ? 1 : 0,
        }
        if (legacy) {
          newPayload.date_from = payload.custom_daterange ? payload.date_from : null
          newPayload.date_to = payload.custom_daterange ? payload.date_to : null
        } else if (!payload.custom_daterange) {
          const newParams = getDefaultCustomDate({
            daterange: payload[dateTypeKey],
            monthly: MONTHLY,
            yearly: YEARLY,
            dateTypeKey,
          })
          newPayload.date_from = newParams.date_from
          newPayload.date_to = newParams.date_to
        } else {
          if (!dateOnly) newPayload.date_from = payload.date_from
          newPayload.date_to = payload.date_to
        }
        if (legacy && (payload.selected_daterange || keepCustomDateType)) {
          newPayload.selected_daterange =
            payload.selected_daterange ?? (keepCustomDateType ? customDateType : null)
        }
        setDateRange(newPayload)
        if (!forceCustomDaterange && newPayload.custom_daterange !== 1 && innerParams.date_from) {
          setInnerParams((prevState) => ({
            ...prevState,
            custom_daterange: 0,
            date_from: null,
            date_to: null,
          }))
        } else {
          setInnerParams({
            ...newPayload,
            date_from:
              !dateOnly && dayjs(newPayload.date_from).isValid()
                ? dayjs(newPayload.date_from)
                : null,
            date_to: dayjs(newPayload.date_to).isValid() ? dayjs(newPayload.date_to) : null,
          })
        }
        setPopoverVisible(false)
      }
    },
    [
      dateOnly,
      keepCustomDateType,
      dateTypeKey,
      forceCustomDaterange,
      id,
      innerParams.date_from,
      legacy,
      setDateRange,
      customDateType,
    ],
  )

  const stopPropagation = (e) => e.stopPropagation()

  // const checkPrevDateDaily = useCallback(
  //   (date = prevDate) => {
  //     return (
  //       Number(
  //         dayjs
  //           .duration(dayjs(toDate).diff(date.startOf('day')))
  //           .asDays()
  //           .toFixed(0),
  //       ) > 31
  //     )
  //   },
  //   [prevDate, toDate],
  // )

  // const checkPrevDateMonthly = useCallback(
  //   (date = prevDate) => {
  //     return (
  //       Number(
  //         dayjs
  //           .duration(dayjs(toDate).diff(date.startOf('day')))
  //           .asMonths()
  //           .toFixed(0),
  //       ) > 12
  //     )
  //   },
  //   [prevDate, toDate],
  // )

  // const checkPrevDateYearly = useCallback(
  //   (date = prevDate) => {
  //     return (
  //       Number(
  //         dayjs
  //           .duration(dayjs(toDate).diff(date.startOf('day')))
  //           .asYears()
  //           .toFixed(0),
  //       ) > 6
  //     )
  //   },
  //   [prevDate, toDate],
  // )

  const onChangeDate = useCallback(
    (value) => {
      setInnerParams((prevState) => ({
        ...prevState,
        // daterange: value,
        ...getDefaultCustomDate({
          daterange: value,
          monthly: MONTHLY,
          yearly: YEARLY,
          dateTypeKey: customDateTypeKey ?? dateTypeKey,
        }),
      }))
    },
    [customDateTypeKey, dateTypeKey],
  )

  const onDateFromDateChange = useCallback(
    (dateMoment) => {
      onPrevDateChange({
        date: dateMoment,
        monthly: MONTHLY,
        setParams: setInnerParams,
        params: innerParams,
        withCustomDaterange,
        yearly: YEARLY,
        dateTypeKey,
      })
    },
    [dateTypeKey, innerParams, withCustomDaterange],
  )

  const onDateToChange = useCallback(
    (dateMoment) => {
      onNextDateChange({
        date: dateMoment,
        monthly: MONTHLY,
        setParams: setInnerParams,
        params: innerParams,
        withCustomDaterange,
        yearly: YEARLY,
        dateTypeKey,
      })
    },
    [dateTypeKey, innerParams, withCustomDaterange],
  )

  const content = useMemo(() => {
    const getPlaceholder = () => {
      let placeholder = intl.formatMessage({ id: 'picker.select_date' })

      if (_.includes(MONTHLY, innerParams[dateTypeKey])) {
        placeholder = intl.formatMessage({ id: 'picker.select_month' })
      } else if (_.includes(YEARLY, innerParams[dateTypeKey])) {
        placeholder = intl.formatMessage({ id: 'picker.select_year' })
      }

      return placeholder
    }

    const placeholder = getPlaceholder()

    let currentFormatDatePickerFrom = formatDateText()
    let currentFormatDatePickerTo = formatDateText()
    let currentPickerTypeDateFrom
    let currentPickerTypeDateTo

    const customDateTypeValue = customDateTypeKey
      ? innerParams[customDateTypeKey]
      : innerParams[dateTypeKey]

    if (formatPickerDateFrom) currentFormatDatePickerFrom = formatPickerDateFrom
    if (formatPickerDateTo) currentFormatDatePickerTo = formatPickerDateTo
    if (pickerTypeDateFrom) currentPickerTypeDateFrom = pickerTypeDateFrom
    if (pickerTypeDateTo) currentPickerTypeDateTo = pickerTypeDateTo
    if (_.includes(MONTHLY, customDateTypeValue)) {
      currentFormatDatePickerFrom = 'MMM YYYY'
      currentFormatDatePickerTo = 'MMM YYYY'
      currentPickerTypeDateFrom = 'month'
      currentPickerTypeDateTo = 'month'
    }
    if (_.includes(YEARLY, customDateTypeValue)) {
      currentFormatDatePickerFrom = 'YYYY'
      currentFormatDatePickerTo = 'YYYY'
      currentPickerTypeDateFrom = 'year'
      currentPickerTypeDateTo = 'year'
    }

    if (dateOnly) {
      return (
        <Space>
          {withCustomDateType ? (
            <Select
              value={customDateTypeValue}
              onClick={stopPropagation}
              onChange={onChangeDate}
              options={customDateranges.map((item) => ({
                label: item.title,
                value: item.name,
              }))}
            />
          ) : null}
          <DatePicker
            format={currentFormatDatePickerTo}
            value={innerParams.date_to}
            onClick={stopPropagation}
            picker={currentPickerTypeDateTo}
            onChange={onDateToChange}
            disabledDate={(current) => {
              if (dayjs(innerParams.date_from).isValid() && current) {
                if (current.isSameOrBefore(dayjs(innerParams.date_from))) {
                  return true
                }
              }
              return current?.isAfter(dayjs())
            }}
            placeholder={placeholderDateTo ?? placeholder}
          />
        </Space>
      )
    }

    return (
      <Space>
        {withCustomDateType ? (
          <>
            <Select
              value={customDateTypeValue}
              onClick={stopPropagation}
              onChange={onChangeDate}
              options={customDateranges.map((item) => ({
                label: item.title,
                value: item.name,
              }))}
            />
            {intl.formatMessage({ id: 'report.from' }).toLowerCase()}
          </>
        ) : null}
        <DatePicker
          format={currentFormatDatePickerFrom}
          value={innerParams.date_from}
          onClick={stopPropagation}
          picker={currentPickerTypeDateFrom}
          onChange={onDateFromDateChange}
          disabledDate={(current) => current?.isAfter(dayjs())}
          placeholder={placeholderDateFrom ?? placeholder}
        />
        {intl.formatMessage({ id: 'report.until' })}
        <DatePicker
          format={currentFormatDatePickerTo}
          value={innerParams.date_to}
          onClick={stopPropagation}
          picker={currentPickerTypeDateTo}
          onChange={onDateToChange}
          disabledDate={(current) => {
            if (dayjs(innerParams.date_from).isValid() && current) {
              if (current.isSameOrBefore(dayjs(innerParams.date_from))) {
                return true
              }
            }
            return current?.isAfter(dayjs())
          }}
          placeholder={placeholderDateTo ?? placeholder}
        />
      </Space>
    )
  }, [
    customDateTypeKey,
    formatPickerDateFrom,
    formatPickerDateTo,
    pickerTypeDateFrom,
    pickerTypeDateTo,
    innerParams,
    dateTypeKey,
    dateOnly,
    withCustomDateType,
    onChangeDate,
    customDateranges,
    intl,
    onDateFromDateChange,
    placeholderDateFrom,
    onDateToChange,
    placeholderDateTo,
  ])

  const items = useMemo(() => {
    const newItems = data.map((item, idx) => {
      return {
        key: item.name || `${idx + 1}`,
        onClick: onSetDateRange({
          [dateTypeKey]: item.name,
        }),
        label: item.title,
      }
    })

    newItems.push({
      key: 'filter',
      label: (
        <Popover
          trigger="click"
          content={content}
          placement="left"
          onOpenChange={(flag) => {
            if (!flag && innerParams.date_from && innerParams.date_to) {
              const currentParams = {
                id,
                [dateTypeKey]: withCustomDaterange && legacy ? null : innerParams[dateTypeKey],
                custom_daterange: 1,
                date_from: dayjs(innerParams.date_from).format('YYYY-MM-DD'),
                date_to: dayjs(innerParams.date_to).format('YYYY-MM-DD'),
              }
              if (legacy) {
                currentParams.selected_daterange = innerParams[customDateTypeKey ?? dateTypeKey]
              }

              onSetDateRange(currentParams)()
            }
            if (flag) {
              // const daterange = getCustomDateTypeFromProps({ dateType: dateType || 'daily' })
              let newInnerParams = {
                // daterange,
              }
              if (!innerParams.date_from && !innerParams.date_to) {
                newInnerParams = {
                  // ...newInnerParams,
                  ...getDefaultCustomDate({
                    [dateTypeKey]: dateType,
                    monthly: MONTHLY,
                    yearly: YEARLY,
                  }),
                }
              }

              setInnerParams((prevState) => ({
                ...prevState,
                ...newInnerParams,
              }))
            }
          }}
        >
          {/* For make a text stay like before position absolute */}
          <span
            style={{
              visibility: 'hidden',
            }}
          >
            Custom
          </span>
          <span
            style={{
              position: 'absolute',
              top: 0,
              right: 16,
              left: 16,
              bottom: 0,
            }}
          >
            Custom
          </span>
        </Popover>
      ),
      style: {
        position: 'relative',
      },
    })

    return newItems
  }, [
    customDateTypeKey,
    content,
    data,
    dateType,
    dateTypeKey,
    id,
    innerParams,
    legacy,
    onSetDateRange,
    withCustomDaterange,
  ])

  const parseSelectedKeys = params?.custom_daterange === 1 ? ['filter'] : selectedKeys

  const visibleChangeHandler = useCallback(
    (visible) => {
      setPopoverVisible(visible)

      if (
        !visible &&
        ((daterangeOnly && innerParams.date_from && innerParams.date_to) || dateOnly)
      ) {
        const currentParams = {
          id,
          [dateTypeKey]: withCustomDaterange && legacy ? null : innerParams[dateTypeKey],
          custom_daterange: 1,
          date_from: innerParams.date_from
            ? dayjs(innerParams.date_from).format('YYYY-MM-DD')
            : null,
          date_to: innerParams.date_to ? dayjs(innerParams.date_to).format('YYYY-MM-DD') : null,
        }
        if (legacy) currentParams.selected_daterange = innerParams[dateTypeKey]

        onSetDateRange(currentParams)()
      }
    },
    [
      dateOnly,
      dateTypeKey,
      daterangeOnly,
      id,
      innerParams,
      legacy,
      onSetDateRange,
      withCustomDaterange,
    ],
  )

  const popoverContent = useMemo(() => {
    if (daterangeOnly) return content
    if (dateOnly) return content

    return <Menu selectedKeys={parseSelectedKeys} style={{ borderRight: 'none' }} items={items} />
  }, [content, dateOnly, daterangeOnly, items, parseSelectedKeys])

  return (
    <>
      <Popover
        trigger="click"
        open={popoverVisible}
        onOpenChange={visibleChangeHandler}
        content={popoverContent}
      >
        <Button
          type="text"
          className={styles.buttonTransparent}
          style={{
            padding: 0,
            width: 'fit-content',
            height: 'fit-content',
            border: 'none',
          }}
        >
          <Icon component={ThreeDotsIcon} size={16} />
        </Button>
      </Popover>
    </>
  )
}
