import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { Layout } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import NProgress from 'nprogress'
import dayjs from 'dayjs'

import { inits } from 'redux/actions'
import { loadedInitSelector, loadingInitSelector, userSelector } from 'redux/selectors'
import styles from '../Login/style.module.scss'

const InfoLayout = ({ children }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector(loadingInitSelector)
  const loadedInit = useSelector(loadedInitSelector)
  const { id } = useSelector(userSelector)

  // // Loader init
  useEffect(() => {
    if (loading) {
      NProgress.start()
    } else {
      NProgress.done()
    }
  }, [loading])

  useEffect(() => {
    // Jika init belum di load, maka di ambil dari api
    const loadInits = async () => {
      const onInits = () => dispatch(inits())
      onInits()
    }

    if (!id && !loadedInit) {
      loadInits()
    }
  }, [id, dispatch, loadedInit])

  return (
    <Layout>
      <Layout.Content>
        <div className={styles.layout}>
          <div className={styles.content}>{children}</div>
          <div className={`${styles.footer} text-center`}>
            <div>
              Powered by
              <div className={styles.logoKledoFooter}>
                <a target="_blank" rel="noopener noreferrer" href="https://kledo.com">
                  <img src="resources/images/logo-color-kledo-front.png" alt="Kledo" />
                </a>
              </div>
            </div>

            <p>&copy;{` ${dayjs().format('YYYY')} Kledo. All rights reserved.`}</p>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default injectIntl(InfoLayout)
