import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { notification } from 'antd'

import { checkPermission, getModulePermissions } from 'redux/user/selectors'
import { useLoadedInit } from 'utils/hooks/index'
import { useIntl } from 'react-intl'

/**
 *
 * @param name {string}
 * @param parentPermission {string}
 * @returns {{isHasAccess: boolean, loadedInit: boolean}}
 */
const usePermission = ({ name, parentPermission }) => {
  const { formatMessage } = useIntl()
  const loadedInit = useLoadedInit()
  const isHasAccess = useSelector((state) =>
    parentPermission
      ? getModulePermissions(state, parentPermission, true)[name]
      : checkPermission(state, name),
  )

  useEffect(() => {
    if (loadedInit && name && !isHasAccess) {
      notification.warning({
        message: formatMessage({ id: 'report.access_denied' }),
      })
    }
  }, [formatMessage, isHasAccess, loadedInit, name])

  return {
    isHasAccess: loadedInit && !!isHasAccess,
    loadedInit,
  }
}

export default usePermission
