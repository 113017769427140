import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, message, Select, Skeleton } from 'antd'

const PersonalWhatsapp = ({ getWaTemplate, show }) => {
  const [loadingWaTemplate, setLoadingWaTemplate] = useState(false)
  const [phonesNumber, setPhonesNumber] = useState([])

  const { formatMessage } = useIntl()
  const form = Form.useFormInstance()

  useEffect(() => {
    const getTemplate = async () => {
      try {
        setLoadingWaTemplate(true)
        const response = await getWaTemplate()
        setLoadingWaTemplate(false)
        const currentData = response.data.data

        form.setFieldValue('body', currentData.body)

        if (currentData.phones) {
          setPhonesNumber(currentData.phones.filter(Boolean))
          form.setFieldValue('phone_number', currentData.phones[0])
        } else {
          form.setFieldValue('phone_number', currentData.phone)
        }
      } catch (e) {
        console.log(e)
        message.error(formatMessage({ id: 'sms.cannot_send_wa' }))
      }
    }

    if (show) {
      getTemplate()
    }
  }, [show, form, formatMessage, getWaTemplate])

  return (
    <Skeleton loading={loadingWaTemplate} active>
      <Form.Item
        label={formatMessage({ id: 'sms.destination_number' })}
        name="phone_number"
        rules={[
          { required: true, message: formatMessage({ id: 'sms.please_input_phone' }) },
          {
            min: 6,
            message: formatMessage({ id: 'financeContacts.min_characters' }, { value: 6 }),
          },
          {
            pattern: /^[\d\+]+$/,
            message: formatMessage({ id: 'financeContacts.only_number_allowed' }),
          },
        ]}
      >
        {phonesNumber.length < 2 ? (
          <Input placeholder={formatMessage({ id: 'sms.destination_number' })} />
        ) : (
          <Select
            placeholder="Select a option and change input text above"
            options={phonesNumber.map((phoneNumber) => ({
              label: phoneNumber,
              value: phoneNumber,
            }))}
          />
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'sms.wa_content' })}
        name="body"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'sms.please_input_wa_content' }),
          },
          {
            min: 20,
            message: formatMessage({ id: 'financeContacts.min_characters' }, { value: 20 }),
          },
        ]}
      >
        <Input.TextArea
          placeholder={formatMessage({ id: 'sms.wa_content' })}
          autoSize={{ minRows: 2, maxRows: 8 }}
        />
      </Form.Item>
    </Skeleton>
  )
}

export default PersonalWhatsapp
