import React from 'react'
import { Button } from 'antd'
import { useIntl } from 'react-intl'
import { UpCircleOutlined } from '@ant-design/icons'

function Upgrade({
  icon = <UpCircleOutlined />,
  type = 'success',
  title = 'button.upgrade',
  onClick = () => {},
  id = 'btn-upgrade',
  ...props
}) {
  const { formatMessage } = useIntl()
  const buttonTitle = title === 'button.upgrade' ? formatMessage({ id: title }) : title
  return (
    <Button
      disabled={props.loading}
      {...props}
      title={buttonTitle}
      onClick={onClick}
      icon={icon}
      type={type}
      id={id}
    >
      {title}
    </Button>
  )
}

export default Upgrade
