import { push, replace, goBack } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

// Hook untuk handle navigation router
const useNavigation = () => {
  const dispatch = useDispatch()

  const handleNavigate = useCallback(
    (uri) => {
      dispatch(push(uri))
    },
    [dispatch],
  )

  const handleReplace = useCallback(
    (uri) => {
      dispatch(replace(uri))
    },
    [dispatch],
  )

  const handleBack = useCallback(() => {
    dispatch(goBack())
  }, [dispatch])

  return { onNavigate: handleNavigate, onReplace: handleReplace, onBack: handleBack }
}

export default useNavigation
