import axios from 'axios'

import { cleanBlankValue } from 'utils/helper'

export const getFinanceContacts = (params) => {
  return axios.get('/finance/contacts', { params })
}

export const getFinanceContactDetail = (id, params) => {
  return axios.get(`/finance/contacts/${id}`, { params })
}

export const getFinanceContactDetailStats = (id, params) => {
  return axios.get(`/finance/contacts/${id}/stats`, { params })
}

export const getFinanceContactMoneyInOutStat = (id, params) => {
  return axios.get(`/finance/contacts/${id}/moneyInOutStats`, { params })
}

export const getFinanceContactSalesStats = (id, params) => {
  return axios.get(`/finance/contacts/${id}/salesStats`, { params })
}

export const addFinanceContact = (data) => {
  return axios.post('/finance/contacts', data)
}

export const editFinanceContact = (data) => {
  return axios.put(`/finance/contacts/${data.id}`, data)
}

export const editFinanceContactAsEmployee = (data) => {
  return axios.patch(`/finance/contacts/${data.id}/updateAsEmployee`, data)
}

export const archiveFinanceContact = (id) => {
  return axios.patch(`/finance/contacts/${id}/archive`)
}

export const unarchiveFinanceContact = (id) => {
  return axios.patch(`/finance/contacts/${id}/unarchive`)
}

export const deleteFinanceContact = (id) => {
  return axios.delete(`/finance/contacts/${id}`)
}

export const getSuggestionFinanceContact = (params, options) => {
  return axios.get('/finance/contacts/suggestionPerPage', { ...options, params })
}

export const getFinanceContact = (id) => {
  return axios.get(`/finance/contacts/${id}/name`)
}

export const getFinanceContactLog = (id, params) => {
  return axios.get(`/finance/contacts/${id}/logs`, { params })
}

export const getFinanceContactTransactions = (id, params) => {
  params = cleanBlankValue(params)
  return axios.get(`/finance/contacts/${id}/transactions`, { params })
}

export const uploadImportFinanceContact = (data) => {
  return axios.postForm('/finance/contacts/uploadImport', data)
}

export const uploadImportFinanceContactTransactions = (data) => {
  return axios.postForm('/finance/contacts/transactions/uploadImport', data)
}

export const executeImportFinanceContact = (data) => {
  return axios.post('/finance/contacts/executeImport', data)
}

export const executeImportFinanceContactTransactions = (data) => {
  return axios.post('/finance/contacts/transactions/executeImport', data)
}

export const exportFinanceContact = (params) => {
  params = cleanBlankValue(params)
  return axios.get('/finance/contacts/export', { responseType: 'arraybuffer', params })
}

export const inputMassDeleteFinanceContact = (data) => {
  return axios.post('/finance/contacts/inputMassDelete', data)
}

export const executeMassDeleteFinanceContact = (data) => {
  return axios.delete('/finance/contacts/executeMassDelete', { data })
}

export const deleteOnAddFinanceContactPhoto = (url) => {
  return axios.delete('/finance/contacts/photo', { data: { url } })
}

export const deleteOnEditFinanceContactPhoto = (id, url) => {
  return axios.delete(`/finance/contacts/${id}/photo`, { data: { url } })
}

export const getInvoiceStatusByContact = (id) => {
  return axios.get(`/finance/contacts/${id}/invoiceStatus`)
}

export const getContactSummmary = (params) => {
  return axios.get('/finance/contacts/summary', { params })
}
