/* eslint-disable react/require-default-props */
import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { ImportOutlined } from '@ant-design/icons'

const Import = ({
  type = 'default',
  icon = <ImportOutlined />,
  title = 'button.import',
  onClick = () => {},
  id = 'btn-import',
  ...props
}) => {
  const { formatMessage } = useIntl()
  const newTitle = title === 'button.import' ? formatMessage({ id: title }) : title
  return (
    <Button
      disabled={props.loading}
      {...props}
      title={newTitle}
      type={type}
      icon={icon}
      onClick={onClick}
      id={id}
    >
      {newTitle}
    </Button>
  )
}

Import.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
}

export default Import
