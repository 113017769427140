import { Button, Space, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

const { Text } = Typography

const COUNT_DOWN_TIME = 60

const ResendOTP = ({ onResend }) => {
  const { formatMessage } = useIntl()
  const [countdown, setCountdown] = useState(COUNT_DOWN_TIME)

  useEffect(() => {
    let timer
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1)
      }, 1000)
    }
    return () => {
      if (timer) clearInterval(timer)
    }
  }, [countdown])

  const handleResendClick = () => {
    onResend()
    setCountdown(COUNT_DOWN_TIME)
  }

  return (
    <Space>
      <Text>{formatMessage({ id: 'not_received_otp_code' })}</Text>
      <Button size="small" type="link" onClick={handleResendClick} disabled={countdown > 0}>
        {countdown > 0
          ? `${formatMessage({ id: 'resend_code' })} (${countdown}s)`
          : formatMessage({ id: 'resend_code' })}
      </Button>
    </Space>
  )
}

export default ResendOTP
