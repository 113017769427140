import React from 'react'
import { Form, Tabs } from 'antd'

import PersonalWhatsapp from 'components/UI/SendWaModal/PersonalWhatsapp'
import OfficialWhatsapp from 'components/UI/SendWaModal/OfficialWhatsapp'

const FormTab = ({ getWaTemplate, hideOfficial, id, show, transTypeId }) => {
  const form = Form.useFormInstance()
  const watchWhatsappType = Form.useWatch('whatsapp_type', { form, preserve: true }) || '1'

  if (!transTypeId || hideOfficial) {
    return <PersonalWhatsapp getWaTemplate={getWaTemplate} show={show} />
  }

  return (
    <Tabs
      defaultActiveKey={watchWhatsappType}
      onChange={(activeKey) => form.setFieldsValue({ whatsapp_type: activeKey })}
      size="small"
      style={{ marginBottom: 32 }}
      destroyInactiveTabPane
      items={[
        {
          key: '1',
          label: 'Personal Whatsapp',
          children: <PersonalWhatsapp getWaTemplate={getWaTemplate} show={show} />,
        },
        {
          key: '2',
          label: 'Official Whatsapp',
          children: <OfficialWhatsapp id={id} transTypeId={transTypeId} />,
        },
      ]}
    />
  )
}

export default FormTab
