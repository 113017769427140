import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Alert, Layout } from 'antd'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import NProgress from 'nprogress'

import { inits } from 'redux/actions'
import { changeSetting } from 'redux/settings/actions'
import {
  companiesSelector,
  loadedInitSelector,
  loadingInitSelector,
  optionsSelector,
  settingsSelector,
  userSelector,
} from 'redux/selectors'
import styles from './style.module.scss'

const SelectCompanyLayout = ({ intl, children }) => {
  const dispatch = useDispatch()
  const [networkError, setNetworkError] = useState(false)
  const options = useSelector(optionsSelector)
  const { id } = useSelector(userSelector)
  const { locale } = useSelector(settingsSelector)
  const loading = useSelector(loadingInitSelector)
  const loadedInit = useSelector(loadedInitSelector)
  const companies = useSelector(companiesSelector)

  // Loader init
  useEffect(() => {
    if (loading) {
      NProgress.start()
    } else {
      NProgress.done()
    }
  }, [loading])

  useEffect(() => {
    // Jika init belum di load, maka di ambil dari api
    const loadInits = async () => {
      const onInits = () => dispatch(inits())
      const response = await onInits()
      if (!response) {
        setNetworkError(true)
      }
    }

    if (!id && !loading) {
      loadInits()
    }
  }, [id, dispatch, setNetworkError, loading])

  // Jika tidak mempunya perusahaan di redirect ke tambah perusahaan
  useEffect(() => {
    if (_.isEmpty(companies) && !!id && loadedInit) {
      dispatch(push('/companies/add?from_login=1'))
    }
  }, [companies, dispatch, id, loadedInit])

  useEffect(() => {
    // Jika locale di setting tidak sama dg di init di update
    if (locale && options.property_language && locale !== options.property_language) {
      dispatch(
        changeSetting({
          setting: 'locale',
          value: options.property_language,
        }),
      )
    }
  }, [locale, dispatch, options.property_language])

  return (
    <Layout>
      <Layout.Content>
        <div className={styles.layout}>
          <div className={styles.content}>
            {networkError === true ? (
              <div className={styles.inner}>
                <Alert
                  message={intl.formatMessage({ id: 'users.failed_to_connect_to_kledo' })}
                  type="warning"
                  description={intl.formatMessage({
                    id: 'users.please_check_your_internet_connection_please_contact_wa',
                  })}
                  showIcon
                />
                <a href="https://kledo.com" className="btn mt-3">
                  &larr; {intl.formatMessage({ id: 'users.go_back_to_home_page' })}
                </a>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default injectIntl(SelectCompanyLayout)
