import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd'
import HideFeature from 'containers/Billing/HideFeature'
import Timeline from 'components/apps/Notification/Timeline'
import { cleanBlankValue, isFeaturePro } from 'utils/helper'
import { useIntl } from 'react-intl'
import { useInfiniteLogs } from 'utils/queries'
import {
  auditModalSelector,
  billingSelector,
  mobileViewSelector,
  titleSelector,
} from 'redux/selectors'
import { hideAuditModal } from 'redux/finance/actions'
import { useQueryClient } from 'react-query'
import _ from 'lodash'

const AuditByTransType = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const audit = useSelector(auditModalSelector)
  const billing = useSelector(billingSelector)
  const title = useSelector((state) => titleSelector(state, 'audits'))
  const isMobileView = useSelector(mobileViewSelector)
  const queryClient = useQueryClient()
  const params = cleanBlankValue(audit.params)
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteLogs(
    params,
    {
      enabled: !!params?.trans_type_id,
    },
  )

  const formattedData = useMemo(() => {
    if (_.isEmpty(data?.pages[0])) {
      return []
    }
    return data?.pages?.flatMap((page) => page?.data)
  }, [data])

  const hideHandler = useCallback(() => {
    dispatch(hideAuditModal())
    // Reset query data to keep only the first page
    queryClient.setQueryData(['infinite/logs', params], (oldData) => {
      if (!oldData) return oldData

      // Hanya simpan halaman pertama
      return {
        ...oldData,
        pages: oldData.pages.slice(0, 1), // Keep only the first page data
        pageParams: [1], // Reset pageParams to start from page 1
      }
    })
  }, [dispatch, params, queryClient])

  return (
    <Modal
      title={title}
      open={audit.show}
      onCancel={hideHandler}
      footer={null}
      destroyOnClose
      forceRender
      styles={{ body: { height: '80vh', overflowY: 'auto', overflowX: 'hidden' } }}
    >
      {audit.show && (
        <HideFeature code="settings.audit" feature={formatMessage({ id: 'audit.show_audit' })}>
          <Timeline
            isPaidFeature={isFeaturePro({ billing, code: 'settings.audit' })}
            loading={isLoading}
            data={formattedData}
            loadingMore={isFetchingNextPage}
            onLoadMore={fetchNextPage}
            hasMore={hasNextPage}
            isMobileView={isMobileView}
            onHideParent={hideHandler}
          />
        </HideFeature>
      )}
    </Modal>
  )
}

export default AuditByTransType
