import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Button, Result } from 'antd'
import { push } from 'connected-react-router'
import { useLoadedInit } from 'utils/hooks'
import useMemoizeCheckPermission from 'utils/hooks/useMemoizeCheckPermission'

function RestrictedPage({ children, code, restricted }) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isHasAccessDashboard = useMemoizeCheckPermission({ permission: 'dashboard' })
  const isHasAccess = useMemoizeCheckPermission({ permission: code })
  const loadedInit = useLoadedInit()

  const goToDashboard = useCallback(() => dispatch(push('/dashboard')), [dispatch])

  if (loadedInit && (restricted || (code && !isHasAccess))) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={intl.formatMessage({
          id: 'message.sorry_you_are_not_authorized_to_access_this_page',
        })}
        extra={
          isHasAccessDashboard ? (
            <Button onClick={goToDashboard} type="primary">
              {intl.formatMessage({ id: 'companies.go_to_dashboard' })}
            </Button>
          ) : null
        }
      />
    )
  }
  return children
}

export default RestrictedPage
