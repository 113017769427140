import { message } from 'antd'
import { useQuery, useInfiniteQuery } from 'react-query'
import _ from 'lodash'
import {
  getCreditMemoLog,
  getDebitMemoLog,
  getFinanceBankTransLog,
  getFinanceContactLog,
  getFinanceDeliveryLog,
  getFinanceExpenseLog,
  getFinanceFixedAssetLog,
  getFinanceFixedAssetsAutomaticDepreciateLogs,
  getFinanceInvoiceLog,
  getFinanceLogs,
  getFinanceManualJournalLog,
  getFinanceNumberDetailLog,
  getFinanceNumberLog,
  getFinanceOrderLog,
  getFinanceProductLog,
  getFinancePurchaseDeliveryLog,
  getFinancePurchaseInvoiceLog,
  getFinancePurchaseOrderLog,
  getFinancePurchaseQuoteLog,
  getFinanceQuoteLog,
  getFinanceStockAdjustmentLog,
  getFinanceWareHouseLog,
  getFinanceWareHouseTransferLog,
  getInvoicePaymentCreditMemoLogs,
  getPurchaseInvoicePaymentDebitMemoLogs,
  getPurchaseReturnLog,
  getReturnLog,
} from 'utils/apis'
import { getLogByTransType } from 'utils/apis/audit'
import { transType } from 'utils/static'
// import { transType } from 'utils/static'

export const useFinanceLogs = ({ options = {} } = {}) => {
  return useQuery(
    ['financeLogs'],
    async () => {
      const {
        data: { data },
      } = await getFinanceLogs()
      return data
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

const bankTransTypes = [
  transType.SPEND_MONEY,
  transType.RECEIVE_MONEY,
  transType.EXPENSE_PAYMENT,
  transType.PURCHASE_PAYMENT,
  transType.PURCHASE_ORDER_PAYMENT,
  transType.RECEIVE_INVOICE_PAYMENT,
  transType.RECEIVE_SALES_ORDER_PAYMENT,
  transType.SEND_TRANSFER,
  transType.RECEIVE_TRANSFER,
]

export const useInfiniteLogs = (payload, options = {}) => {
  // Jika ada params trans_type_id berupa Array maka endpoint berubah
  const isByTransTypes = Array.isArray(payload.trans_type_id)
  let request = getFinanceLogs
  if (!_.isEmpty(payload.trans_type_id) && isByTransTypes) {
    request = getLogByTransType
  } else if (payload.tran_id && payload.trans_type_id === transType.DELIVERY) {
    request = getFinanceDeliveryLog
  } else if (payload.tran_id && payload.trans_type_id === transType.INVOICE) {
    request = getFinanceInvoiceLog
  } else if (payload.tran_id && payload.trans_type_id === transType.ORDER) {
    request = getFinanceOrderLog
  } else if (payload.tran_id && payload.trans_type_id === transType.QUOTE) {
    request = getFinanceQuoteLog
  } else if (payload.tran_id && payload.trans_type_id === transType.PURCHASE_DELIVERY) {
    request = getFinancePurchaseDeliveryLog
  } else if (payload.tran_id && payload.trans_type_id === transType.PURCHASE_INVOICE) {
    request = getFinancePurchaseInvoiceLog
  } else if (payload.tran_id && payload.trans_type_id === transType.PURCHASE_ORDER) {
    request = getFinancePurchaseOrderLog
  } else if (payload.tran_id && payload.trans_type_id === transType.PURCHASE_QUOTE) {
    request = getFinancePurchaseQuoteLog
  } else if (payload.tran_id && payload.trans_type_id === transType.EXPENSE) {
    request = getFinanceExpenseLog
  } else if (payload.tran_id && payload.trans_type_id === transType.FIXED_ASSET) {
    request = getFinanceFixedAssetLog
  } else if (payload.tran_id && payload.trans_type_id === transType.CONTACT) {
    request = getFinanceContactLog
  } else if (payload.tran_id && payload.trans_type_id === transType.PRODUCT) {
    request = getFinanceProductLog
  } else if (payload.tran_id && payload.trans_type_id === transType.FIXED_ASSET) {
    request = getFinanceFixedAssetLog
  } else if (payload.tran_id && payload.trans_type_id === transType.NUMBER) {
    request = getFinanceNumberDetailLog
  } else if (payload.tran_id && payload.trans_type_id === transType.STOCK_ADJUSTMENT) {
    request = getFinanceStockAdjustmentLog
  } else if (payload.tran_id && payload.trans_type_id === transType.WAREHOUSE_TRANSFER) {
    request = getFinanceWareHouseTransferLog
  } else if (payload.tran_id && payload.trans_type_id === transType.INVOICE_PAYMENT_CREDIT_MEMO) {
    request = getInvoicePaymentCreditMemoLogs
  } else if (
    payload.tran_id &&
    payload.trans_type_id === transType.PURCHASE_INVOICE_PAYMENT_DEBIT_MEMO
  ) {
    request = getPurchaseInvoicePaymentDebitMemoLogs
  } else if (payload.tran_id && payload.trans_type_id === transType.RETURN) {
    request = getReturnLog
  } else if (payload.tran_id && payload.trans_type_id === transType.PURCHASE_RETURN) {
    request = getPurchaseReturnLog
  } else if (payload.tran_id && payload.trans_type_id === transType.CREDIT_MEMO) {
    request = getCreditMemoLog
  } else if (payload.tran_id && payload.trans_type_id === transType.DEBIT_MEMO) {
    request = getDebitMemoLog
  } else if (payload.tran_id && bankTransTypes.includes(payload.trans_type_id)) {
    request = getFinanceBankTransLog
  } else if (payload.tran_id && payload.trans_type_id === transType.EXPENSE) {
    request = getFinanceExpenseLog
  } else if (payload.source === 'automaticDepreciationLogs') {
    request = getFinanceFixedAssetsAutomaticDepreciateLogs
  } else if (payload.tran_id && payload.trans_type_id === transType.MANUAL_JOURNAL) {
    request = getFinanceManualJournalLog
  } else if (payload.source === 'numberLogs') {
    request = getFinanceNumberLog
  } else if (payload.tran_id && payload.trans_type_id === transType.WAREHOUSE) {
    request = getFinanceWareHouseLog
  }

  return useInfiniteQuery(
    ['logs', payload],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const params = { page: pageParam, ...payload }
        if (params.tran_id && !isByTransTypes) {
          delete params.trans_type_id
        }
        if (isByTransTypes) {
          params.trans_type_id = params.trans_type_id.join(',')
        }
        let _response
        if (params.tran_id) {
          const { tran_id: id, ..._params } = params
          _response = await request(id, _params)
        } else {
          _response = await request(params)
        }

        response = _response.data.data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }
        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}
