import { Table } from 'components/UI'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { formatDate, qtyToCurrency } from 'utils/formatting'
import { productSerialNumberType } from 'utils/static'

const ExpandedSerialNumbers = ({ item = {}, forceShowQty, ...props }) => {
  const { formatMessage } = useIntl()
  const columns = useMemo(() => {
    const tempColumns = [
      {
        title: 'No',
        key: 'key',
        dataIndex: 'key',
        width: 90,
        render: (text, record, index) => {
          return index + 1
        },
        className: 'text-center',
      },
      {
        title: 'Nomor',
        key: 'code',
        dataIndex: 'code',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.code.localeCompare(b.code),
      },
    ]

    if (item.product?.sn_type === productSerialNumberType.PRODUCTION_NUMBER || forceShowQty) {
      tempColumns.push({
        title: formatMessage({
          id: 'qty',
        }),
        key: 'qty',
        dataIndex: 'qty',
        width: 100,
        render: (text) => {
          return qtyToCurrency(text)
        },
        className: 'text-right',
      })
      if (item.product?.manage_expiry === 1 || !_.isEmpty(item.expiry_date)) {
        tempColumns.push({
          title: formatMessage({
            id: 'expired',
          }),
          key: 'expiry_date',
          dataIndex: 'expiry_date',
          width: 150,
          render: (text) => {
            return formatDate(text)
          },
          className: 'text-center',
        })
      }
    }
    return tempColumns
  }, [item, forceShowQty, formatMessage])

  // const renderTitle = useCallback(
  //   () => (
  //     <Typography.Text style={{ fontSize: 16 }} strong>
  //       No. Seri / Produksi
  //     </Typography.Text>
  //   ),
  //   [],
  // )

  return (
    <Table
      // style={{ margin: 10 }}
      columns={columns}
      rowKey="id"
      dataSource={item.serial_numbers}
      pagination={false}
      // title={renderTitle}
      // size="small"
      {...props}
    />
  )
}

export default ExpandedSerialNumbers
