import React, { useCallback, useState } from 'react'
import { Card, Flex, message, Spin, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { useGenerateOtp } from 'utils/queries'
import VerificationOTPForm from './VerificationOTPForm'
import SendOTPForm from './SendOTPForm'

const { Title } = Typography

const LoginOTP = ({ defaultEmail, onSuccessCallback, onBackCallback, isOnModal = false }) => {
  const { formatMessage } = useIntl()

  const [requestOtp, setRequestOtp] = useState(() => {
    if (defaultEmail) {
      return { requested: true, email: defaultEmail }
    }
    return { requested: false, email: '' }
  })

  const generateOtpMutation = useGenerateOtp()

  const handleSubmit = useCallback(
    (values) => {
      generateOtpMutation.mutate(
        { email: values.email },
        {
          onSuccess: (response) => {
            if (response.data.success) {
              setRequestOtp({ requested: true, email: values.email })
              if (typeof onSuccessCallback === 'function') {
                onSuccessCallback(values.email)
              }
              
            } else {
              message.error(response.data?.message || 'Failed to verify code')
            }
          },
          onError: (error) => {
            message.error(error?.message || 'Failed to verify code')
          },
        },
      )
    },
    [generateOtpMutation, onSuccessCallback],
  )

  const backToSendOtp = useCallback(() => {
    setRequestOtp({ requested: false, email: '' })
    if (typeof onBackCallback === 'function') {
      onBackCallback()
    }
  }, [onBackCallback])

  const handleResend = useCallback(() => {
    handleSubmit({ email: requestOtp.email })
    message.success(formatMessage({ id: 'otp_has_been_resent_to_your_email' }, { email: requestOtp.email }))
  }, [handleSubmit, requestOtp.email, formatMessage])

  return (
    <Flex justify="center" align="center">
      <Spin spinning={generateOtpMutation.isLoading}>
        <Card style={{ width: 450 }}>
          <Title className="text-center" level={3}>
            {formatMessage({ id: 'continue_with_otp' })}
          </Title>

          {requestOtp.requested ? (
            <VerificationOTPForm
              loading={generateOtpMutation.isLoading}
              email={requestOtp.email}
              onBack={backToSendOtp}
              onResend={handleResend}
              isOnModal={isOnModal}
            />
          ) : (
            <SendOTPForm loading={generateOtpMutation.isLoading} onSubmit={handleSubmit} />
          )}
        </Card>
      </Spin>
    </Flex>
  )
}

export default LoginOTP
