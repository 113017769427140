import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Grid, Select } from 'antd'

import { settingsSelector } from 'redux/selectors'

const { useBreakpoint } = Grid

const PageSizeChanger = ({ pageSize, pageSizeOptions, onShowSizeChange, current }) => {
  const { formatMessage } = useIntl()

  const { xs } = useBreakpoint()
  const settings = useSelector(settingsSelector)
  const isSmall = !!xs

  return (
    <Select
      style={{ float: settings.isMobileView ? 'left' : 'right', width: 'auto' }}
      size={isSmall ? 'small' : 'middle'}
      className="ant-select"
      value={pageSize}
      onChange={(value) => onShowSizeChange(current, value)}
      options={pageSizeOptions.map((option) => ({
        label: `${option} / ${formatMessage({ id: 'button.page' }).toLowerCase()}`,
        value: option,
      }))}
    />
  )
}

export default PageSizeChanger
