import axios from 'axios'
import { stringify } from 'query-string'

export const getProductFavorites = () => {
  return axios.get('/pos/products/favorites')
}

export const createProductFavorites = (payload) => {
  return axios.post('/pos/products/favorites', payload)
}

export const deleteProductFavorites = ({ id }) => {
  return axios.delete(`/pos/products/favorites/${id}`)
}

export const moveProductFavorite = ({ id, payload }) => {
  return axios.patch(`/pos/products/favorites/${id}/move`, payload)
}

export const getOutlets = ({ payload }) => {
  const query = stringify(payload)
  return axios.get(`/pos/outlets?${query}`)
}

export const createOutlet = ({ payload }) => {
  return axios.post('/pos/outlets', payload)
}

export const editOutlet = ({ id, payload }) => {
  return axios.put(`/pos/outlets/${id}`, payload)
}

export const deleteOutlet = ({ id }) => {
  return axios.delete(`/pos/outlets/${id}`)
}

export const getPosUsers = () => {
  return axios.get('/pos/users')
}

export const outletFloors = ({ payload }) => {
  const { id, ...restPayload } = payload
  const query = stringify(restPayload)
  return axios.get(`/pos/outlets/${id}/floors?${query}`)
}

export const addFloor = ({ id, payload }) => {
  return axios.post(`/pos/outlets/${id}/floors`, payload)
}

export const deleteFloor = ({ outletId, roomId }) => {
  return axios.delete(`/pos/outlets/${outletId}/floors/${roomId}`)
}

export const editFloor = ({ outletId, roomId, body }) => {
  return axios.patch(`/pos/outlets/${outletId}/floors/${roomId}`, body)
}

export const getPosRoles = () => {
  return axios.get('/pos/roles')
}

export const editPosRole = ({ id, payload }) => {
  return axios.patch(`/pos/roles/${id}`, payload)
}

export const getPosOjols = ({ payload }) => {
  const query = stringify(payload)
  return axios.get(`/pos/ojols?${query}`)
}

export const addPosOjol = ({ payload }) => {
  return axios.post('/pos/ojols', payload)
}

export const getPosOjolsById = ({ id }) => {
  return axios.get(`/pos/ojols/${id}`)
}

export const editPosOjol = ({ id, payload }) => {
  return axios.put(`/pos/ojols/${id}`, payload)
}

export const deletePosOjol = ({ id }) => {
  return axios.delete(`/pos/ojols/${id}`)
}

export const getPosOutletDetail = ({ id }) => {
  return axios.get(`/pos/outlets/${id}`)
}

export const getSuggestionOutlet = (params, options) => {
  return axios.get('/pos/outlets', { params, ...options })
}
export const getSuggestionOjols = (params, options) => {
  return axios.get('/pos/ojols', { params, ...options })
}

export const getPosShifts = (params) => {
  return axios.get('pos/shifts', { params })
}

export const getPosShiftDetail = (id) => {
  return axios.get(`pos/shifts/${id}`)
}
