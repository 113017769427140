import React, { useCallback, useState } from 'react'
import { Button, Card, Flex, Form, Input, Space, Spin, Tooltip, Typography } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import { SecurityScanOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const TwoFactorVerificationForm = ({ onSubmit, loading, onBack }) => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const [recoveryMode, setRecoveryMode] = useState(false)

  const handleRecoveryMode = useCallback(() => {
    setRecoveryMode(!recoveryMode)
    form.resetFields(['code'])
  }, [recoveryMode, form])

  return (
    <Flex justify="center" align="center">
      <Spin spinning={loading}>
        <Card style={{ width: 450 }}>
          <Title className="text-center" level={3}>
            Two Factor Verification
          </Title>

          <Form layout="vertical" onFinish={onSubmit} style={{ marginTop: 30 }} form={form}>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: recoveryMode
                      ? 'please_input_the_recovery_code'
                      : 'please_input_the_verification_code',
                  }),
                },
              ]}
              label={recoveryMode ? formatMessage({ id: 'recovery_code' }) : undefined}
            >
              {recoveryMode ? (
                <Input
                  addonAfter={
                    <Tooltip title={formatMessage({ id: 'use_verification_code' })}>
                      <SecurityScanOutlined style={{ fontSize: 20 }} onClick={handleRecoveryMode} />
                    </Tooltip>
                  }
                  placeholder={formatMessage({ id: 'enter_the_recovery_code' })}
                  autoFocus
                />
              ) : (
                <Input.OTP
                  inputType="text"
                  length={6}
                  autoFocus
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '12px',
                    marginBottom: '16px',
                    width: 300,
                    margin: '0 auto',
                  }}
                  formatter={(str) => str.toUpperCase()}
                />
              )}
            </Form.Item>
            <Text style={{ textAlign: 'center', display: 'block', marginTop: 20 }}>
              {formatMessage({ id: 'enter_verification_code_from_authentication_app' })}
              {!recoveryMode && (
                <Text>
                  <FormattedMessage
                    id="if_you_cannot_access_the_application"
                    values={{
                      recoveryModeLink: (
                        <a
                          rel="noopener noreferrer"
                          className="text-primary"
                          onClick={handleRecoveryMode}
                          role="button"
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleRecoveryMode()
                            }
                          }}
                        >
                          {formatMessage({ id: 'use_recovery_code' })}
                        </a>
                      ),
                    }}
                  />
                </Text>
              )}
            </Text>
            <Space direction="vertical" style={{ marginTop: 30, width: '100%' }}>
              <Button block type="primary" htmlType="submit">
                {formatMessage({ id: 'verify_code' })}
              </Button>
              <Button block htmlType="button" onClick={onBack}>
                {formatMessage({ id: 'button.back' })}
              </Button>
            </Space>
          </Form>
        </Card>
      </Spin>
    </Flex>
  )
}

export default TwoFactorVerificationForm
