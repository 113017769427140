import { createSlice } from '@reduxjs/toolkit'
import { updateObject } from './utility'

const initialState = { showLoginForm: false, error: null, message: '' }

const slice = createSlice({
  name: 'loginForm',
  initialState,
  reducers: {
    resetLoginForm: () => initialState,
    showLoginForm: (state, { payload }) =>
      updateObject(state, { showLoginForm: true, message: payload.message || '' }),
    closeLoginForm: (state) => updateObject(state, { showLoginForm: false, message: '' }),
    setErrorLoginForm: (state, { payload }) => updateObject(state, { error: payload }),
  },
})

export const { resetLoginForm, showLoginForm, closeLoginForm, setErrorLoginForm } = slice.actions

export default slice
