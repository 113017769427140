import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import menu from './menu/reducers'
import settings from './settings/reducers'
import inits from './inits/reducers'
import options from './options/reducers'
import posSettings from './posSettings/reducers'
import finance from './finance/reducers'
import billing from './billing/reducers'
import billingPos from './billingPos/reducers'
import companies from './companies/reducers'
import translation from './translation/reducers'
import broadcast from './broadcast/reducers'
import customColumn from './customColumn/reducers'
import multiCurrency from './currencies/reducers'
import maintenance from './maintenance/reducers'
import onboardingChecklist from './onboardingChecklist/reducers'
import outlets from './outlets/reducers'
import maxCompanies from './maxCompanies/reducers'
import initSlice from './initSlice'
import loginSlice from './loginSlice'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    menu,
    settings,
    inits,
    options,
    posSettings,
    finance,
    billing,
    billingPos,
    companies,
    translation,
    broadcast,
    customColumn,
    multiCurrency,
    maintenance,
    max_companies: maxCompanies,
    onboardingChecklist,
    outlets,
    _inits: initSlice.reducer,
    loginForm: loginSlice.reducer,
  })
