import { useMutation, useQuery, useQueryClient } from 'react-query'
import { message } from 'antd'

import {
  addFinanceContactGroup,
  addFinanceContactGroupRoles,
  canBeDeletedContactGroup,
  deleteFinanceContactGroup,
  editFinanceContactGroup,
  getFinanceContactGroupRoles,
  getFinanceContactGroups,
  moveFinanceContactGroup,
} from 'utils/apis'
import { arrayMove } from '@dnd-kit/sortable'

export function useContactGroupRoles({ id, options }) {
  return useQuery(
    ['contactGroups', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceContactGroupRoles({ id })
        response = data
      } catch (error) {
        throw new Error(error?.data?.message ?? 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useContactGroups(params, options) {
  return useQuery(
    ['contactGroupsSuggestion', params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceContactGroups(params)
        response = data
      } catch (error) {
        throw new Error(error?.data?.message ?? 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useCanbeDeletedContactGroups(options) {
  return useQuery(
    ['canbeDeletedContactGroups'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await canBeDeletedContactGroup()
        response = data
      } catch (error) {
        throw new Error(error?.data?.message ?? 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useAddFinanceContactGroupRoles = () => useMutation(addFinanceContactGroupRoles)
export const useEditContactGroup = () => useMutation(editFinanceContactGroup)
export const useDeleteContactGroup = () => useMutation(deleteFinanceContactGroup)
export const useMoveContactGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(({ srcId, destId }) => moveFinanceContactGroup(srcId, destId), {
    onMutate: async ({ searchParams, srcIndex, destIndex }) => {
      const queryKey = ['contactGroupsSuggestion', searchParams]
      await queryClient.cancelQueries(queryKey)
      const previousData = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old) => {
        return arrayMove(old, srcIndex, destIndex)
      })

      return { previousData }
    },
    onError: (_err, payload, context) => {
      queryClient.setQueryData(
        ['contactGroupsSuggestion', payload.searchParamms],
        context.previousData,
      )
    },
  })
}
export const useAddContactGroup = (options = {}) => useMutation(addFinanceContactGroup, options)
