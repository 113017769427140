import { hideNPS } from 'redux/initSlice'
import * as api from 'utils/apis/nps'

export const ignoreNPS = () => {
  return (dispatch) => {
    return api.ignoreNPS().then((response) => {
      dispatch(hideNPS())
      return response
    })
  }
}

export const postNPS = (payload) => {
  return (dispatch) => {
    return api.postNPS(payload).then((response) => {
      dispatch(hideNPS())
      return response
    })
  }
}
