import { message } from 'antd'
import { push } from 'connected-react-router'
import localStore from 'store'
import _ from 'lodash'
import { getInits, getInitsPublic } from 'utils/apis'
import { removeToken } from 'utils/cookies'
import * as actionTypes from 'redux/actionTypes'
import { setFinance, setPaymentConnectProviders } from 'redux/finance/actions'
import { setBroadcast } from 'redux/broadcast/actions'
import { changeSetting } from 'redux/settings/actions'
import { setSelectedEndpoint } from 'redux/companies/actions'
import { setCustomColumns } from 'redux/customColumn/actions'
import { setCurrencies } from 'redux/currencies/actions'
import { setOutlets } from 'redux/outlets/actions'
import { setMaxCompanies } from 'redux/maxCompanies/actions'
import { allowedLocales } from 'utils/static'
import { setInits as _setInits, resetInits as _resetInits, setReviews } from 'redux/initSlice'
import { closeLoginForm } from 'redux/loginSlice'

export const startLoading = () => {
  return {
    type: actionTypes.START_LOADING,
  }
}

export const stopLoading = () => {
  return {
    type: actionTypes.STOP_LOADING,
  }
}

export const startInit = () => {
  return {
    type: actionTypes.START_INIT,
  }
}

export const stopInit = () => {
  return {
    type: actionTypes.STOP_INIT,
  }
}

export const setLoadedInit = (payload = true) => {
  return {
    type: actionTypes.SET_LOADED_INIT,
    payload,
  }
}

export const increaseAttemp = () => {
  return {
    type: actionTypes.INCREASE_ATTEMP,
  }
}

export const resetAttemp = () => {
  return {
    type: actionTypes.RESET_ATTEMP,
  }
}

export const setMenu = (payload) => {
  return {
    type: actionTypes.SET_MENU,
    payload,
  }
}

export const setOptions = (payload) => {
  return {
    type: actionTypes.SET_OPTIONS,
    payload,
  }
}

export const setBilling = (payload) => {
  return {
    type: actionTypes.SET_BILLING,
    payload,
  }
}

export const setBillingPos = (payload) => {
  return {
    type: actionTypes.SET_BILLING_POS,
    payload,
  }
}

export const clearBillingPos = (payload) => {
  return {
    type: actionTypes.CLEAR_BILLING_POS,
    payload,
  }
}

export const setOnboardingChecklist = (payload) => {
  return {
    type: actionTypes.SET_ONBOARDING_CHECKLIST,
    payload,
  }
}

export const setCompanies = (payload) => {
  return {
    type: actionTypes.SET_COMPANIES,
    payload,
  }
}

export const setTranslation = (payload) => {
  return {
    type: actionTypes.SET_TRANSLATION,
    payload,
  }
}

export const setInits = (data) => {
  return (dispatch, getState) => {
    if (!_.isEmpty(data.companies)) {
      dispatch(
        changeSetting({
          setting: 'locale',
          value: data.user.lang,
        }),
      )
      dispatch(setMenu(data.user.menus))
      dispatch(
        setCompanies({
          companies: data.companies,
          website_id: data.website_id,
          endpoint: data.endpoint,
        }),
      )

      // Jika belum ada endpoint disetkan dari init
      if (
        data.endpoint &&
        data.endpoint !== localStore.get('app.endpoint') &&
        !localStore.get('app.endpoint')
      ) {
        if (getState()?.router?.location?.pathname !== '/user/select-company') {
          dispatch(setSelectedEndpoint(data.endpoint))
        }
      }

      if (data.translation) dispatch(setTranslation(data.translation))
      if (data.options) dispatch(setOptions(data))
      if (data.finance) dispatch(setFinance(data.finance, data.translation))
      if (data.billing) dispatch(setBilling(data.billing))
      if (data.billing_pos) dispatch(setBillingPos(data.billing_pos))
      if (data.onboarding_checklist) dispatch(setOnboardingChecklist(data.onboarding_checklist))
      if (data.broadcast) {
        dispatch(setBroadcast(data.broadcast))
      }
      if (data.custom_columns) {
        dispatch(
          setCustomColumns({
            custom_column_types: data.custom_column_types,
            custom_columns: data.custom_columns,
            custom_column_items: data.custom_column_items,
          }),
        )
      }
      if (data.currencies) {
        dispatch(setCurrencies(data.currencies))
      }

      if (data.max_companies) {
        dispatch(setMaxCompanies(data.max_companies))
      }

      if (data.outlets) {
        dispatch(setOutlets(data.outlets))
      }
      // Perlahan ganti ke redux-tollkit
      const livestream = data.livestream || {}
      livestream.is_show_thumbnail = true

      const payload = {
        livestream,
        finance_trans_total: data.finance_trans_total,
        user: data.user,
        show_nps: data.show_nps,
        show_hr_addon: data.show_hr_addon,
        tutorials: data.tutorials,
        message_default: data.message_default,
        lang: data.lang,
        salutations: data.salutations,
        reasons: data.reasons,
        announcement: data.announcement,
        banner: data.banner,
      }
      dispatch(_setInits(payload))
    }

    dispatch(setLoadedInit())
  }
}

export const inits = ({ callback } = {}) => {
  return async (dispatch, getState) => {
    const state = getState()
    await dispatch(startInit())

    return getInits()
      .then((response) => {
        if (response.data.success) {
          dispatch(setInits(response.data.data))
          dispatch(stopInit())
          if (typeof callback === 'function') callback()
        } else {
          message.error(response.data.message)
          dispatch(stopInit())
        }
        return true
      })
      .catch((e) => {
        if (e?.status !== 521) {
          if (e?.status !== 401) {
            const maxAttemp = 4
            if (state.inits.attemp === maxAttemp) {
              dispatch(push('/server-error'))
            } else {
              dispatch(increaseAttemp())
            }
          } else {
            removeToken()
            dispatch(resetInits())
            dispatch(push('/user/login'))
            dispatch(closeLoginForm())
          }
        }

        return false
      })
      .finally(() => {
        dispatch(stopInit())
      })
  }
}

export const initsPublic = () => {
  return (dispatch) => {
    dispatch(startInit())

    return getInitsPublic()
      .then((response) => {
        if (response.data.success) {
          const { data } = response.data
          dispatch(setReviews(data.quotes))

          const locale = localStore.get('app.settings.locale')

          if (!allowedLocales.includes(locale)) {
            dispatch(
              changeSetting({
                setting: 'locale',
                value: data.options.property_language || 'id-ID',
              }),
            )
          }

          dispatch(setOptions(data))
          dispatch(stopInit())
        } else {
          message.error(response.data.message)
          dispatch(stopInit())
        }
        return true
      })
      .catch(() => {
        dispatch(stopInit())
        return false
      })
  }
}

// Reset redux ketika logout
export const resetInits = () => {
  return (dispatch) => {
    dispatch(setMenu({ dashboard: [], settings: [] }))
    dispatch(
      setCompanies({
        companies: [],
        website_id: null,
        endpoint: null,
      }),
    )
    dispatch(setSelectedEndpoint(null))
    dispatch(clearBillingPos())
    dispatch(setMaxCompanies(0))
    dispatch(
      setCustomColumns({
        custom_column_types: [],
        custom_columns: [],
        custom_column_items: [],
      }),
    )
    dispatch(
      setFinance({
        accountCategories: [],
        bankAccounts: [],
        config: {},
        finance_approval: {},
        dashboard: [],
        expenseTerms: [],
        productCategories: [],
        product_categories_has_role: [],
        shippingComps: [],
        taxes: [],
        units: [],
        warehouses: [],
      }),
    )
    dispatch(setPaymentConnectProviders([]))
    dispatch(setOutlets([]))
    dispatch(_resetInits({ logout: true }))
  }
}
