import React, { useCallback } from 'react'
import { injectIntl } from 'react-intl'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { escapeRegExp } from 'lodash'
import { useSelector } from 'react-redux'
import { darkModeSelector } from 'redux/selectors'

function SearchWidget({
  intl,
  onChange = () => {},
  onKeyDown = () => {},
  escape = false,
  autoFocus = true,
  ...props
}) {
  const darkMode = useSelector(darkModeSelector)

  const handleChange = useCallback(
    (e) => {
      if (escape) {
        e.target.value = escapeRegExp(e.target.value)
      }
      onChange(e)
    },
    [escape, onChange],
  )

  const handleKeyDown = useCallback(
    (e) => {
      if (escape) {
        e.target.value = escapeRegExp(e.target.value)
      }
      onKeyDown(e)
    },
    [escape, onKeyDown],
  )

  return (
    <Input
      prefix={<SearchOutlined style={{ color: darkMode ? '#B8BECA' : 'rgba(0,0,0,.25)' }} />}
      placeholder={intl.formatMessage({ id: 'input.placeholder.search' })}
      id="input-search"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      allowClear
      autoFocus={autoFocus}
      {...props}
    />
  )
}

export default injectIntl(SearchWidget)
