import React from 'react'
import { Button, Form, Input, Space, Typography } from 'antd'
import useNavigation from 'utils/hooks/useNavigation'
import { useIntl } from 'react-intl'
import { getSavedEmail } from 'utils/cookies'

const { Text } = Typography

const SendOTPForm = ({ onSubmit, loading }) => {
  const { formatMessage } = useIntl()
  const { onBack } = useNavigation()

  return (
    <Form
      initialValues={{ email: getSavedEmail() }}
      layout="vertical"
      onFinish={onSubmit}
      style={{ marginTop: 30 }}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'users.please_input_your_email_address' }),
          },
          {
            type: 'email',
            message: formatMessage({ id: 'users.the_input_is_not_valid_email_' }),
          },
        ]}
        label={formatMessage({ id: 'users.email' })}
      >
        <Input
          placeholder={formatMessage({ id: 'users.please_input_your_email_address' })}
          size="default"
          autoFocus
        />
      </Form.Item>
      <Text style={{ marginTop: 20 }}>
        {formatMessage({
          id: 'enter_your_email_so_we_can_send_you_the_otp_code_for_the_login_process',
        })}
      </Text>
      <Space direction="vertical" style={{ marginTop: 30, width: '100%' }}>
        <Button block type="primary" htmlType="submit" loading={loading} disabled={loading}>
          {formatMessage({ id: 'send_otp_code' })}
        </Button>
        <Button block htmlType="button" onClick={onBack}>
          {formatMessage({ id: 'button.back' })}
        </Button>
      </Space>
    </Form>
  )
}

export default SendOTPForm
