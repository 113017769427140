import { message } from 'antd'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'

import {
  addFloor,
  addPosOjol,
  createOutlet,
  createProductFavorites,
  deleteFloor,
  deleteOutlet,
  deletePosOjol,
  deleteProductFavorites,
  editFloor,
  editOutlet,
  editPosOjol,
  editPosRole,
  getOutlets,
  getPosOjols,
  getPosOutletDetail,
  getPosRoles,
  getPosShifts,
  getPosUsers,
  getProductFavorites,
  getSuggestionOjols,
  getSuggestionOutlet,
  getPosShiftDetail,
  moveProductFavorite,
  outletFloors,
} from 'utils/apis'

export const useProductFavorites = ({ options }) => {
  return useQuery(
    ['products-favorites'],
    async () => {
      const {
        data: { data },
      } = await getProductFavorites()
      return data
    },
    { ...options },
  )
}

export const useCreateProductFavorites = () => {
  return useMutation((payload) => createProductFavorites(payload))
}

export const useDeleteProductFavorites = () => {
  return useMutation(({ id }) => deleteProductFavorites({ id }))
}

export const useMoveProductFavorite = () => {
  return useMutation(({ id, payload }) => moveProductFavorite({ id, payload }))
}

export const useOutlets = ({ options, payload }) => {
  return useQuery(
    ['outlets', payload],
    async () => {
      const {
        data: { data },
      } = await getOutlets({ payload })
      return data
    },
    { ...options },
  )
}

export const useCreateOutlet = () => {
  return useMutation(({ payload }) => createOutlet({ payload }))
}

export const useEditOutlet = () => {
  return useMutation(({ id, payload }) => editOutlet({ id, payload }))
}

export const useDeleteOutlet = () => {
  return useMutation(({ id, payload }) => deleteOutlet({ id, payload }))
}

export const usePosUsers = ({ options = {}, payload } = {}) => {
  return useQuery(
    ['pos-users', payload],
    async () => {
      const {
        data: { data },
      } = await getPosUsers({ payload })
      return data
    },
    { ...options },
  )
}

export const useOutletFloors = ({ options, payload }) => {
  return useQuery(
    ['outletFloors', payload],
    async () => {
      const {
        data: { data },
      } = await outletFloors({ payload })
      return data
    },
    options,
  )
}

export const useAddFloorMutation = () => {
  return useMutation(({ id, payload }) => addFloor({ id, payload }))
}

export const useDeleteFloorMutation = () => {
  return useMutation(({ outletId, roomId }) => deleteFloor({ outletId, roomId }))
}

export const useEditFloorMutation = () => {
  return useMutation(({ outletId, roomId, body }) => editFloor({ outletId, roomId, body }))
}

export const usePosRoles = (options) => {
  return useQuery(
    ['pos-roles'],
    async () => {
      const {
        data: { data },
      } = await getPosRoles()
      return data
    },
    { ...options },
  )
}

export const useEditPosRole = () => {
  return useMutation(({ id, payload }) => editPosRole({ id, payload }))
}

export const usePosOjols = ({ options, payload }) => {
  return useQuery(
    ['pos-ojols', payload],
    async () => {
      const {
        data: { data },
      } = await getPosOjols({ payload })
      return data
    },
    { ...options },
  )
}
export const useAddPosOjol = () => {
  return useMutation(({ payload }) => addPosOjol({ payload }))
}

export const useEditPosOjol = () => {
  return useMutation(({ id, payload }) => editPosOjol({ id, payload }))
}

export const useDeletePosOjol = () => {
  return useMutation(({ id }) => deletePosOjol({ id }))
}

export const useInfiniteOjolSuggestion = (payload, options = {}, apiOptions = {}) => {
  return useInfiniteQuery(
    ['infinite/ojols/suggestion', payload],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const {
          data: { data },
        } = await getSuggestionOjols({ page: pageParam, ...payload }, apiOptions)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }
        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      keepPreviousData: true,
      ...options,
    },
  )
}

export const useOutletDetail = ({ options, id }) => {
  return useQuery(
    ['outletDetail', id],
    async () => {
      const {
        data: { data },
      } = await getPosOutletDetail({ id })
      return data
    },
    { ...options },
  )
}

export const useInfiniteOutletSuggestion = (payload, options = {}, apiOptions = {}) => {
  return useInfiniteQuery(
    ['infinite/outlets/suggestion', payload],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const {
          data: { data },
        } = await getSuggestionOutlet({ page: pageParam, ...payload }, apiOptions)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }
        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      keepPreviousData: true,
      ...options,
    },
  )
}

export const usePosShifts = ({ params, options }) => {
  return useQuery(
    ['pos-shifts', params],
    async () => {
      const {
        data: { data },
      } = await getPosShifts(params)
      return data
    },
    options,
  )
}

export const usePosShiftDetail = ({ id, options }) => {
  return useQuery(
    ['pos-shift-detail', id],
    async () => {
      const {
        data: { data },
      } = await getPosShiftDetail(id)
      return data
    },
    options,
  )
}
