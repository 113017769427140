import React, { useCallback } from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { accountTransReportSelector } from 'redux/selectors'
import { hideAccountTransactionReport } from 'redux/finance/actions'
import { Cancel } from 'components/UI'
import AccountTransactions from './index'

const AccountTransactionModal = () => {
  const dispatch = useDispatch()
  const accountTrans = useSelector(accountTransReportSelector)

  const hideHandler = useCallback(() => dispatch(hideAccountTransactionReport()), [dispatch])

  return (
    <Modal
      // title={translation.menu && `${intl.formatMessage({ id: 'button.add' })} ${translation.menu.tags}`}
      open={accountTrans.show}
      onCancel={hideHandler}
      footer={[<Cancel key={`_${0}`} onClick={hideHandler} />]}
      destroyOnClose
      width="90%"
      closable={false}
      styles={{
        body: { overflowY: 'auto', maxHeight: 'calc(100vh - 150px)', position: 'relative' },
      }}
    >
      <AccountTransactions params={accountTrans.params} isOnModal />
    </Modal>
  )
}

export default AccountTransactionModal
