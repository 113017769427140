import React, { useEffect, useMemo } from 'react'
import { Form, Image, Input, message, Skeleton } from 'antd'
import { useQuery } from 'react-query'

import colors from 'components/apps/FinanceAccounts/Charts/colors'
import {
  getFinanceInvoiceInputWhatsappTemplate,
  getFinanceOrderInputWhatsappTemplate,
  getFinancePurchaseInvoiceInputWhatsappTemplate,
  getFinancePurchaseOrderInputWhatsappTemplate,
  getFinancePurchaseQuoteInputWhatsappTemplate,
  getFinanceQuoteInputWhatsappTemplate,
} from 'utils/apis'
import { transType } from 'utils/static'
import styles from './official-whatsapp.module.scss'

const InputComponent = ({ backgroundColor, defaultValue, value, onChange }) => {
  if (defaultValue.includes('\n\n')) {
    return (
      <div className={`mb-3 ${styles.textArea}`} style={{ '--background-color': backgroundColor }}>
        <div className={styles.sideAccent} />
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 8 }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    )
  }

  return (
    <Input
      className={`mb-3 ${styles.input}`}
      addonBefore={<div />}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{ width: '100%', '--background-color': backgroundColor }}
    />
  )
}

// ini adalah list alternative color yang ok (mudah dibaca) di dalam text
const alternativeColors = [colors[0], colors[12]]

const valueReplacementName = 'official_whatsapp_value_replacement'

const useInputWhatsappTemplate = ({ id, transTypeId }) => {
  const fetchFunction = useMemo(() => {
    switch (transTypeId) {
      case transType.INVOICE:
        return getFinanceInvoiceInputWhatsappTemplate
      case transType.ORDER:
        return getFinanceOrderInputWhatsappTemplate
      case transType.QUOTE:
        return getFinanceQuoteInputWhatsappTemplate
      case transType.PURCHASE_INVOICE:
        return getFinancePurchaseInvoiceInputWhatsappTemplate
      case transType.PURCHASE_ORDER:
        return getFinancePurchaseOrderInputWhatsappTemplate
      case transType.PURCHASE_QUOTE:
        return getFinancePurchaseQuoteInputWhatsappTemplate
      default:
        return getFinanceInvoiceInputWhatsappTemplate
    }
  }, [transTypeId])

  return useQuery(
    ['input-whatsapp-template', id, transTypeId],
    async () => {
      const {
        data: { data },
      } = await fetchFunction(id)

      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
    },
  )
}

const OfficialWhatsapp = ({ id, transTypeId }) => {
  const form = Form.useFormInstance()
  const watchValueReplacement = Form.useWatch(valueReplacementName, { form, preserve: true }) || []

  const { data, isLoading } = useInputWhatsappTemplate({ id, transTypeId })

  const defaultValues = useMemo(() => {
    if (!data?.default_value) {
      return []
    }

    return data.default_value.map((defaultValue, index) => {
      const colorIndex = index % colors.length

      const color = alternativeColors[colorIndex] || colors[colorIndex]

      return {
        text: defaultValue.replace(/\n /g, '\n'),
        color,
      }
    })
  }, [data])

  useEffect(() => {
    if (watchValueReplacement.length === 0 && defaultValues.length > 0) {
      const _newValues = defaultValues.map((defaultValue) => defaultValue.text)
      form.setFieldValue(valueReplacementName, _newValues)
    }
  }, [defaultValues, form, watchValueReplacement.length])

  const formatText = useMemo(() => {
    if (!data || isLoading || watchValueReplacement.length === 0) {
      return ''
    }

    const { content } = data.payload

    const parts = content.split(/(\{\{\d+}})/)

    return parts.map((part, index) => {
      const match = part.match(/\{\{(\d+)}}/)
      if (match) {
        const _value = parseInt(match[1], 10) - 1
        const defaultValue = defaultValues[_value]
        const replacementValue = watchValueReplacement[_value] || ''
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <span style={{ color: defaultValue?.color, fontWeight: 'bold' }}>
              {replacementValue}
            </span>
          </React.Fragment>
        )
      }

      // For non-placeholder text, split by newlines and add <br /> tags
      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {part.split('\n').map((line, lineIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${index}-${lineIndex}`}>
              {lineIndex > 0 && <br />}
              {line}
            </React.Fragment>
          ))}
        </React.Fragment>
      )
    })
  }, [data, defaultValues, isLoading, watchValueReplacement])

  return (
    <Skeleton loading={isLoading} active>
      <div className="d-flex mb-3 justify-content-center w-full">
        <Image src="/resources/images/due-reminder/preview-whatsapp.png" alt="Preview Whatsapp" />
      </div>
      <pre className={styles.formattedMessage}>{formatText}</pre>

      {defaultValues.map((defaultValue, index) => (
        <InputComponent
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          defaultValue={defaultValue.text}
          value={watchValueReplacement[index]}
          backgroundColor={defaultValue.color}
          onChange={(newValue) => {
            const newValuesReplacement = [...watchValueReplacement]

            newValuesReplacement[index] = newValue

            form.setFieldValue(valueReplacementName, newValuesReplacement)
          }}
        />
      ))}
    </Skeleton>
  )
}

export default OfficialWhatsapp
