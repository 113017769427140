import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Layout, Menu, Skeleton, Space } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars-2'
import _ from 'lodash'
import { stringify } from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { changeSetting } from 'redux/settings/actions'
import { checkAlurFlow } from 'redux/options/selectors'
import { withAppConsumer } from 'components/AppProvider'
import { DemoWarning, SubscriptionWarning, TrialWarning } from 'components/UI'
import { getToken } from 'utils/cookies'
import styles from './style.module.scss'

const { Sider } = Layout

const PayrollSvg = ({ darkMode }) => {
  return (
    <svg
      width="15"
      height="15"
      className={`${styles.icon} icon-collapsed-hidden`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_627_121)">
        <g opacity="0.48">
          <path
            d="M15.1737 6.28646C14.2972 5.6016 12.7824 5.59211 10.9149 6.92783L9.95492 7.61449L7.67034 9.2482L5.98429 7.40014C5.77993 7.18982 5.50173 7.06729 5.2086 7.05849C4.91547 7.04968 4.63042 7.1553 4.4138 7.35299C4.19719 7.55067 4.066 7.82489 4.04802 8.1176C4.03005 8.41031 4.12669 8.69852 4.31749 8.92123L6.67916 11.509C6.86534 11.7128 7.11974 11.8414 7.39426 11.8703C7.66877 11.8992 7.94437 11.8266 8.16895 11.6661C8.16895 11.6661 12.7612 7.95301 12.7856 7.93325C13.3869 7.42285 13.9645 7.2035 14.7666 7.3945C15.4165 7.54958 15.9479 8.08487 15.9581 8.61747C16.1161 7.65207 15.8158 6.7884 15.1737 6.28646Z"
            fill={darkMode ? '#fff' : '#231F20'}
          />
          <path
            d="M14.8221 8.2126C14.6518 8.15526 14.0033 7.95451 13.2108 8.62616L11.5584 9.96214L12.5905 9.57463C13.022 9.41261 13.4383 9.84965 13.2546 10.2724C12.2945 12.4824 9.98358 13.9743 7.36869 13.6974C4.72892 13.4163 2.58826 11.2891 2.29323 8.65117C1.90661 5.19519 4.61296 2.25731 7.99275 2.25731C9.54872 2.25731 10.9581 2.88265 11.9908 3.89307C12.3704 4.26507 12.9503 4.336 13.3979 4.05008C14.0088 3.65986 14.1011 2.80068 13.5837 2.29271C11.9911 0.728909 9.74986 -0.175815 7.30417 0.029938C3.40178 0.35845 0.269308 3.55827 0.0169914 7.46655C-0.282274 12.1113 3.41089 15.9863 7.99275 15.9863C10.9914 15.9863 13.6086 14.3262 14.9754 11.8768C15.1118 11.6331 15.2472 11.385 15.3461 11.1212C15.825 9.84413 15.8064 8.54406 14.8221 8.2126Z"
            fill={darkMode ? '#fff' : '#231F20'}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_627_121">
          <rect
            width="16"
            height="15.9854"
            fill={darkMode ? '#fff' : '#231F20'}
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const mapStateToProps = ({
  menu,
  settings,
  options,
  translation,
  inits,
  companies: { selectedEndpoint, website_id: websiteId, endpoint },
}) => ({
  menuData: menu.menuLeftData,
  // isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  // isMobileMenuOpen: settings.isMobileMenuOpen,
  property: options.options,
  darkMode: options.options?.dark_mode,
  translation,
  loadedInit: inits?.loadedInit,
  selectedEndpoint,
  websiteId,
  endpoint,
})

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeSetting: (payload) => dispatch(changeSetting(payload)),
  }
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@withAppConsumer
class MenuLeft extends React.PureComponent {
  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  // componentDidMount() {
  //   this.setSelectedKeys(this.props)
  // }

  // componentWillReceiveProps(newProps) {
  //   if (newProps.isMenuCollapsed && !newProps.isMobileView) {
  //     this.setState({
  //       openedKeys: [],
  //     })
  //   }
  //   this.setSelectedKeys(newProps)
  // }

  componentDidUpdate(prevProps) {
    const prevAppContext = prevProps.appContext
    const { appContext } = this.props
    if (prevAppContext?.settings?.isMenuCollapsed !== appContext?.settings?.isMenuCollapsed) {
      if (appContext?.settings?.isMenuCollapsed && !this.props.isMobileView) {
        this.setState({
          openedKeys: [],
        })
      }
    }

    if (
      !_.isEqual(prevProps.menuData, this.props.menuData) ||
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setSelectedKeys()
    }
  }

  flattenItems = (items, key) =>
    items.reduce((flattenedItems, item) => {
      flattenedItems.push(item)
      if (Array.isArray(item[key])) {
        return flattenedItems.concat(this.flattenItems(item[key], key))
      }
      return flattenedItems
    }, [])

  setSelectedKeys = () => {
    const { menuData, location } = this.props
    const pathName = location.pathname === '/dashboard' ? '/' : location.pathname
    const flattenData = this.flattenItems(menuData, 'children')
    let selectedItem = null
    if (pathName === '/') {
      selectedItem = _.find(flattenData, { url: pathName })
    } else if (pathName.startsWith('/settings')) {
      selectedItem = _.find(menuData, { name: 'settings' })
    } else if (pathName.startsWith('/reports')) {
      selectedItem = _.find(menuData, { name: 'reports' })
    } else {
      for (let i = 0; i < flattenData.length; i += 1) {
        const item = flattenData[i]

        if (pathName.includes('/pos/settings') && item.url?.includes('/pos/settings')) {
          selectedItem = item
          break
        }

        if (item.url !== '/' && item.url !== '' && pathName.includes(item.url)) {
          selectedItem = item
        }
      }
    }

    store.set('app.menu.selectedKeys', selectedItem ? [selectedItem.key] : [])

    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  onCollapse = (value, type) => {
    const {
      appContext: {
        onChangeSettings,
        settings: { isMenuCollapsed },
      },
    } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    // onChangeSetting({
    //   setting: 'isMenuCollapsed',
    //   value: !isMenuCollapsed,
    // });
    onChangeSettings({ isMenuCollapsed: value })
    // this.setState({
    //   openedKeys: [],
    // })
  }

  onOpenChange = (openedKeys) => {
    this.setState({
      openedKeys,
    })
  }

  handleClick = (e) => {
    // const { isSettingsOpen, onChangeSetting } = this.props
    store.set('app.menu.selectedKeys', [e.key])
    if (e.keyPath[1]) {
      store.set('app.menu.openedKeys', [e.keyPath[1]])
    } else {
      store.set('app.menu.openedKeys', [])
    }

    // custom action on settings menu item
    // if (e.key === 'settings') {
    //   onChangeSetting({
    //     setting: 'isSettingsOpen',
    //     value: !isSettingsOpen,
    //   });
    //   return
    // }
    this.setState({
      selectedKeys: [e.key],
      openedKeys: e.keyPath[1] ? [e.keyPath[1]] : [],
      // openKeys: e.keyPath,
    })
  }

  generateMenuItems = () => {
    const menuData = _.cloneDeep(this.props.menuData || []).filter((menu) => !_.isEmpty(menu))
    const menu = menuData.map((item) => {
      if (item.name === 'settings') item.children = null

      return { ...item }
    })

    const generateItem = (item) => {
      const { key, title, url, icon, disabled } = item
      const id = `kledo-menu-${item.name}`
      if (item.divider) {
        return {
          type: 'divider',
          key: Math.random(),
        }
      }
      if (item.url && item.name === 'pos') {
        const linkKledoPos = (
          <Link to={url} className={styles.link_title}>
            <span className={styles.title}>{title}</span>
            <svg
              width="15"
              className={styles.icon}
              height="15"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H14C15.1046 0.5 16 1.39543 16 2.5V14.5C16 15.6046 15.1046 16.5 14 16.5H2C0.895431 16.5 0 15.6046 0 14.5V2.5Z"
                fill="#959394"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.86858 11.6075V8.64254C6.86858 8.12002 6.44052 7.69644 5.91249 7.69644C5.38448 7.69644 4.95642 8.12002 4.95642 8.64254V11.6075C4.95642 12.13 5.38448 12.5536 5.91249 12.5536C6.44052 12.5536 6.86858 12.13 6.86858 11.6075ZM5.91249 6.75C4.85625 6.75 4 7.59733 4 8.64254V11.6075C4 12.6527 4.85625 13.5 5.91249 13.5C6.96875 13.5 7.825 12.6527 7.825 11.6075V8.64254C7.825 7.59733 6.96875 6.75 5.91249 6.75Z"
                fill="#FAFBFF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25 3.97881C4.25 3.71437 4.4576 3.5 4.71369 3.5H8.53794C10.4099 3.5 11.9274 5.067 11.9274 7C11.9274 8.93301 10.4099 10.5 8.53794 10.5H4.71369C4.4576 10.5 4.25 10.2856 4.25 10.0212C4.25 9.75676 4.4576 9.54239 4.71369 9.54239H8.53794C9.89772 9.54239 11 8.40413 11 7C11 5.59588 9.89772 4.4576 8.53794 4.4576H4.71369C4.4576 4.4576 4.25 4.24325 4.25 3.97881Z"
                fill="#FAFBFF"
              />
            </svg>
          </Link>
        )
        return {
          key,
          disabled,
          label: linkKledoPos,
        }
      }
      if (item.url) {
        let linkComponent = item.target ? (
          <a href={url} target={item.target} rel="noopener noreferrer">
            {icon && (
              <FontAwesomeIcon icon={icon} className={`${styles.icon} icon-collapsed-hidden`} />
            )}
            <span className={styles.title}>{title}</span>
          </a>
        ) : (
          <Link to={url} className={styles.titleLink}>
            {item.name === 'payroll' ? (
              <PayrollSvg darkMode={this.props.darkMode} />
            ) : (
              <>
                {icon && (
                  <FontAwesomeIcon icon={icon} className={`${styles.icon} icon-collapsed-hidden`} />
                )}
              </>
            )}

            <span className={styles.title}>{title}</span>
          </Link>
        )
        if (item.name === 'payroll' && this.props.property?.option?.app_connect_hr) {
          const searchs = {
            token: getToken(),
            website_id: this.props.websiteId,
            endpoint: this.props.selectedEndpoint,
          }
          const urlKledo = `${process.env.REACT_APP_GAJIHUB_URL}/dashboard?${stringify(searchs)}`
          linkComponent = (
            <a
              target="_blank"
              href={urlKledo}
              rel="noopener noreferrer"
              className={styles.titleLink}
            >
              <PayrollSvg darkMode={this.props.darkMode} />
              <span className={styles.title}>{title}</span>
            </a>
          )
        }
        return {
          id,
          key,
          disabled,
          label: linkComponent,
        }
      }
      return {
        key,
        disabled,
        label: <span className={styles.title}>{title}</span>,
        icon: icon ? (
          <FontAwesomeIcon icon={icon} className={`${styles.icon} icon-collapsed-hidden`} />
        ) : null,
      }
    }

    const generateSubmenu = (items) => {
      return items.map((menuItem) => {
        if (!checkAlurFlow(menuItem.name)) {
          return null
        }
        const id = `kledo-menu-${menuItem.name}`

        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && (
                <FontAwesomeIcon icon={menuItem.icon} className={`${styles.icon}`} />
              )}
            </span>
          )
          return {
            key: menuItem.key,
            id,
            label: subMenuTitle,
            children: generateSubmenu(menuItem.children),
          }
        }
        return generateItem(menuItem)
      })
    }

    return menu.map((menuItem) => {
      const id = `kledo-menu-${menuItem.name}`
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {!_.isEmpty(menuItem.url) ? (
              <Link to={menuItem.url}>
                {menuItem.icon && (
                  <FontAwesomeIcon icon={menuItem.icon} className={`${styles.icon}`} />
                )}
                <span className={styles.title}>{menuItem.title}</span>
              </Link>
            ) : (
              <>
                <div className={menuItem.icon === 'kledo-pos' ? styles.kledo_pos : ''}>
                  <span className={styles.title}>{menuItem.title}</span>
                  {menuItem.icon && menuItem.icon !== 'kledo-pos' && (
                    <FontAwesomeIcon icon={menuItem.icon} className={`${styles.icon}`} />
                  )}
                  {menuItem.icon === 'kledo-pos' && (
                    <svg
                      width="15"
                      className={[styles.icon, 'icon-collapsed-hidden'].join(' ')}
                      height="15"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H14C15.1046 0.5 16 1.39543 16 2.5V14.5C16 15.6046 15.1046 16.5 14 16.5H2C0.895431 16.5 0 15.6046 0 14.5V2.5Z"
                        fill="#959394"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.86858 11.6075V8.64254C6.86858 8.12002 6.44052 7.69644 5.91249 7.69644C5.38448 7.69644 4.95642 8.12002 4.95642 8.64254V11.6075C4.95642 12.13 5.38448 12.5536 5.91249 12.5536C6.44052 12.5536 6.86858 12.13 6.86858 11.6075ZM5.91249 6.75C4.85625 6.75 4 7.59733 4 8.64254V11.6075C4 12.6527 4.85625 13.5 5.91249 13.5C6.96875 13.5 7.825 12.6527 7.825 11.6075V8.64254C7.825 7.59733 6.96875 6.75 5.91249 6.75Z"
                        fill="#FAFBFF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.25 3.97881C4.25 3.71437 4.4576 3.5 4.71369 3.5H8.53794C10.4099 3.5 11.9274 5.067 11.9274 7C11.9274 8.93301 10.4099 10.5 8.53794 10.5H4.71369C4.4576 10.5 4.25 10.2856 4.25 10.0212C4.25 9.75676 4.4576 9.54239 4.71369 9.54239H8.53794C9.89772 9.54239 11 8.40413 11 7C11 5.59588 9.89772 4.4576 8.53794 4.4576H4.71369C4.4576 4.4576 4.25 4.24325 4.25 3.97881Z"
                        fill="#FAFBFF"
                      />
                    </svg>
                  )}
                </div>
              </>
            )}
          </span>
        )
        return {
          key: menuItem.key,
          id,
          label: subMenuTitle,
          children: generateSubmenu(menuItem.children),
        }
      }

      return generateItem(menuItem)
    })
  }

  makeLoader = () => {
    const loaders = []
    for (let i = 1; i <= 10; i += 1) {
      loaders.push(
        <Space key={i}>
          <Skeleton.Avatar active size="small" shape="circle" />
          {!this.props.appContext.settings?.isMenuCollapsed && (
            <Skeleton.Input style={{ width: 185 }} active size="small" />
          )}
        </Space>,
      )
    }
    return loaders
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const {
      isMobileView,
      isLightTheme,
      property,
      darkMode,
      scrollBarRef,
      appContext: {
        settings: { isMenuCollapsed },
      },
    } = this.props
    const menuSettings = isMobileView
      ? {
          width: 256,
          collapsible: false,
          collapsed: false,
          collapsedWidth: 0,
          onCollapse: this.onCollapse,
        }
      : {
          width: 256,
          collapsible: true,
          collapsed: isMenuCollapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }

    const menu = this.generateMenuItems()

    return (
      <Sider
        {...menuSettings}
        className={darkMode ? styles.menu : `${styles.menu} ${styles.light}`}
        style={{ zIndex: 4 }}
      >
        <div className={styles.logo}>
          <div
            className={styles.logoContainer}
            style={{ backgroundColor: darkMode ? '#141414' : '#FFFFFF' }}
          >
            <Link to="/">
              {!_.isEmpty(property.property_logo) ? (
                <img
                  src={menuSettings.collapsed ? 'favicon.png' : property.property_logo}
                  alt={property.property_name}
                />
              ) : (
                <img
                  src={
                    menuSettings.collapsed
                      ? 'favicon.png'
                      : 'resources/images/logo-color-kledo-front.png'
                  }
                  alt="Kledo"
                />
              )}
            </Link>
          </div>
        </div>
        {
          (!isMenuCollapsed || isMobileView) && [
            <SubscriptionWarning key="subscription" showIcon={!isMenuCollapsed || isMobileView} />,
            <DemoWarning key="demo" showIcon={!isMenuCollapsed || isMobileView} />,
            <TrialWarning key="trial" showIcon={!isMenuCollapsed || isMobileView} />,
          ]
          // <Alert
          //   className="notification-demo"
          //   style={{ backgroundColor: '#ffefb3' }}
          //   message={
          //     <FormattedMessage
          //       id="setup.demo"
          //       values={{
          //         setup: (
          //           <Link to="/settings/setup">
          //             <strong>
          //               <FormattedMessage id="setup.demo_link" />
          //             </strong>
          //           </Link>
          //         ),
          //       }}
          //     />
          //   }
          //   type="warning"
          //   showIcon={!isMenuCollapsed || isMobileView}
          // />
        }
        <Scrollbars
          ref={scrollBarRef}
          className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          style={{ backgroundColor: darkMode ? '#141414' : '#FFFFFF' }}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                borderRadius: 'inherit',
                backgroundColor: '#c5cdd2',
                left: '1px',
              }}
            />
          )}
          autoHide
        >
          {!this.props.loadedInit ? (
            <Space className="mt-5 ml-4" direction="vertical" size={30}>
              {this.makeLoader()}
            </Space>
          ) : (
            <Menu
              theme={isLightTheme ? 'light' : 'dark'}
              onClick={this.handleClick}
              selectedKeys={selectedKeys}
              openKeys={openedKeys}
              onOpenChange={this.onOpenChange}
              mode="inline"
              style={{ overflowX: 'hidden' }}
              className={styles.navigation}
              items={menu}
            />
          )}
        </Scrollbars>
        {!isMobileView && <div style={{ height: 46 }} />}
      </Sider>
    )
  }
}

export default MenuLeft
