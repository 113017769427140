import { DownOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Dropdown } from 'antd'
import React, { memo, useCallback } from 'react'

const DropdownClickButton = ({
  buttonLeftProps,
  buttonRightProps,
  id = 'dropdow-click-button-id',
  ...props
}) => {
  const getPopupContainer = useCallback(() => document.getElementById(id), [id])

  return (
    <div style={{ position: 'relative' }} id={id}>
      <Dropdown
        getPopupContainer={getPopupContainer}
        arrow
        placement="topRight"
        trigger={['click']}
        {...props}
      >
        <div>
          <Button.Group>
            <ConfigProvider theme={{ components: { Button: { contentFontSize: 13 } } }}>
              <Button {...buttonLeftProps} />
            </ConfigProvider>
            <Button icon={<DownOutlined style={{ fontSize: 10 }} />} {...buttonRightProps} />
          </Button.Group>
        </div>
      </Dropdown>
    </div>
  )
}

export default memo(DropdownClickButton)
