import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'
import { getUsers, updateReadNews } from 'utils/apis/users'
import {
  loginWithSso,
  acceptInvitation,
  invite,
  getUserByToken,
  singleLogin,
  verifyGoogleToken,
  verifyFacebookToken,
  revokeLogin,
  updateProfile,
  changePassword,
  generateOtp,
} from 'utils/apis/user'
import { useDispatch } from 'react-redux'
import { updateUser } from 'redux/initSlice'

export function useUsers({ payload, options = {} } = {}) {
  return useQuery(
    ['users', payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getUsers(payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useLoginWithSsoMutation = () => {
  return useMutation(loginWithSso)
}

export const useRegister = () => {
  return useMutation(invite)
}

export const useAcceptInvitation = () => {
  return useMutation(acceptInvitation)
}

export function useUserByToken(payload, options = {}) {
  return useQuery(
    ['userByToken', payload.token],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getUserByToken(payload.token)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useSingleLogin = () => {
  return useMutation((payload) =>
    singleLogin(payload.email, payload.password, payload.remember_me, payload.is_otp),
  )
}

export const useVerifyGoogleToken = () => {
  return useMutation((accessToken) => verifyGoogleToken(accessToken))
}

export const useVerifyFacebookToken = () => {
  return useMutation((accessToken) => verifyFacebookToken(accessToken))
}

export const useRevokeLogin = () => {
  return useMutation(() => revokeLogin())
}

export const useUpdateProfile = () => {
  return useMutation((payload) => updateProfile(payload))
}

export const useChangePassword = () => {
  return useMutation((payload) => changePassword(payload))
}

export const useGenerateOtp = () => {
  return useMutation(generateOtp)
}

export const useUpdateReadNews = () => {
  const dispatch = useDispatch()
  return useMutation(updateReadNews, {
    onMutate: () => {
      dispatch(updateUser({ is_show_news: 0 }))
    },
    onSuccess: (response) => {
      if (!response.data.success) {
        dispatch(updateUser({ is_show_news: 1 }))
      }
    },
    onError: () => {
      dispatch(updateUser({ is_show_news: 1 }))
    },
  })
}
