import React, { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Divider, Form, Input, message, Space, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'
import { getSavedEmail } from 'utils/cookies'
import { loginFormSelector } from 'redux/selectors'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import useNavigation from 'utils/hooks/useNavigation'
import { closeLoginForm } from 'redux/loginSlice'
import _ from 'lodash'
import SocialLoginButton from './SocialLoginButton'

const { Text } = Typography

const LoginForm = ({
  onSubmit,
  onGoogleLogin,
  onFacebookLogin,
  onSsoLogin,
  onOtpLogin,
  loading,
  email,
}) => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const [editEmail, setEditEmail] = useState(false)

  const loginForm = useSelector(loginFormSelector)
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  const failureGoogleLoginHandler = useCallback(
    (e) => {
      message.error(e?.message || formatMessage({ id: 'notification.login_google_failure' }))
    },
    [formatMessage],
  )

  const login = useGoogleLogin({
    onSuccess: onGoogleLogin,
    onFailure: onGoogleLogin,
    cookiePolicy: 'single_host_origin',
    onError: failureGoogleLoginHandler,
    flow: 'auth-code',
  })

  const savedEmail = email || getSavedEmail()
  const emailRef = useRef()
  const passwordRef = useRef()

  const onEmailFocus = useCallback(() => {
    setEditEmail(true)
    setTimeout(() => {
      if (emailRef.current) {
        emailRef.current.focus()
        emailRef.current.input.setSelectionRange(0, form.getFieldValue('email').length)
      }
    }, 1)
  }, [form])

  const onEmailBlur = useCallback(() => {
    form.setFieldsValue({
      email: form.getFieldValue('email') ? form.getFieldValue('email').trim() : '',
    })
    if (form.getFieldsError(['email'])?.[0]?.errors?.length < 1 && passwordRef.current?.focus) {
      passwordRef.current.focus()
      setEditEmail(false)
    }
  }, [form])

  const googleLoginHandler = useCallback(() => login(), [login])

  const ssoLoginHandler = useCallback(() => {
    if (!_.isEmpty(loginForm.show)) {
      dispatch(closeLoginForm())
    }

    onNavigate('/user/login/sso')
  }, [dispatch, loginForm.show, onNavigate])

  const otpLoginHandler = useCallback(() => {
    onNavigate('/user/login/otp')
  }, [onNavigate])

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
      initialValues={{
        email: savedEmail ? savedEmail.trim() : '',
        remember_me: true,
      }}
    >
      <Form.Item
        shouldUpdate={(prevValues, currentValues) => prevValues.email !== currentValues.email}
        label={formatMessage({ id: 'users.email' })}
        onClick={editEmail ? undefined : onEmailFocus}
      >
        {({ getFieldValue }) => {
          const currentEmail = getFieldValue('email')
          return (
            <>
              {savedEmail && !editEmail ? (
                <Space>
                  {currentEmail || savedEmail}
                  <Button
                    icon={<EditOutlined />}
                    shape="circle"
                    type="text"
                    onClick={onEmailFocus}
                  />
                </Space>
              ) : null}
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'users.please_input_your_email_address' }),
                  },
                  {
                    type: 'email',
                    message: formatMessage({ id: 'users.the_input_is_not_valid_email_' }),
                  },
                ]}
                noStyle
              >
                <Input
                  ref={emailRef}
                  size="default"
                  type={savedEmail && !editEmail ? 'hidden' : undefined}
                  autoFocus
                  onBlur={onEmailBlur}
                />
              </Form.Item>
            </>
          )
        }}
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'users.password' })}
        name="password"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'users.please_input_your_password' }),
          },
        ]}
      >
        <Input.Password
          placeholder={formatMessage({ id: 'users.please_input_your_password' })}
          ref={passwordRef}
          autoFocus={savedEmail}
        />
      </Form.Item>
      <Form.Item>
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Form.Item name="remember_me" valuePropName="checked" noStyle>
            <Checkbox>{formatMessage({ id: 'users.remember_me' })}</Checkbox>
          </Form.Item>
          <Link to="/user/forgot" className="utils__link--blue utils__link--underlined pull-right">
            {formatMessage({ id: 'users.forgot_password_' })}
          </Link>
        </Space>
      </Form.Item>
      <Button block type="primary" className="mt-2" htmlType="submit" loading={loading}>
        {formatMessage({ id: 'users.login' })}
      </Button>
      <Divider
        style={{
          borderColor: '#D9D9D9',
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <Text>Atau login dengan</Text>
      </Divider>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <SocialLoginButton icon="google" label="Google" onClick={googleLoginHandler} />
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          fields="email"
          callback={onFacebookLogin}
          render={(renderProps) => (
            <SocialLoginButton icon="fb" label="Facebook" onClick={renderProps.onClick} />
          )}
        />
        <SocialLoginButton
          icon="sso"
          label="SSO"
          onClick={loginForm.showLoginForm ? onSsoLogin : ssoLoginHandler}
        />
        <SocialLoginButton
          icon="otp"
          label="OTP"
          onClick={
            typeof onOtpLogin === 'function' && loginForm.showLoginForm
              ? onOtpLogin
              : otpLoginHandler
          }
        />
      </div>
    </Form>
  )
}

export default LoginForm
