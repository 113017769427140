import React, { memo, useCallback, useState } from 'react'
import { Avatar, Dropdown, Menu, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { compareWithJSON } from 'utils/helper'
import { useIntl } from 'react-intl'
import { changeLanguage } from 'redux/settings/actions'
import { langSelector, settingsSelector } from 'redux/selectors'
import styles from './style.module.scss'

const LanguageSelector = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [visibleDropdown, setVisibleDropdown] = useState(false)

  const { locale } = useSelector(settingsSelector)
  const lang = useSelector(langSelector)
  const language = locale.substr(0, 2)

  const handleOpenChange = useCallback((visible) => setVisibleDropdown(visible), [])

  const handleChange = useCallback(
    ({ key }) => {
      if (locale !== key) {
        dispatch(
          changeLanguage({
            setting: 'locale',
            value: key,
          }),
        )
      }
    },
    [dispatch, locale],
  )

  const dropdownRender = useCallback(() => {
    const items = lang.map((item) => {
      return {
        key: item.value,
        label: (
          <>
            <span role="img" aria-label={item.name} className="mr-2 text-uppercase">
              {item.value.substr(0, 2)}
            </span>
            {item.name}
          </>
        ),
      }
    })
    return (
      <Menu className={styles.menu} selectedKeys={[locale]} onClick={handleChange} items={items} />
    )
  }, [handleChange, lang, locale])

  return (
    <Tooltip
      open={visibleDropdown ? false : undefined}
      title={formatMessage({ id: 'topBar.changeLanguage' })}
    >
      <div>
        <Dropdown
          open={visibleDropdown}
          onOpenChange={handleOpenChange}
          dropdownRender={dropdownRender}
          trigger={['click']}
          arrow
          placement="bottomRight"
        >
          <div className={`${styles.dropdown} ${visibleDropdown ? styles.open : ''}`}>
            <Avatar shape="square" size="small">
              <strong className="text-uppercase">{language}</strong>
            </Avatar>
          </div>
        </Dropdown>
      </div>
    </Tooltip>
  )
}

export default memo(LanguageSelector, compareWithJSON)
