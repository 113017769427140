import { useMutation, useQuery, useQueryClient } from 'react-query'
import { message } from 'antd'
import _ from 'lodash'
import { getRole, getRoles, moveRole } from 'utils/apis'
import { arrayMove } from '@dnd-kit/sortable'
import { deleteRole, getDeletableRoles } from 'utils/apis/roles'

export function useRoles({ enabled = false, options }) {
  return useQuery(
    ['roles'],
    async () => {
      let response
      try {
        response = (await getRoles()).data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useRole({ id, options }) {
  return useQuery(
    ['roles', id],
    async () => {
      try {
        const {
          data: { data },
        } = await getRole(id)
        return data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useMoveRole = () => {
  const queryClient = useQueryClient()
  return useMutation(({ srcId, destId }) => moveRole(srcId, destId), {
    onMutate: async ({ srcIndex, destIndex }) => {
      const queryKey = ['roles']
      await queryClient.cancelQueries(queryKey)
      const previousData = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old) => {
        const _old = _.cloneDeep(old)
        if (!_.isEmpty(_old?.data?.data) && Array.isArray(_old?.data?.data)) {
          _old.data.data = arrayMove(_old?.data?.data, srcIndex, destIndex)
          return _old
        }
        return old
      })

      return { previousData }
    },
    onError: (_err, payload, context) => {
      queryClient.setQueryData(['roles'], context.previousData)
    },
  })
}

export function useCanBeDeletedRoles(options) {
  return useQuery(
    ['canBeDeletedRoles'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getDeletableRoles()
        response = data
      } catch (error) {
        throw new Error(error?.data?.message ?? 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useDeleteRow = () => useMutation(deleteRole)
