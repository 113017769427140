import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Divider, Form, Input, message, Space, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { useLoginWithSsoMutation } from 'utils/queries'
import { loginFormSelector } from 'redux/selectors'
import useNavigation from 'utils/hooks/useNavigation'
import styles from './sso.module.scss'

const Sso = ({ onBackLogin }) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()

  const loginForm = useSelector(loginFormSelector)
  const { onNavigate } = useNavigation()

  const loginWithSsoMutation = useLoginWithSsoMutation()

  const onSubmit = useCallback(
    (values) => {
      try {
        loginWithSsoMutation.mutate(values, {
          onSuccess: (response) => {
            if (response.data.success) {
              window.location.href = response.data.data
            } else {
              message.error(response.data.message)
            }
          },
          onError: (error) => {
            if (error?.data?.message) {
              message.error(error.data.message)
            }
          },
        })
      } catch {
        //
      }
    },
    [loginWithSsoMutation],
  )

  const backToLogin = useCallback(() => onNavigate('/user/login'), [onNavigate])

  return (
    <>
      <Typography.Title className={`text-center ${styles.title}`} level={1}>
        {formatMessage({ id: 'sso.continue_with_sso' })}
      </Typography.Title>
      <Typography.Paragraph className={`text-center ${styles.desc}`}>
        <FormattedMessage id="sso.login_sso_desc">
          {(msg) => <span dangerouslySetInnerHTML={{ __html: msg }} />}
        </FormattedMessage>
      </Typography.Paragraph>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          email: '',
        }}
      >
        <Form.Item
          label={formatMessage({ id: 'users.email' })}
          name="email"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'users.please_input_your_email_address',
              }),
            },
            {
              type: 'email',
              message: formatMessage({
                id: 'users.the_input_is_not_valid_email_',
              }),
            },
          ]}
        >
          <Input placeholder="Email" size="default" type="email" autoFocus />
        </Form.Item>
        <Divider className="my-3" />
        <Space style={{ width: '100%' }} direction="vertical">
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={loginWithSsoMutation.isLoading}
            disabled={loginWithSsoMutation.isLoading}
          >
            {formatMessage({ id: 'sso.continue_with_sso' })}
          </Button>
          <Button
            block
            htmlType="button"
            onClick={loginForm.showLoginForm ? onBackLogin : backToLogin}
          >
            {formatMessage({ id: 'sso.return_to_login' })}
          </Button>
        </Space>
      </Form>
    </>
  )
}

export default Sso
