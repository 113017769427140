import axios from 'axios'
import { cleanBlankValue, getOnce } from 'utils/helper'
// import { openNewTabBlobFile } from 'utils/helper'

export const getFinanceQuotes = (params) => {
  return getOnce('/finance/quotes', { params })
}

export const addFinanceQuote = (data) => {
  return axios.post('/finance/quotes', data)
}

export const editFinanceQuote = (data) => {
  return axios.put(`/finance/quotes/${data.id}`, data)
}

export const deleteOnAddFinanceQuoteAttachment = (url) => {
  return axios.delete('/finance/quotes/attachments', { data: { url } })
}

export const deleteOnEditFinanceQuoteAttachment = (id, url) => {
  return axios.delete(`/finance/quotes/${id}/attachments`, { data: { url } })
}

export const getFinanceQuote = (id) => {
  return axios.get(`/finance/quotes/${id}`)
}

export const getFinanceQuoteLog = (id, params) => {
  return axios.get(`/finance/quotes/${id}/logs`, { params })
}

export const changeFinanceQuoteStatus = (id, data) => {
  return axios.patch(`/finance/quotes/${id}/status`, data)
}

export const deleteFinanceQuote = (id) => {
  return axios.delete(`/finance/quotes/${id}`)
}

export const uploadImportFinanceQuote = (data) => {
  return axios.postForm('/finance/quotes/uploadImport', data)
}

export const executeImportFinanceQuote = (data) => {
  return axios.post('/finance/quotes/executeImport', data)
}

// export const createPdfFinanceQuote = (id) => {
//   return axios
//     .get(`/finance/quotes/${id}/print`, { responseType: 'blob' })
//     .then((response) => {
//       openNewTabBlobFile(response)
//
//       return Promise.resolve(true)
//     })
//     .catch((error) => {
//       return Promise.reject(error)
//     })
// }

export const getFinanceQuotePublicUrl = (id) => {
  return axios.get(`/finance/quotes/${id}/publicUrl`)
}

export const exportFinanceQuote = (params) => {
  params = cleanBlankValue(params)
  return axios.get('/finance/quotes/export', { responseType: 'arraybuffer', params })
}

export const getFinanceQuoteWaTemplate = (id) => {
  return axios.get(`/finance/quotes/${id}/whatsapp`)
}

export const getFinanceQuoteInputWhatsappTemplate = (id) => {
  return axios.get(`/finance/quotes/${id}/inputWhatsapp`)
}

export const sendFinanceQuoteWhatsapp = (payload) => {
  const { id, ...body } = payload
  return axios.post(`/finance/quotes/${id}/whatsapp`, body)
}

export const getFinanceQuoteEmailTemplate = (id) => {
  return axios.get(`/finance/quotes/${id}/email`)
}

export const sendFinanceQuoteEmail = (id, data) => {
  return axios.post(`/finance/quotes/${id}/email`, data)
}

export const getFinanceQuoteSmsTemplate = (id) => {
  return axios.get(`/finance/quotes/${id}/sms`)
}

export const sendFinanceQuoteSms = (id, data) => {
  return axios.post(`/finance/quotes/${id}/sms`, data)
}

export const inputMassDeleteFinanceQuote = (data) => {
  return axios.post('/finance/quotes/inputMassDelete', data)
}

export const executeMassDeleteFinanceQuote = (data) => {
  return axios.delete('/finance/quotes/executeMassDelete', { data })
}

export const inputMassSendEmailFinanceQuote = (data) => {
  return axios.post('/finance/quotes/inputMassEmail', data)
}

export const executeMassSendEmailFinanceQuote = (data) => {
  return axios.post('/finance/quotes/executeMassEmail', data)
}

export const inputMassSendWhatsappFinanceQuote = (data) => {
  return axios.post('/finance/quotes/inputMassWhatsapp', data)
}

export const executeMassSendWhatsappFinanceQuote = (data) => {
  return axios.post('/finance/quotes/executeMassWhatsapp', data)
}

export const getMassDownloadFinanceQuote = (params) => {
  return axios.get('/finance/quotes/massDownload', { params })
}

export const approveFinanceQuote = ({ id }) => {
  return axios.post(`/finance/quotes/${id}/approve`)
}

export const rejectFinanceQuote = ({ id, body }) => {
  return axios.post(`/finance/quotes/${id}/reject`, body)
}

export const revertApprovalFinanceQuote = ({ id }) => {
  return axios.post(`/finance/quotes/${id}/revert`)
}

export const getTotalFinanceQuotes = (params) => {
  return axios.get('/finance/quotes/totals', { params })
}
